import React from 'react';
import { IGroupedMeetingsList } from '../GridLayout/index';
import { EventType } from '../LayoutWrapper';
import { CarouselWrapperByTrack } from './CarouselWrapper';
import './Card.scss';

export interface ICarouselSection {
  event: EventType;
  selectedDate: string;
  showAllDates: boolean;
  showPastMeetings: boolean;
  meetings: IGroupedMeetingsList;
}
const CarouselViewByTrack = (props: ICarouselSection): JSX.Element => {
  const { event, meetings, selectedDate, showPastMeetings, showAllDates } = props;
  const list = showAllDates ? Object.values(meetings).flatMap(m => m) : meetings[selectedDate];

  return (
    <CarouselWrapperByTrack
      event={event}
      meetings={list}
      key={selectedDate}
      selectedDate={selectedDate}
      showAllDates={showAllDates}
      showPastMeetings={showPastMeetings}
    />
  );
};

export { CarouselViewByTrack };