import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { onRegisterCompleted, onRegisterToMeetingCompleted } from './graphql/subscriptions';

// eslint-disable-next-line @typescript-eslint/ban-types
export function registerCompleteSubscription(registrationId: string, authToken: string): ReturnType<typeof API.graphql> {
  return API.graphql({
    authToken,
    variables: { registrationId },
    query: onRegisterCompleted,
    authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA
  });
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function registerMeetingCompleteSubscription(registrationId: string, authToken: string): ReturnType<typeof API.graphql> {
  return API.graphql({
    authToken,
    variables: { registrationId },
    query: onRegisterToMeetingCompleted,
    authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA
  });
}

