import React from 'react';
import { useEventPublicInfo } from '../../../hooks/api/public/use-event-public-details';
import CookieNotification from './CookieNotification/CookieNotification';

const CookieNotificationWrapper = (): JSX.Element | null => {
  const { data } = useEventPublicInfo();

  if (!data) return null;

  return (
    <CookieNotification {...data} />
  );
};

export default CookieNotificationWrapper;
