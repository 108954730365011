import React from 'react';
import { PrivateMeetingDetailsResponseType } from '../../../../lib/api';
import { Connection } from '../../../../lib/connection/connection';
import './ConnectionWrapper.scss';
import { ConnectionContentItem } from './ConnectionContentItem';

export type ConnectionWrapperProps = {
  meetingDetails: PrivateMeetingDetailsResponseType;
  isConnectBtnDisabled: boolean;
  eventId: string;
}

const ConnectionWrapper: React.FunctionComponent<ConnectionWrapperProps> = ({ meetingDetails, isConnectBtnDisabled, eventId }) => {
  const connection = Connection.create(meetingDetails);
  const connectionArray = connection?.getJoinMethods();

  return (
    <div className='flex-equal sm:flex-1 md:w-1/2 overflow-visible bg-cool-gray'>
      {(!connection || !connectionArray)
        ? (
          <ConnectionContentItem
            joinMethod={null}
            isConnectBtnDisabled={isConnectBtnDisabled}
            meetingDetails={meetingDetails}
            eventId={eventId}
          />
        )
        : connectionArray.map((joinMethod, index) =>
          (
            <ConnectionContentItem
              joinMethod={joinMethod}
              isConnectBtnDisabled={isConnectBtnDisabled}
              meetingDetails={meetingDetails}
              eventId={eventId}
              key={index}
              hidePresenterLink={index !== connectionArray.length - 1}
            />
          )
        )
      }
    </div>
  );
};

export default ConnectionWrapper;
