export type JoinMethodType =
  | 'ZOOM'
  | 'WEBEX'
  | 'TEAMS'
  | 'BLUEJEANS'
  | 'KNOVIO'
  | 'OTHER'
  | 'IN_PERSON'
  | 'SHOWCASE_PAGE_PATH'
  | 'WEBINAR'
  | 'UNIQUE_JOINING_LINK'
  | 'VERACAST'
  | 'AUDIO_BRIDGE'
  | 'UNIQUE_DIAL_IN'
  | 'PANELIST_LINK'
  ;

export enum ConnectionVersion {
  V1 = 'V1',
  V2_SINGLE = 'V2_SINGLE',
  V2_MULTIPLE = 'V2_MULTIPLE',
}

export type ConnectionData = {
  connectionVersion: ConnectionVersion;
  joinMethods: MeetingJoinMethod[];
  meetingConnectionId: number;
}

export type MeetingJoinMethod = {
  type: JoinMethodType;
  joinMethodId?: number;
  registrationNeeded: boolean;
  order: number;
  embedCodeId?: string;
  connectionLink?: string;
  meetingLocation?: string;
  meetingInvitation?: string;
  showcasePagePath?: string;
  autoSyncEnabled?: boolean;
  publishEnabled?: boolean;
  detailsPlaceholderText?: string;
  conferenceId?: string | null;
  connectionPassword?: string | null;
  participantId?: string | null;
  participantOneTapDial?: string | null;
  participantTelephoneNumbers?: string | null;
  phoneNumbersSet?: PhoneNumber[] | null;
  uniqueDialInCode?: string | null;
  displayConfiguration: DisplayConfiguration | null;
};

export type DisplayConfiguration = {
  passportLabel?: string | null;
  passportDescription?: string | null;
  connectButtonLabel: string | null;
  connectionDetailsEnabled?: boolean;
  showHideButtonEnabled?: boolean;
  showDialInInfo?: string;
  hideDialInInfo?: string;
};

export type PhoneNumber = {
  priority: number,
  country: string,
  countryCode: string,
  telephoneNumber: string,
  unformattedTelephoneNumber: string,
  toll: string
  oneTapFormula: string;
};

export type SortedPhoneNumbers = {
  [country: string]: PhoneNumber[];
}

export type ConnectionDetailsType = {
  connection: MeetingJoinMethod,
  errorMessage?: string,
  meetingInvitation?: string,
  roomEndDateTime: string,
  roomStartDateTime: string,
}
