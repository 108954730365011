import React, { createContext, useContext } from 'react';
import { EventPrivateDetailsResponseType, EventPublicDetailsResponseType } from '../../api';
import { useEventPrivateInfo } from '../../../hooks/api/protected/use-event-private-details';

import {
  composersList,
  AgendaProps, LandingPageProps, SessionsProps, ShowcaseProps, SpeakersProps, SupportProps, WelcomeProps, ReplaysProps,
} from './settings-composers';
import { IAuthContext, useAuth } from '../auth-context';
import { useEventPublicInfo } from '../../../hooks/api/public/use-event-public-details';


export type EventPrivateSettingsType = LandingPageProps & ShowcaseProps & WelcomeProps & SupportProps & AgendaProps & SessionsProps & SpeakersProps & ReplaysProps;

export type EventSettingsContextType = EventPrivateDetailsResponseType & EventPrivateSettingsType;

export type EventPublicContextType = EventPublicDetailsResponseType;

const EventSettingsContext = createContext<EventSettingsContextType | void>(undefined);
export const useEventSettings = (): EventSettingsContextType | void => useContext(EventSettingsContext);

const useEventSettingsProvider = (): EventSettingsContextType | EventPublicContextType | void => {
  const auth = useAuth() as IAuthContext;
  const isAuthenticated = auth?.isAuthenticated;

  const { data: privateData } = useEventPrivateInfo();
  const { data: publicData } = useEventPublicInfo();

  if (isAuthenticated === false) return publicData;

  if (!privateData) return;

  const customPages = privateData.customPages || [];

  const pagesProps = composersList
    .reduce((props, composer) => ({ ...props, ...composer(customPages) }), {} as EventPrivateSettingsType)
  ;

  return { ...privateData, ...pagesProps };

};

type Props = { children: React.ReactNode | React.ReactNodeArray }
export const EventSettingsProvider: React.FunctionComponent<Props> = ({ children }: Props): JSX.Element => {
  const settings = useEventSettingsProvider() as EventSettingsContextType;

  return (
    <EventSettingsContext.Provider value={settings}>
      {children}
    </EventSettingsContext.Provider>
  );
};

export default EventSettingsProvider;
