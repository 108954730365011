import { ResponseError } from '../../../config/api';
import { useSWR, responseInterface } from '../../../config/overrides/swr';
import API, { PUBLIC, EVENT, OPEN_SESSION, PublicSessionMeetingDetails } from '../../../lib/api';


const { fetchEventGeneralSessionList } = API[PUBLIC];

export function useEventGeneralSessionList(eventId: string, showOpenMeetings: boolean | undefined): responseInterface<PublicSessionMeetingDetails[], ResponseError> {
  const keyFn = () => showOpenMeetings && eventId ? [ EVENT, eventId, OPEN_SESSION ] : null;

  return useSWR(keyFn, fetchEventGeneralSessionList);
}
