import Handlebars from 'handlebars';

export type TemplateProcessor = (template: string) => string


export const createTemplateProcessor = (templateContext: object): TemplateProcessor => {
  return (template: string) => {
    try {
      return Handlebars.compile(template)(templateContext);
    } catch {
      return template;
    }
  };
};
