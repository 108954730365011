/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onRegisterCompleted = /* GraphQL */ `
  subscription OnRegisterCompleted($registrationId: String!) {
    onRegisterCompleted(registrationId: $registrationId) {
      registrationId
      magicLink
      error
    }
  }
`;
export const onRegisterToMeetingCompleted = /* GraphQL */ `
  subscription OnRegisterToMeetingCompleted($registrationId: String!) {
    onRegisterToMeetingCompleted(registrationId: $registrationId) {
      registrationId
      registrationResult
    }
  }
`;
