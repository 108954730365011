/* eslint-disable @typescript-eslint/no-magic-numbers */
import { RoutesConfig } from "../config/routes";

export default function useLabelFromPath(path: string, routesConfig: RoutesConfig): string | undefined {
  const currentPath = path.slice(1);
  const indexOfFirstSlash = currentPath.indexOf('/');

  return indexOfFirstSlash !== -1 
    ? routesConfig[`/${currentPath.slice(0, indexOfFirstSlash)}`]?.label
    : routesConfig[path]?.label;
}
