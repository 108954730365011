import { ROOT, MEETING_PATH, PARTICIPANT_PATH, PRESENTER_PATH } from './paths';
import MeetingDetailsWrapper from '../../app/meet-links/meeting-details-wrapper';
import PresenterDetailsWrapper from '../../app/meet-links/presenter-details-wrapper';
import ParticipantDetailsWrapper from '../../app/meet-links/participant-details-wrapper';
import { RoutesConfig } from './index';


const routes: RoutesConfig = {
  [ ROOT ]: { exact: true },
  [ PRESENTER_PATH ]: { component: PresenterDetailsWrapper },
  [ PARTICIPANT_PATH ]: { component: ParticipantDetailsWrapper },
  [ MEETING_PATH ]: { component: MeetingDetailsWrapper }
};

export default routes;
