import React from 'react';
import { EventPublicDetailsResponseType } from '../../lib/api';
import { getEventOffsetGMT, TimeZoneDisplayTypeLabels } from '../../lib/helpers/dateHelper';
import DateRange from './DateRange';
import useSetKeyIdClass from '../../hooks/use-set-key-id-class';
import { utcToZonedTime } from 'date-fns-tz';
import { useTemplateProcessor } from '../../hooks/use-template-processor';

type PropsType = {
  details: {
    endTime: string,
    startTime: string,
    name: string,
    timeZone: string,
    logoTitle?: string,
    logoUrl?: string,
    eventTitleForPassport?: string,
    timeZoneDisplayType: string,
    customTimeZoneDisplay: string
  } & Partial<EventPublicDetailsResponseType>
}

const MeetingLinkEventDetails: React.FC<PropsType> = (props: PropsType) => {
  const {
    endTime,
    startTime,
    name,
    timeZone,
    logoTitle,
    logoUrl,
    eventTitleForPassport,
    publicDescription,
    timeZoneDisplayType,
    customTimeZoneDisplay = ''
  } = props.details;
  const startDateTime = utcToZonedTime(startTime, timeZone);
  const templateProcessor = useTemplateProcessor();
  const timeZoneLabel = getEventOffsetGMT(timeZoneDisplayType as TimeZoneDisplayTypeLabels, customTimeZoneDisplay, startDateTime, timeZone);
  const transformedTimeZone = timeZoneDisplayType === TimeZoneDisplayTypeLabels.Custom ? templateProcessor(timeZoneLabel) : timeZoneLabel;

  const classNameEventDetails = 'editable-' + useSetKeyIdClass() + '-event-details';
  const classNameEventDetailsName = `${classNameEventDetails}--name`;
  const classNameEventDetailsDate = `${classNameEventDetails}--date`;
  const classNameEventDetailsImage = `${classNameEventDetails}--image`;
  const classNameEventDetailsWrapper = `${classNameEventDetails}--wrapper`;
  const classNameEventDetailsTimeZone = `${classNameEventDetails}--time-zone`;
  const classNameEventDetailsPublicDescription = `${classNameEventDetails}--public-description`;

  return (
    <div className={`${classNameEventDetails} event-details md:w-3/4 lg:px-8`}>
      <div className={`${classNameEventDetailsWrapper} bg-white pb-2 pl-2`} >
        {logoUrl &&
          <div
            className={`${classNameEventDetailsImage} pt-5 lg:pt-10`}
          >
            <img
              src={logoUrl}
              alt={logoTitle}
              title={logoTitle}
              style={{ objectFit: 'contain', maxHeight: '8rem' }}
            />
          </div>
        }
        <h1
          className={`${classNameEventDetailsName} text-primary font-semibold pt-4 font-size-31px word-break pre-line`}
        >
          {eventTitleForPassport || name}
        </h1>
        <div className='time-date-wrapper'>
          <div
            className={`${classNameEventDetailsDate} uppercase`}
          >
            <DateRange
              startTime={startTime}
              endTime={endTime}
              timeZone={timeZone}
              formattedStartTimeClassName='font-size-14px font-semibold'
            />
          </div>
          <p className={`${classNameEventDetailsTimeZone} text-off-black font-size-11px`}>
            {transformedTimeZone}
          </p>
        </div>
        {publicDescription &&
          <div
            className={`${classNameEventDetailsPublicDescription} public-description pb-5`}
            dangerouslySetInnerHTML={{ __html: publicDescription }}
          >
          </div>
        }
      </div>
    </div>
  );
};

export default MeetingLinkEventDetails;
