import { MeetingDetailsType } from '../api';
import { JoinMethod } from '../join-method/join-method';
import { ConnectionData, ConnectionVersion, MeetingJoinMethod } from '../api/connection.types';

export class Connection {
  private readonly joinMethods: JoinMethod[];
  private readonly connectionVersion: ConnectionVersion;
  private readonly meetingConnectionId: number;

  public static create(meetingDetails: Partial<MeetingDetailsType> | undefined): Connection | null {
    if (!meetingDetails?.connection || !meetingDetails.connection.joinMethods) return null;

    return new Connection(meetingDetails.connection);
  }

  private constructor(connectionData: ConnectionData) {
    this.connectionVersion = connectionData.connectionVersion;
    this.joinMethods = this.createJoinMethods(connectionData.joinMethods);
    this.meetingConnectionId = connectionData.meetingConnectionId;
  }

  public getConnectionId(): number {
    return this.meetingConnectionId;
  }

  public getJoinMethods(): JoinMethod[] | null {
    if (!this.joinMethods || this.joinMethods.length === 0) {
      return null;
    }
    return this.joinMethods.sort((a, b) => a.order - b.order);
  }

  public isMultipleConnection(): boolean {
    return this.connectionVersion === ConnectionVersion.V2_MULTIPLE;
  }

  public getJoinMethod(): JoinMethod | null {
    if (!this.joinMethods || this.joinMethods.length === 0) {
      return null;
    }
    return this.joinMethods[0];
  }

  private createJoinMethods(joinMethods: MeetingJoinMethod[]): JoinMethod[] {
    return joinMethods.map(method => JoinMethod.create(method) as JoinMethod);
  }
}
