import { useMemo } from 'react';
import { PrivateMeetingSessionDetailsResponseType } from './../../../lib/api';
import { PrivateMeetingSessionList } from './GridLayout/index';
import { getMeetingsByStartTime, getActiveMeetings, IMeetingsByStartTime } from './helpers';

interface ComposeMeeting {
  date: string;
  activeMeetings: PrivateMeetingSessionList;
  meetingsByStartTime: IMeetingsByStartTime[];
}

const useComposeMeeting = (meetings:PrivateMeetingSessionDetailsResponseType[], date: string, showPastMeetings: boolean): ComposeMeeting => {

  const meetingsByStartTime = useMemo((): IMeetingsByStartTime[] => {
    return getMeetingsByStartTime(meetings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetings, date]);

  const activeMeetings = useMemo((): PrivateMeetingSessionList => {
    return getActiveMeetings(meetingsByStartTime, showPastMeetings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingsByStartTime, showPastMeetings]);

  return ({
    date,
    activeMeetings,
    meetingsByStartTime
  });
};

export { useComposeMeeting };