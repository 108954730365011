import React, { useCallback, useEffect, useState } from 'react';
import DialInSelect, { IDropdownOption } from '../../../components/_base/DialInSelect';
import { parsePhoneNumbers } from '../../../lib/helpers/text-formatters';
import Icon from '../../../components/_base/Icon';
import { JoinMethod } from '../../../lib/join-method/join-method';

import './DialInInfo.scss';
import { STRING_WITH_HYPHEN_REGEX } from '../../../lib/constants';
import { PhoneNumber } from '../../../lib/api/connection.types';

export type DialInInfoProps = {
  joinMethod: JoinMethod;
}

const DialInInfo: React.FunctionComponent<DialInInfoProps> = (props: DialInInfoProps): JSX.Element => {
  const {
    joinMethod
  } = props;

  const formattedDialInCode = joinMethod?.uniqueDialInCode.replace(STRING_WITH_HYPHEN_REGEX,'$1-');

  const getPhoneNumberInitial = (): IDropdownOption[] => {
    if (joinMethod.transformedAndSortedNumbersSet) {
      const phoneNumbersByCounty = joinMethod.getPhoneNumbersByCountry(countryInitial.value);
      return getPhoneNumberSelectOptions(phoneNumbersByCounty);
    } else {
      const parsedPhoneNumbers = parsePhoneNumbers(joinMethod.participantTelephoneNumbers, joinMethod.participantOneTapDial);
      return getPhoneNumberSelectOptions(parsedPhoneNumbers);
    }
  };

  const getPhoneNumberSelectOptions = (phoneNumbers :PhoneNumber[]): IDropdownOption[] => {
    return phoneNumbers.map(item => ({
      value: item.telephoneNumber,
      item: item,
    }));
  };

  const getCountryInitial = (countriesSelectOptios: IDropdownOption[]): IDropdownOption => {
    return countriesSelectOptios
      ? countriesSelectOptios[0]
      : { value: '' };
  };

  const getCountriesSelectOptions = (): IDropdownOption[] => {
    const countries = joinMethod.getCountries();
    return countries?.map(item => ({
      value: item,
    })) || [];
  };

  const countriesSelectOptios = getCountriesSelectOptions();
  const countryInitial = getCountryInitial(countriesSelectOptios);
  const phoneNumbersInitial = getPhoneNumberInitial();
  const countries = getCountriesSelectOptions();
  const [country, setCountry] = useState<IDropdownOption>(countryInitial);
  const [phoneNumbers, setPhoneNumbers] = useState<IDropdownOption[]>(phoneNumbersInitial);
  const [phoneNumber, setPhoneNumber] = useState<IDropdownOption>(phoneNumbersInitial[0]);
  const [filledOneTapFormula, setFilledOneTapFormula] = useState<string>('');

  const handleCountryChange = (dropdownOption: IDropdownOption): void => {
    const phoneNumbersByCounty = joinMethod.getPhoneNumbersByCountry(dropdownOption.value) ?? [];
    const phoneNumbersSelectOptions = getPhoneNumberSelectOptions(phoneNumbersByCounty);
    setCountry(dropdownOption);
    setPhoneNumbers(phoneNumbersSelectOptions);
    setPhoneNumber(phoneNumbersSelectOptions[0]);
  };

  const selectTelephoneNumber = (value: IDropdownOption): void => {
    setPhoneNumber(value);
  };

  const fillOneTapFormula = useCallback(() => {
    if (phoneNumber?.item?.unformattedTelephoneNumber) {
      const oneTapFormula = joinMethod.getFilledOneTapFormula(phoneNumber.item.unformattedTelephoneNumber as string);
      setFilledOneTapFormula(oneTapFormula);
    }
  }, [joinMethod, phoneNumber]);

  useEffect(() => {
    fillOneTapFormula();
  }, [fillOneTapFormula, joinMethod, phoneNumber]);

  return (
    <div className='dial-in-info-wrapper'>
      {phoneNumbers &&
        <div className='dial-in-info__phone-number'>
          <span className='dial-in-info__phone-icon-wrapper'>
            <a
              className={`${!phoneNumbers[0].value.length ? 'pointer-events' : ''} no-underline`}
              href={filledOneTapFormula}
              data-test-id='dial-in-link'
            >
              <Icon.PhoneIcon
                className='dial-in-info__phone-icon transform rotate-5'
                width='18px'
                height='18px'
              />
            </a>
          </span>
          <DialInSelect
            selectedOption={phoneNumber}
            onChange={selectTelephoneNumber}
            options={phoneNumbers}
          />
        </div>
      }
      {!joinMethod.uniqueDialInCode && (
        <>
          {joinMethod.conferenceId &&
            <div className='dial-in-info__field'>
              <label className='dial-in-info__label'>Conference ID</label>
              <span className='dial-in-info__value'>{joinMethod.conferenceId}</span>
            </div>
          }
          {joinMethod.participantId &&
            <div className='dial-in-info__field'>
              <label className='dial-in-info__label'>Participant ID</label>
              <span className='dial-in-info__value'>{joinMethod.participantId}</span>
            </div>
          }
          {joinMethod.connectionPassword &&
            <div className='dial-in-info__field'>
              <label className='dial-in-info__label'>Passcode</label>
              <span className='dial-in-info__value'>{joinMethod.connectionPassword}</span>
            </div>
          }
        </>
      )}
      {joinMethod.uniqueDialInCode &&
        <div className='dial-in-info__field'>
          <label className='dial-in-info__label'>Participant ID</label>
          <span className='dial-in-info__value'>{formattedDialInCode}</span>
        </div>
      }
      {joinMethod.transformedAndSortedNumbersSet && countries &&
        <div className='dial-in-info__field'>
          <label className='dial-in-info__label'>Country</label>
          <DialInSelect
            isCountryDropdown={true}
            selectedOption={country}
            onChange={handleCountryChange}
            options={countries}
          />
        </div>
      }
    </div>
  );
};

export default DialInInfo;
