/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react';
import { EventPrivateDetailsResponseType } from '../../../lib/api';
import { CustomPageProps, PassportPageType } from '../../../lib/api/custom-page.types';
import { EventPrivateSettingsType } from '../../../lib/context-providers/event-settings-context/event-settings-context';
import { MediaQueryContextType, useMediaQuery } from '../../../lib/context-providers/media-query-provider';
import { WelcomeFooterBlock } from './WelcomeFooterBlock';
import './WelcomeFooter.scss';

const WelcomeFooter = (props: EventPrivateDetailsResponseType & EventPrivateSettingsType): JSX.Element => {
  const { isMobile, isTable, isLaptop } = useMediaQuery() as MediaQueryContextType;
  const isSmallScreen = isMobile || isTable || isLaptop;

  const showedPages = props.customPages
    .slice()
    .sort((a, b) => (a.order || 0) - (b.order || 0))
    .filter(item => item.type !== 'WELCOME' && item.enabled && item.pageLinkEnabled);
  const pagesLength = showedPages.length;

  const className = 'editable-welcome-footer';
  const classNameWelcomeFooterTitle = `${className}--title`;
  const classNameWelcomeFooterWrapper = `${className}--wrapper`;
  const classNameWelcomeFooterContentWrapper = `${className}--content-wrapper`;
  const classNameWelcomeFooterDescription = `${className}--description`;

  const getItemsPerRow = (length: number): number => {
    const perRowOpts = [3, 2];
    const [defaultItemsPerRow] = perRowOpts;
    const option = perRowOpts.find(opt => length % opt === 0);

    return length === 1 ? 1 : option || defaultItemsPerRow;
  };
  const itemsPerRow = getItemsPerRow(pagesLength);

  const groupItemsPerRow = (items: CustomPageProps[]): Array<CustomPageProps[]> => {
    const _items = [...items];
    const result = [];

    while (_items.length) {
      result.push(_items.splice(0, itemsPerRow));
    }

    return result;
  };
  const groupedFooterItems = groupItemsPerRow(showedPages);

  const itemWidthClassName = itemsPerRow === 1 ? 'lg:w-2/3 text-center' : itemsPerRow === 2 ? 'lg:w-1/2' : 'lg:w-1/3';
  const footerBlockClassName = `${classNameWelcomeFooterWrapper} ${itemWidthClassName}`;
  const footerContentWrapperClassName = `${classNameWelcomeFooterContentWrapper} h-full`;

  const footerTitleClassName = `
    ${classNameWelcomeFooterTitle} ${'text-primary uppercase ls-1-4px flex align-center ' +
    (itemsPerRow === 1 ? 'justify-center' : '')}
  `;

  const getPath = (type: PassportPageType, externalLink: string, path: string): string | { pathname: string } => {
    if (type === PassportPageType.EXTERNAL_LINK) {
      return { pathname: externalLink };
    }

    if (path) {
      return path;
    } else {
      return type.toLocaleLowerCase();
    }
  };

  return (
    <div>
      {
        groupedFooterItems.map((items, rowIdx) => (
          <div
            key={rowIdx}
            className={`
              ${rowIdx === groupedFooterItems.length - 1 ? 'border-t border-b' : 'border-t'}
              ${className} flex flex-nowrap flex-col lg:flex-row items-stretch
              ${itemsPerRow === 1 ? 'justify-center' : ''}`
            }
          >
            {items.map((item, index) => (
              <WelcomeFooterBlock
                key={index}
                classNameWelcomeFooterDescription={classNameWelcomeFooterDescription}
                footerBlockClassName={footerBlockClassName}
                footerContentWrapperClassName={
                  `${footerContentWrapperClassName} ${isSmallScreen && (index !== items.length - 1) ? 'border-b' : ''}`
                }
                footerTitleClassName={footerTitleClassName}
                enabled={item.enabled}
                label={item.label}
                path={getPath(item.type, item.externalLink || '', item.path)}
                description={item?.linkDescription || ''}
                type={item.type}
              />
            ))}
          </div>
        ))}
    </div>
  );
};

export default WelcomeFooter;
