import React from 'react';
import { SessionLayout } from '../../../../lib/api/custom-page.types';
import { IGroupedMeetingsList } from '../GridLayout/index';
import { CarouselWrapperByDay } from './CarouselWrapper';
import { EventType } from '../LayoutWrapper';
import './Card.scss';

export interface ICarouselSection {
  event: EventType;
  selectedDate: string;
  showAllDates: boolean;
  showPastMeetings: boolean;
  meetings: IGroupedMeetingsList;
}

const CarouselViewByDay = (props: ICarouselSection): JSX.Element => {
  const { event, meetings, showAllDates, showPastMeetings } = props;
  const isCarouselViewByDay = event?.layout === SessionLayout.CAROUSEL_VIEW_BY_DAY;
  return (
    <>
      {
        Object.keys(meetings).map((date: string) => (
          <CarouselWrapperByDay
            key={date}
            event={event}
            selectedDate={date}
            meetings={meetings[date]}
            showAllDates={showAllDates}
            isCarouselViewByDay={isCarouselViewByDay}
            showPastMeetings={showPastMeetings}
          />
        ))
      }
    </>
  );
};

export { CarouselViewByDay };
