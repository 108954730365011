import React from 'react';
import Button from '../../components/button';

export type WatchButtonProps = {
  action: () => void;
  isContentExpanded: boolean;
  ariaLabel: string;
  className?: string;
}

export const MoreButton: React.FC<WatchButtonProps> = (props: WatchButtonProps): JSX.Element | null => {
  const { action, isContentExpanded, ariaLabel, className } = props;

  return (
    <Button.Common
      name='more-btn'
      className={`toggle ${className ? className : ''}`}
      aria-label={ariaLabel}
      onClick={action}
    >
      <span className='toggle-text'>{isContentExpanded ? 'Less' : 'More'}</span>
      <span className='toggle-arrow'>&#8250;</span>
    </Button.Common>
  );
};
