import React, { ChangeEvent, FocusEvent, KeyboardEvent } from 'react';
import { AUTO_COMPLETE_VALUES } from '../../lib/constants';
import { useTestDataAttrProps } from '../../lib/data-test-attrs';
import './Input.scss';

export type InputProps = {
  id: string
  label: string;
  name: string;
  placeholder?: string;
  value: string | number;
  type: string;
  error?: string | boolean;
  autoFocus?: boolean;
  span?: string
  onChange(e: ChangeEvent<HTMLInputElement>): void;
  onKeyPress?(e: KeyboardEvent<HTMLInputElement>): void;
  onFocus?(e: FocusEvent<HTMLInputElement>): void;
  onBlur?(e: FocusEvent<HTMLInputElement>): void;
  disabled?: boolean;
  withOverflowEllipsis?: boolean
};

export const Input: React.FunctionComponent<InputProps> = (props: InputProps): JSX.Element => {
  const inputClassName = `
    text-sm appearance-none outline w-full py-2 px-3 leading-tight 
    placeholder-gray-700 input-outline bg-white mb-1
    ${props.error ? ' border-red-600' : ''}
  `;

  const labelClassName = `block font-bold mt-3 text-sm mb-2 ${props.withOverflowEllipsis ? 'text-ellipsis' : ''} `;

  const attrProps = useTestDataAttrProps();

  const autoCompleteValue: string = props.name in AUTO_COMPLETE_VALUES ? AUTO_COMPLETE_VALUES[props.name] : 'off';

  return (
    <>
      <div className='flex'>
        <label className={labelClassName} title={props.label} htmlFor={props.id}>
          <span className='mr-1'>{props.label}</span>
          <span className='mt-3 text-sm mb-2 font-normal'>{props.span}</span>
        </label>
      </div>
      <input
        className={inputClassName}
        id={props.id}
        type={props.type}
        placeholder={props.placeholder}
        name={props.name}
        value={props.value}
        onFocus={props.onFocus}
        autoFocus={!!props.autoFocus}
        onKeyPress={props.onKeyPress}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
        autoComplete={autoCompleteValue}
        { ...attrProps }
      />
      {props.error && (typeof props.error === 'boolean') && <span className='text-red-600 text-xs py-4'>{props.error}</span>}
    </>
  );
};

export default Input;
