import React from "react";
import { Link } from 'react-router-dom';
import { PassportPageType } from "../../../lib/api/custom-page.types";

export interface IWelcomeFooterBlockProps{
  classNameWelcomeFooterDescription: string;
  footerBlockClassName: string;
  footerContentWrapperClassName: string;
  footerTitleClassName: string;
  enabled: boolean;
  path: string | { pathname: string };
  label: string;
  description: string;
  type: string;
}

export const WelcomeFooterBlock = (props: IWelcomeFooterBlockProps): JSX.Element | null => {
  const {
    classNameWelcomeFooterDescription,
    footerContentWrapperClassName,
    footerBlockClassName,
    footerTitleClassName,
    description,
    enabled,
    label,
    path,
    type
  } = props;

  if (!enabled) {
    return null;
  }

  return (
    <Link
      to={path}
      className={`${footerBlockClassName} no-underline bg-cool-gray-hover`}
      target={type === PassportPageType.EXTERNAL_LINK ? '_blank' : '_self'}
    >
      <div className='cursor-pointer h-full'>
        <div className={`${footerContentWrapperClassName}`}>
          <div className={footerTitleClassName}>
            <h2 className='font-bold truncate leading-9 text-sm text-primary'>{label}</h2>
            <span className='font-size-28px leading-8'>&nbsp;&#8250;</span>
          </div>
          <div className={`${classNameWelcomeFooterDescription} break-words`}>{description}</div>
        </div>
      </div>
    </Link>
  );
};