import React from 'react';

export type CheckboxProps = {
  label: string;
  value: boolean;
  error?: string | boolean;
  onChange(e: React.FormEvent<HTMLInputElement>): void;
  onBlur?(e: React.FormEvent<HTMLInputElement>): void;
};

export const Checkbox: React.FunctionComponent<CheckboxProps> = (props: CheckboxProps): JSX.Element => {
  const {
    onChange,
    error,
    value = false,
    onBlur,
    label
  } = props;

  const checkboxClassName = `
    text-sm border py-2 px-3 mt-3 leading-tight
    focus:outline-none focus:shadow-outline-none
    focus:border-current
    ${error ? ' border-red-600' : ''}
  `;

  return (
    <>
      <div className='flex items-baseline'>
        <span>
          <input
            type='checkbox'
            className={checkboxClassName}
            onChange={onChange}
            onBlur={onBlur}
            checked={value}
          />
        </span>
        <span className='ml-1 text-sm' dangerouslySetInnerHTML={{ __html: label }} />
      </div>
      {error && (typeof error === 'boolean') && <span className='text-red-600 text-xs py-4'>{error}</span>}
    </>
  );
};

export default Checkbox;
