import React from 'react';
import { utcToZonedTime } from 'date-fns-tz';
import { isSameDay } from 'date-fns';
import '../../shared/MeetingTimingBlock.scss';
import { getFormatTimezone, getFullDateInLocalFormat, getTimeInLocalFormat } from '../../../lib/helpers/dateHelper';
import { GetMeetingStatus, IMeetingTimingBlockProps } from '../../shared/MeetingTimingBlock';
import Buttons from '../../../components/button';
import useMeetingCalendar from '../../../hooks/use-meeting-calendar';
import useMeetingPath from '../../../hooks/use-meeting-path';
import useBypassToken from '../../../hooks/use-bypass-token';
import useAttendeeToken from '../../../hooks/use-attendee-token';
import usePasscodeStore from '../../../hooks/use-passcode-store';

const MeetingLinkMeetingTimingBlock: React.FC<IMeetingTimingBlockProps> = (props: IMeetingTimingBlockProps) => {
  const {
    timeZone,
    primaryColor,
    meetingDetails
  } = props;

  const {
    startDateTime,
    endDateTime,
    eventName,
    eventId
  } = meetingDetails;

  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const formattedHostStartDate = getFullDateInLocalFormat(startDateTime, timeZone);
  const formattedHostStartTime = getTimeInLocalFormat(startDateTime, timeZone);
  const formattedAttendeeStartTime = getTimeInLocalFormat(startDateTime, currentTimeZone);
  const attendeeStartDateTime = utcToZonedTime(startDateTime, currentTimeZone);
  const attendeeOffsetGMT = getFormatTimezone(attendeeStartDateTime);
  const formattedHostEndTime = getTimeInLocalFormat(endDateTime, timeZone);
  const formattedAttendeeEndTime = getTimeInLocalFormat(endDateTime, currentTimeZone);
  const attendeeEndDateTime = utcToZonedTime(endDateTime, currentTimeZone);
  const eventStartDateTime = new Date(startDateTime);
  const className = 'editable-session';
  const classNameSessionTableRowLeftDate = `${className}--table-row-left-date`;

  const isEventTimezoneTimeDifferents = (): boolean => {
    return (getTimeInLocalFormat(attendeeStartDateTime, currentTimeZone) === getTimeInLocalFormat(eventStartDateTime, timeZone));
  };

  const meetingStartDateTime = utcToZonedTime(startDateTime, timeZone);
  const eventOffsetGMT = getFormatTimezone(meetingStartDateTime, timeZone);
  const textPrimary = primaryColor ? 'text-primary' : '';

  const { fetchOutlookCalendarForMeetLink, fetchGoogleCalendarForMeetLink } = useMeetingCalendar();

  const calendarProps: { [key: string] : string } = {
    'attendeeToken': useAttendeeToken() || '',
    'meetingPath': useMeetingPath() || '',
    'bypassToken': useBypassToken() || '',
    'passcode': usePasscodeStore().getPasscode() || ''
  };

  return (
    <>
      {props.hideStartDate ||
        <div className='flex justify-between items-center'>
          <h1 className={`${textPrimary} font-size-15px font-bold uppercase ls-0-5px`}>
            {formattedHostStartDate}
          </h1>
          <div className='sm:pl-8'>
            <Buttons.OptionsButton
              label={'+ Add to Calendar'}
              name={'addToCalendar'}
              disabled={false}
              eventId={eventId || ''}
              eventName={eventName || ''}
              className='select-box--button-small'
              downloadIcsFile={() => fetchOutlookCalendarForMeetLink(`${eventName}.ics`, calendarProps)}
              openGoogleCalendar={() => fetchGoogleCalendarForMeetLink(calendarProps)}
            />
          </div>
        </div>
      }
      <div className={`${classNameSessionTableRowLeftDate} text-dark-off-black font-size-15px pt-1 lowercase ls-1-5px`}>
        {formattedHostStartTime}{props.hideEndDateTime || ` - ${formattedHostEndTime}`}
        <span className={`${'classNameEventDetailsTimeZone'} uppercase text-off-black font-size-12px ls-0-5px`}>
          ({eventOffsetGMT})
        </span>
      </div>
      {!isSameDay(eventStartDateTime, utcToZonedTime(startDateTime, timeZone)) &&
        <div className='text-off-black font-medium font-size-10px pt-1'>
          {getFullDateInLocalFormat(eventStartDateTime, currentTimeZone)}
        </div>}
      {!isEventTimezoneTimeDifferents() && <p className='text-off-black font-medium font-size-10px pt-1'>
        <span className='lowercase'>
          {formattedAttendeeStartTime}{props.hideEndDateTime || ` - ${formattedAttendeeEndTime}`}
        </span>
        &nbsp;({attendeeOffsetGMT})
      </p>}
      <GetMeetingStatus
        attendeeStartDateTime={attendeeStartDateTime}
        attendeeEndDateTime={attendeeEndDateTime}
        isMultiMeetingsBlock={props.isMultiMeetingsBlock}
      />
    </>
  );
};

export default MeetingLinkMeetingTimingBlock;
