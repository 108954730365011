import React, { useLayoutEffect, useRef, useState } from 'react';
import useConnectMeetingDisabled from '../../../hooks/use-connect-meeting-disabled';
import { PrivateMeetingSessionDetailsResponseType } from '../../../lib/api';
import { FEATURE_CONTENT_HIGHT_BEFORE_EXPAND } from '../../../lib/constants';
import { EventSettingsContextType, useEventSettings } from '../../../lib/context-providers/event-settings-context/event-settings-context';
import { DateFnsFormats, dateToFormattedString, getFullDateInUsEuFormat, getIsEnUsRegion, getTimeInLocalFormat } from '../../../lib/helpers/dateHelper';
import MeetingDetailsLinksMapper from '../../shared/MeetingDetailsLinks/MeetingDetailsLinksMapper';
import { EventType } from '../SessionWrapper/LayoutWrapper';
import { MeetingPresentersList } from '../SessionWrapper/MeetingPresenterList';
import { MeetingTopic } from '../SessionWrapper/MeetingTopic';
import ReplaysButtons from './ReplaysButtons';

export interface IReplaysFeatureMeetingTileProps {
  meeting: PrivateMeetingSessionDetailsResponseType;
  timeZone: string;
  isUpcomingSession?: boolean;
  event?: EventType;
  isClosestMeeting? : boolean;
}

const ReplaysFeatureMeetingTile = (props: IReplaysFeatureMeetingTileProps): JSX.Element => {
  const { meeting, timeZone, isUpcomingSession = false, event, isClosestMeeting = false } = props;

  const {
    thumbnailImageUrl,
    presenterOrganizations,
    topic,
    featured,
    startDateTime,
    notes,
    links = []
  } = meeting;

  const [contentExpanded, setContentExpanded] = useState(false);
  const [toggleVisible, setToggleVisible] = useState(false);

  const eventSettings = useEventSettings() as EventSettingsContextType;
  const isConnectBtnDisabled = useConnectMeetingDisabled(eventSettings, startDateTime);

  const formattedHostStartTime = getTimeInLocalFormat(startDateTime, timeZone);

  const fullDate = !isUpcomingSession
    ? getFullDateInUsEuFormat(startDateTime, timeZone)
    : dateToFormattedString(
      startDateTime,
      getIsEnUsRegion()
        ? DateFnsFormats.StringifiedUSShortDay
        : DateFnsFormats.StringifiedEUShortDay, timeZone
    );

  const expandableContentRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore*/
    const expandableScrollHeight = expandableContentRef.current?.scrollHeight ?? 0;

    if (expandableScrollHeight > FEATURE_CONTENT_HIGHT_BEFORE_EXPAND) {
      setContentExpanded(false);
      setToggleVisible(true);
    }
  }, []);

  return (
    <div
      className={`featured-card ${contentExpanded ? 'featured-card--expanded' : ''} ${!thumbnailImageUrl ? 'featured-card__image--empty' : ''}`}
      data-meeting-id={meeting.meetingId}
    >
      <div className='featured-card__img'>
        {thumbnailImageUrl &&
          <img
            src={thumbnailImageUrl}
            alt='thumbnailImageUrl'
            title='thumbnailImageUrl'
          />
        }
      </div>
      <div className='featured-card__content'>
        <div
          ref={expandableContentRef}
          className='featured-card__expandable-content'
        >
          <div className='featured-card__content-timing'>
            <div title={fullDate} className='featured-card__content-date'>
              {fullDate}
            </div>
            {isUpcomingSession && <span className='start-time'>{formattedHostStartTime}</span>}
          </div>
          <MeetingTopic
            orgs={presenterOrganizations ?? []}
            topic={topic}
            isFeatured={isUpcomingSession || featured}
            classNames='featured-card__content-topic'
          />
          <MeetingPresentersList
            orgs={presenterOrganizations ?? []}
            organizationClassName='featured-card__presenters-list-item'
            wrapperClassName='featured-card__presenters-list'
          />
          {links.length > 0 && <MeetingDetailsLinksMapper links={links} className='featured-card__links-mapper' dataTestId='replays-feature-card-links-mapper' />}
          {notes && <div className='flex flex-col notes word-break overflow-hidden' dangerouslySetInnerHTML={{ __html: notes }} />}
        </div>
        <ReplaysButtons
          toggleVisible={toggleVisible}
          contentExpanded={contentExpanded}
          setContentExpanded={() => setContentExpanded(prev => !prev)}
          isUpcomingSession={isUpcomingSession}
          startDateTime={startDateTime}
          meeting={meeting}
          isConnectBtnDisabled={isConnectBtnDisabled}
          event={event as EventType}
          isClosestMeeting={isClosestMeeting}
        />
      </div>
    </div>
  );
};

export default ReplaysFeatureMeetingTile;
