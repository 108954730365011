/*eslint-disable @typescript-eslint/no-var-requires*/
import { RouteProps } from 'react-router-dom';
import { PassportPageType } from '../../lib/api/custom-page.types';
import { AppConfigurator } from '../../lib/services/app-configuration/AppConfigurator';


export type RoutesConfig = Record<string, RouteProps & {
  _protected?: boolean,
  _public?: boolean,
  type?: PassportPageType,
  label?: string
}>;


export default (() => {
  if (AppConfigurator.isPassportProfile) return require('./passport.routes').default;
  if (AppConfigurator.isMeetLinksProfile) return require('./meet.routes').default;
  if (AppConfigurator.isFileDownloadProfile) return require('./file-download.routes').default;
})();
