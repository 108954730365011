import React, { createContext, useContext, useEffect, useState } from 'react';
import { AppConfigurator } from '../services/app-configuration/AppConfigurator';

export type AppContextType = {
  isConfigured: boolean | undefined;
};

const useAppConfigProvider = (): AppContextType => {
  const [isConfigured, setIsConfigured] = useState<boolean | undefined>(undefined);
  const setAppAsConfigured = () => setIsConfigured(true);
  const setAppAsNotConfigured = () => setIsConfigured(false);

  useEffect(() => {
    AppConfigurator
      .configure()
      .then(setAppAsConfigured)
      .catch(setAppAsNotConfigured);
  }, []);


  return {
    isConfigured
  };
};

const appConfigurationContext = createContext<AppContextType | void>(undefined);
export const useApp = (): AppContextType | void => useContext(appConfigurationContext);

type Props = {
  children: React.ReactNode | React.ReactNodeArray
}

export const AppConfigurationProvider: React.FunctionComponent<Props> = ({ children }: Props): JSX.Element => {
  const app = useAppConfigProvider() as AppContextType;

  return (
    <appConfigurationContext.Provider value={app}>
      {children}
    </appConfigurationContext.Provider>
  );
};

