import { CurrentUserResponseType } from '../api';
import { KV_PARAMS, TrackingParamsType } from './KvEmbedTrackingService';

type TrackingValueType = string;
export default class TrackingParamsMapper {
  static readonly DEFAULT_VALUE: string = '';
  static readonly KEY_LIST: Array<KV_PARAMS> = [
    KV_PARAMS.KV_ID,
    KV_PARAMS.KV_NAME,
    KV_PARAMS.KV_TOKEN
  ];

  static getValueByKey = (key: KV_PARAMS, data: CurrentUserResponseType): TrackingValueType => {
    switch (key) {
    case KV_PARAMS.KV_ID:
      return data?.email ?? TrackingParamsMapper.DEFAULT_VALUE;
    case KV_PARAMS.KV_NAME:
      return [
        data?.firstName ?? TrackingParamsMapper.DEFAULT_VALUE,
        data?.lastName ?? TrackingParamsMapper.DEFAULT_VALUE
      ].join(' ');
    case KV_PARAMS.KV_TOKEN:
      return data?.knovioToken ?? TrackingParamsMapper.DEFAULT_VALUE;

    default:
      return TrackingParamsMapper.DEFAULT_VALUE;
    }
  };

  static mapToParams = (data: CurrentUserResponseType): TrackingParamsType => {
    return TrackingParamsMapper.KEY_LIST
      .reduce((params, key) => {
        params[key] = TrackingParamsMapper.getValueByKey(key, data);
        return params;
      }, {} as TrackingParamsType);
  };
}
