import React, { FC, ReactNode, useState } from 'react';
import { PrivateMeetingDetailsResponseType, SpeakerListResponseType, SpeakerResponseType } from '../../../lib/api';
import OrganizationsList from '../../shared/OrganizationsList';
import '../../shared/MeetingDetails.scss';
import { PREVENTED_DETAILS_TEXT } from '../../shared/MeetingDetails';
import MeetingLinkMeetingTimingBlock from './MeetingLinkMeetingTimingBlock';
import { useSpeakersList } from '../../../hooks/api/protected/use-speaker-list';
import OrganizationsListWithDetails from '../../shared/OrganizationListWithDetails/OrganizationListWithDetails';
import { JoinMethod } from '../../../lib/join-method/join-method';

export interface IMeetingLinkMeetingDetailsProps {
  meetingDetails: PrivateMeetingDetailsResponseType;
  showDetailsAllowed?: boolean;
  timeZone: string;
  children: ReactNode;
  showTopNavBtns?: boolean;
  agendaLabel?: string;
  eventName?: string;
  eventId?: string;
  showAddToCalendarBtn?: boolean;
  eventCustomTitle?: string;
  eventType: string;
  joinMethod: JoinMethod | null;
}

const MeetingLinkMeetingDetails: FC<IMeetingLinkMeetingDetailsProps> = (props: IMeetingLinkMeetingDetailsProps) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);

  const {
    eventType,
    timeZone,
    meetingDetails,
    showDetailsAllowed,
    children,
    joinMethod
  } = props;
  const connectionDetails = joinMethod ? joinMethod.meetingInvitation : meetingDetails.connectionDetails;

  const speakers: SpeakerListResponseType = [];

  const { data } = useSpeakersList(String(meetingDetails.eventId)) as { data: SpeakerListResponseType | void };

  /* eslint-disable @typescript-eslint/no-unused-expressions */
  if (data) {
    [
      ...(meetingDetails.hostOrganizations || []),
      ...(meetingDetails.participantOrganizations || []),
      ...(meetingDetails.presenterOrganizations || [])
    ].forEach(organization => {
      organization.attendees?.map(attendee => {
        data.forEach(el => {
          if (el.speakers.find(speak => speak.attendeeId === attendee.attendeeId)) {
            speakers.push({ ...organization, speakers: [attendee as SpeakerResponseType]});
          }
        });
        return attendee;
      });
    });
  }

  return (
    <div className='meeting-details'>
      <div className='shadow-md bg-white md:w-full meeting-details-content-wrapper'>
        <div className='flex flex-col shadow-gray justify-between bg-white'>
          <div className='px-6 md:px-0 py-6 sm:pb-0 md:px-8 px-0 p-8'>
            <div className='meeting-details-content pb-5'>
              <div>
                <MeetingLinkMeetingTimingBlock meetingDetails={meetingDetails} timeZone={timeZone} />
              </div>
            </div>
            {eventType && <p className='text-sm mb-2'>
              <span className='font-size-13px font-bold'>{eventType}</span>
            </p>}
            {(meetingDetails.topic || meetingDetails.notes) && <div className='px-0 mb-6 sm:pr-12'>
              <h2 className='text-primary font-semibold font-size-18px mb-5 mt-1'>{meetingDetails.topic}</h2>
              <p className='text-sm whitespace-pre-wrap break-words' dangerouslySetInnerHTML={{ __html: meetingDetails.notes }} />
            </div>}
            {children &&
              <div className='children mb-6'>
                {children}
              </div>
            }

          </div>
          <div className='flex-grow relative bg-cool-gray px-6 py-6 md:px-8 px-0 p-8'>
            {meetingDetails.presenterOrganizations &&
              <OrganizationsList
                blockName=''
                organizations={meetingDetails.presenterOrganizations}
                isMeetingLink={true}
              />}
            {meetingDetails.hostOrganizations &&
              <OrganizationsList
                blockName=''
                organizations={meetingDetails.hostOrganizations}
                isMeetingLink={true}
              />}
            {speakers.length > 0 &&
              <OrganizationsListWithDetails
                organizations={speakers as SpeakerListResponseType}
                isMeetLink
              />
            }
            {
              (connectionDetails ?
                <div>
                  {connectionDetails && <div className='font-size-12px'>
                    <div
                      className={`connection-details-title font-semibold ${isDetailsOpen ? 'connection-details-title_expanded' : ''}`}
                    >
                      <button
                        aria-label={`${!isDetailsOpen ? 'Show Connection Details' : 'Hide Connection Details'}`}
                        onClick={() => setIsDetailsOpen(prev => !prev)}
                      >
                        <span>Connection Details</span>
                        <span>&#8250;</span>
                      </button>
                    </div>
                    {isDetailsOpen &&
                      <div className='meeting-link__connection-details font-size-14px pt-4 whitespace-pre-wrap leading-loose'>
                        {showDetailsAllowed ? connectionDetails : PREVENTED_DETAILS_TEXT}
                      </div>
                    }
                  </div>
                  }
                </div> :
                <div className='meeting-link__connection-stand-by text-left p-4 pl-0 text-sm'>
                  {!joinMethod && 'Connection details will be provided ahead of the meeting. Stand by.'}
                </div>)
            }
          </div>
        </div>
        {!meetingDetails && <p className='text-center pt-4 pb-8'>No meeting details yet</p>}
      </div>
    </div>
  );
};

MeetingLinkMeetingDetails.defaultProps = {
  showDetailsAllowed: true
};

export default MeetingLinkMeetingDetails;