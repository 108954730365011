import React, { RefObject, useRef, useEffect, useMemo, useState, useLayoutEffect, useCallback } from 'react';

interface DateOptionProps {
  isActive: boolean,
  label: string,
  parentRightPosition: number,
  className?: string,
  onSelectedDate: () => void ,
  selectedCount?: number,
}

export interface DatesProps {
  showAllDates: boolean;
  selectedDate: string;
  dates: string[];
  timeZone: string;
  onSelectedDateChange: (date: string) => void;
  isTogglePastMeetingsBtnShown: boolean;
  changeShowAllDates?: () => void;
  withoutAllDays?: boolean;
  selectedCountPerDay?: {[ date: string ]: number };
}

const DateOption = ({ isActive, label, onSelectedDate, parentRightPosition, className = '', selectedCount }: DateOptionProps): JSX.Element | null => {
  const nodeRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!nodeRef.current) return;
    const position = nodeRef.current.getBoundingClientRect();
    if (position.right > parentRightPosition) {
      nodeRef.current.style.visibility = 'hidden';
    } else {
      nodeRef.current.style.visibility = 'visible';
    }
  }, [parentRightPosition]);

  return (
    <div ref={nodeRef}>
      <button
        type='button'
        style={{
          color: 'var(--primary-color)',
          fontWeight: 700,
          lineHeight: 1.3,
          borderRadius: '7px',
          cursor: 'pointer',
          display: 'flex',
          maxWidth: '120px',
          width: 'max-content',
          whiteSpace: 'pre',
        }}
        onClick={onSelectedDate}
        className={`date-filter__option outline-auto-visible font-size-11px p-2 mr-3 ${className} flex flex-col items-start ${isActive ? 'bg-gray-300 date-filter__option--active' : 'bg-gray-100' } hover:bg-gray-200`}
      >
        <div className='DateOption__label'>{label}</div>
        { selectedCount !== undefined && <div className='DateOption__count font-normal'>{`${selectedCount} Selected`}</div> }
      </button>
    </div>
  );
};

const MoreDates = ({
  list,
  open,
  setOpen,
  selectedDate,
  showAllDates,
  onSelectedDateChange,
  selectedCountPerDay
}: {
  open: boolean,
  showAllDates: boolean,
  selectedDate: string,
  list: (string | null)[],
  setOpen: (i: boolean) => void,
  onSelectedDateChange: (date: string) => void,
  selectedCountPerDay?: {[ date: string ]: number },
}): JSX.Element => {
  const optionsButtonRef: RefObject<HTMLDivElement> | null = useRef<HTMLDivElement>(null);

  const handleOutsideClick = useCallback((e: Event) => {
    const target = e.target as HTMLElement;
    if (optionsButtonRef?.current?.contains(target)) {
      return;
    }
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsButtonRef]);

  useLayoutEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <div style={{ position: 'relative' }}>
      <button
        type='button'
        style={{
          color: 'var(--primary-color)',
          fontWeight: 700,
          lineHeight: 1.3,
          display: 'flex',
          alignItems: 'center',
          borderRadius: '7px',
          cursor: 'pointer',
        }}
        onClick={() => setOpen(!open)}
        className={`date-filter__option date-filter__more outline-auto-visible font-size-11px p-2 mx-3 flex items-center ${open ? 'bg-gray-300 date-filter__option--active' : 'bg-gray-100 font-bold' } hover:bg-gray-200`}

      >
        More Dates…
      </button>
      <div
        style={{
          top: '100%',
          zIndex: 1000,
          right: '0.75rem',
          borderRadius: '7px',
          overflowY: 'scroll',
          width: 'max-content',
          maxHeight: '282px',
          position: 'absolute',
          display: open ? 'block' : 'none',
          boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.16)'
        }}
        ref={optionsButtonRef}
        className='p-4 flex items-center bg-white see-more-box-select-box--container'
      >
        {
          list.map((date, i) => {
            const isActive = date === selectedDate && !showAllDates;
            return (
              <DateOption
                label={date || ''}
                isActive={isActive}
                parentRightPosition={1000000}
                className='mb-2'
                key={`${i} + ${date}`}
                onSelectedDate={() => {
                  setOpen(!open);
                  onSelectedDateChange(date || '');
                }}
                selectedCount={selectedCountPerDay?.[date || '']}
              />
            );
          })
        }
      </div>
    </div>
  );
};

const DateFilter = (props: DatesProps): JSX.Element => {
  const {
    showAllDates,
    isTogglePastMeetingsBtnShown,
    selectedDate,
    dates,
    onSelectedDateChange,
    changeShowAllDates,
    withoutAllDays,
    selectedCountPerDay
  } = props;

  const [parentRightPosition, setRightBorder] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const headerFilterContent: RefObject<HTMLDivElement> | null = useRef<HTMLDivElement>(null);

  const { hiddenDates } = useMemo(() => {
    let visibleDates: string[] = [];
    let hiddenDates: string[] = [];
    if (headerFilterContent.current) {
      setRightBorder(parentRightPosition);
      [...headerFilterContent?.current?.children].forEach((item) => {
        const clientRect = item.getBoundingClientRect();
        const itemLabel = item.getElementsByClassName('DateOption__label')?.[0];
        const text = itemLabel?.textContent || '';

        if (clientRect.right > parentRightPosition) {
          hiddenDates = hiddenDates.concat([text]);
        } else {
          visibleDates = visibleDates.concat([text]);
        }
      });
    }
    return ({
      visibleDates,
      hiddenDates,
    });
  }, [headerFilterContent, parentRightPosition]);

  const isShowMoreDate = Boolean(hiddenDates.length);

  const changeRightBorder = useCallback(() => {
    if (!headerFilterContent.current) return;
    const parentRightPosition = headerFilterContent?.current?.getBoundingClientRect().width;
    setRightBorder(parentRightPosition as number);
    setOpen(false);
  }, [headerFilterContent]);

  useLayoutEffect(() => {
    const changeBorder = () => {
      setRightBorder(parentRightPosition);
    };
    if (!headerFilterContent.current) return;
    const parentRightPosition = headerFilterContent.current.getBoundingClientRect().right;
    changeBorder();
  });

  useEffect(() => {
    window.addEventListener('resize', changeRightBorder);
    return () => window.removeEventListener('resize', changeRightBorder);
  }, [headerFilterContent, changeRightBorder]);

  return (
    <div
      className='flex items-center date-filter'
      style={{ minWidth: !isTogglePastMeetingsBtnShown ? '100%' : '200px', width: 'calc(100% - 200px)' }}
    >
      <div
        className='p-4 pl-0 flex date-filter__container'
        ref={headerFilterContent}
        style={{
          flex: 6,
          minWidth: '80px',
          overflow: 'hidden',
        }}
      >
        { !withoutAllDays && changeShowAllDates &&
          <DateOption
            label='All Dates'
            isActive={showAllDates}
            parentRightPosition={parentRightPosition}
            onSelectedDate={changeShowAllDates}
          />
        }
        {dates
          .slice()
          .map((label: string, key: number) => {
            const isActive = label === selectedDate && !showAllDates;
            return (
              <DateOption
                label={label}
                isActive={isActive}
                parentRightPosition={parentRightPosition}
                key={`${key} + ${label}`}
                onSelectedDate={() => onSelectedDateChange(label)}
                selectedCount={selectedCountPerDay?.[label]}
              />
            );
          })
        }
      </div>
      { isShowMoreDate
        ? (
          <MoreDates
            open={open}
            setOpen={setOpen}
            list={hiddenDates}
            selectedDate={selectedDate}
            showAllDates={showAllDates}
            onSelectedDateChange={onSelectedDateChange}
            selectedCountPerDay={selectedCountPerDay}
          />
        )
        : null
      }
    </div>
  );
};

export {
  DateOption,
  DateFilter,
  MoreDates
};
