import React, { FC } from 'react';
import { useCustomSettings } from '../../hooks/use-custom-settings';
import { OE_LOGO_IMG_PATH, POWERED_BY_ALT_TEXT } from '../../lib/constants';

const PoweredBy: FC = () => {
  const {
    showWebsiteFooterLogoImage,
    websiteFooterLogoUrl,
    websiteFooterLogoTitle,
  } = useCustomSettings();

  if (websiteFooterLogoUrl || showWebsiteFooterLogoImage) return (
    <div className='powered-by px-4 text-center'>
      {showWebsiteFooterLogoImage ? <span className='powered-by-text'>Powered By</span> : null}
      <img
        src={showWebsiteFooterLogoImage ? OE_LOGO_IMG_PATH : websiteFooterLogoUrl}
        alt={showWebsiteFooterLogoImage ? POWERED_BY_ALT_TEXT : websiteFooterLogoTitle}
        title={showWebsiteFooterLogoImage ? POWERED_BY_ALT_TEXT : websiteFooterLogoTitle}
        role='presentation'
        tabIndex={0}
        className={`inline-block max-h-6 ${showWebsiteFooterLogoImage ? 'oe-footer-logo-image' : ''}`}
      />
    </div>
  );
  return null;

};

export default PoweredBy;
