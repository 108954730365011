import React from 'react';
import { EventType } from '../SessionWrapper/LayoutWrapper';
import { IReplaysContext, TReplaysListResponse, useReplaysContext } from './ReplaysContextProvider';
import ReplaysFeatureMeetingTile from './ReplaysFeatureMeetingTile';
import ReplaysMeetingTile from './ReplaysMeetingTile';
import Button from '../../../components/button';
import { ReplaysPageLayoutOptions } from '../../../lib/api/custom-page.types';
import ReplaysSessionsByTrack from './ReplaysSessionsByTrack';

interface IProps {
  event: EventType;
  showAnchorElement: boolean;
  sessionLabel: string;
  upcomingSessionsLabel: string;
  pastSessions: TReplaysListResponse;
}

const ReplaysPastSessionsWrapper = (props: IProps): JSX.Element | null => {
  const { event, showAnchorElement, sessionLabel, upcomingSessionsLabel = 'Upcoming Sessions', pastSessions } = props;

  const { timeZone } = event;

  const {
    isValidatingPastSessions,
    pastSessionsRef,
    upcomingSessionsRef,
    scrollToRef,
    pageConfiguration
  } = useReplaysContext() as IReplaysContext;

  if (pastSessions && !!pastSessions?.length && !isValidatingPastSessions) {
    return (
      <div className='px-8'>
        {!!pastSessions.length &&
          <div>
            {showAnchorElement &&
              <div className='flex justify-between items-center'>
                <h1 className='section-label' ref={pastSessionsRef} data-test-id='replays-past-sessions-label'>{sessionLabel || 'Past Sessions'}</h1>
                <Button.Common
                  name='watch'
                  onClick={() => scrollToRef(upcomingSessionsRef)}
                  label={`View ${upcomingSessionsLabel}`}
                  className='replays-anchor'
                  data-test-id='view-upcoming-sessions'
                  ariaLabel={`View ${upcomingSessionsLabel}`}
                />
              </div>
            }
            { pageConfiguration.replaysLayout === ReplaysPageLayoutOptions.GridViewByMeetingDate
              ? <div className='flex flex-wrap'>
                {
                  pastSessions.map(meeting => {
                    return meeting.featured ?
                      <ReplaysFeatureMeetingTile
                        key={meeting.meetingId}
                        meeting={meeting}
                        timeZone={timeZone}
                      />
                      :
                      <ReplaysMeetingTile
                        key={meeting.meetingId}
                        event={event}
                        meeting={meeting}
                        timeZone={timeZone}
                      />;
                  })
                }
              </div>
              : <ReplaysSessionsByTrack sessions={pastSessions} event={event} />
            }
          </div>
        }
      </div>
    );
  }
  return null;
};

export default ReplaysPastSessionsWrapper;
