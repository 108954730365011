import { NOT_FOUND_PATH, PASSPORT_PATH_LIST, REGISTRATION_PATH } from '../config/routes/paths';
import { PassportPageType } from '../lib/api/custom-page.types';
import { getPathName } from '../lib/helpers/text-formatters';
import { AGENDA_MEETING_PATH_REGEX, SESSION_MEETING_PATH_REGEX } from '../lib/constants';

export default function useSetKeyIdClass(): string {
  const pathname = window.location.pathname;
  const key = '';

  if (NOT_FOUND_PATH === pathname) {
    return 'not-found';
  }

  if (AGENDA_MEETING_PATH_REGEX.test(pathname) || SESSION_MEETING_PATH_REGEX.test(pathname)) {
    return 'meeting-details';
  }

  if (PASSPORT_PATH_LIST.includes(pathname)) {
    return getPathName(pathname);
  }

  if (pathname.includes('stream')) {
    return 'stream';
  }

  if (pathname.startsWith(REGISTRATION_PATH)) {
    return 'registration';
  }

  return key;
}

export const getCustomPageIdKeyByType = (type: PassportPageType): string => {
  const key = {
    [ PassportPageType.KNOVIO as string ]: 'knovio',
    [ PassportPageType.EMBEDDED_WEBPAGE as string ]: 'embedded-webpage',
    [ PassportPageType.RICH_TEXT as string]: 'rich-text',
    [ PassportPageType.SINGLE_MEETING as string]: 'single-meeting',
    [ PassportPageType.EMBEDDED_HTML as string]: 'embedded-html'
  }[type];

  return key ? `custom-${key}` : '';
};
