import React from 'react';
import { SpeakerOrganizationResponseType, SpeakerResponseType, SpeakerWithOrganizationName } from '../../../../lib/api';
import OrganizationSpeaker from './OrganizationSpeaker/OrganizationSpeaker';
import './OrganizationList.scss';

interface IProps {
  organizations: Array<SpeakerOrganizationResponseType>;
  searchValue: string;
  searchedSpeakers: SpeakerWithOrganizationName[];
}

const OrganizationList: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    organizations,
    searchValue,
    searchedSpeakers,
  } = props;

  return (
    <div className={'organizations-list'}>
      {searchValue ?
        <>
          <div className='result-wrapper'>
            {searchedSpeakers.length ? searchedSpeakers.length : 'no'} search results for <pre>“{searchValue}”</pre>
          </div>
          <hr />
          {!!searchedSpeakers.length &&
            searchedSpeakers.map(s =>
              <OrganizationSpeaker key={s.attendeeId} speaker={s} organizationName={s.organizationName} />)
          }
        </> :
        organizations
          .map((organization: SpeakerOrganizationResponseType) => {
            return (
              <div id={`section${organization.organizationId}`} className={'organization'} key={organization.organizationId}>
                <h2>{organization.name}</h2>
                {organization.speakers.map((speaker: SpeakerResponseType) => {
                  return (
                    <OrganizationSpeaker key={speaker.attendeeId} speaker={speaker} organizationName={organization.name} />
                  );
                })}
              </div>
            );
          })
      }
    </div>
  );
};

export default OrganizationList;
