import { useEventPublicCustomFields, useEventPublicTriageAnswerCustomFields } from './api/public/use-event-public-custom-fields';
import { CustomFieldsType, RegistrationConfiguration } from '../lib/api';
import { ResponseError } from '../config/api';

interface ResponseType {
  loading: boolean;
  customFields: CustomFieldsType[] | undefined;
  triagedRegistrationEnabled: boolean | undefined;
  error?: ResponseError | undefined;
  configuration: RegistrationConfiguration | undefined;
}

export function useCustomFieldsSettings(eventId: number, registrationEnabled: boolean, selectedTriageAnswer?: string): ResponseType {
  const { data , error, isValidating } = useEventPublicCustomFields(eventId, registrationEnabled, selectedTriageAnswer);
  return {
    customFields: data?.fields,
    loading: !error && !isValidating,
    triagedRegistrationEnabled: data?.triagedRegistrationEnabled,
    configuration: data?.configuration,
  };
}

export function useCustomFieldsByTriageAnswerSettings(eventId: number, selectedTriageAnswer: string, registrationEnabled: boolean): ResponseType {
  const { data, error, isValidating } = useEventPublicTriageAnswerCustomFields(eventId, selectedTriageAnswer, registrationEnabled);
  return {
    customFields: data?.fields,
    triagedRegistrationEnabled: data?.triagedRegistrationEnabled,
    loading: !error && !isValidating,
    error: error,
    configuration: data?.configuration,
  };
}
