import { HTTP_STATUS } from '../config/api';
import { FooterLinkType } from '../lib/api';
import { useEventPublicInfo } from './api/public/use-event-public-details';
import { BACKGROUND_IMG_URL, DEFAULT_BACKGROUND_IMG_ULT_TEXT, ERR_MSG, PRIMARY_COLOR } from '../lib/constants';
import { AppConfigurator } from '../lib/services/app-configuration/AppConfigurator';

export interface IEventCustomSettings {
  backgroundFile: string | null,
  primaryColor: string | null,
  footerLinks: (FooterLinkType | void)[],
  loginErrorMessage: string,
  loginInstructions: string | null,
  pageTitle: string,
  customCss: string,
  nonExisted?: boolean,
  registrationEnabled?: boolean,
  signInPageLabel: string | undefined,
  showWebsiteLogoImage?: boolean,
  websiteLogoUrl?: string,
  websiteLogoTitle?: string,
  backgroundTitle: string,
  showWebsiteFooterLogoImage?: boolean,
  websiteFooterLogoUrl?: string,
  websiteFooterLogoTitle?: string,
  signInButtonLabel?: string,
  accessCodeRequestButtonLabel?: string,
  loading: boolean;
  buttonAccessCodeRequestEnabled?: boolean;
  buttonAccessCodeSignInEnabled?: boolean;
}

export function useCustomSettings(): IEventCustomSettings {
  const { data, error, isValidating } = useEventPublicInfo(AppConfigurator.isMeetLinksProfile || AppConfigurator.isFileDownloadProfile);
  const dataOrError = (!isValidating && data) || error;

  if (!dataOrError) {
    return {
      backgroundFile: null,
      backgroundTitle: '',
      primaryColor: null,
      footerLinks: [],
      loginErrorMessage: ERR_MSG,
      loginInstructions: null,
      pageTitle: AppConfigurator.getAppTitle() || '',
      customCss: '',
      signInPageLabel: undefined,
      showWebsiteLogoImage: false,
      websiteLogoUrl: '',
      websiteLogoTitle: '',
      showWebsiteFooterLogoImage: true,
      websiteFooterLogoUrl: '',
      websiteFooterLogoTitle: '',
      signInButtonLabel: '',
      accessCodeRequestButtonLabel: '',
      loading: true,
      buttonAccessCodeRequestEnabled: true,
      buttonAccessCodeSignInEnabled: true,
    };
  }

  /*
  * TODO:
  *  check this:
  * return {
    backgroundFile: dataOrError ? data?.backgroundFile ?? BACKGROUND_IMG_URL : null,
    primaryColor: dataOrError ? data?.primaryColor ?? PRIMARY_COLOR : null,
    footerLinks: dataOrError ? data?.footerLinks ?? [] : [],
    loginErrorMessage: dataOrError ? data?.loginErrorMessage ?? ERR_MSG : ERR_MSG,
    loginInstructions: dataOrError ? data?.loginInstructions ?? null : null,
    pageTitle: dataOrError ? data?.name ?? (process.env.REACT_APP_TITLE || '') : ''
  };
  * */
  return {
    backgroundFile: data?.backgroundFile ?? BACKGROUND_IMG_URL,
    backgroundTitle: data?.backgroundTitle ?? DEFAULT_BACKGROUND_IMG_ULT_TEXT,
    primaryColor: data?.primaryColor ?? PRIMARY_COLOR,
    footerLinks: data?.footerLinks ?? [],
    loginErrorMessage: data?.loginErrorMessage ? data?.loginErrorMessage : ERR_MSG,
    loginInstructions: data?.loginInstructions || null,
    pageTitle: data?.eventTitleForPassport || data?.name || AppConfigurator.getAppTitle() || '',
    customCss: data?.customCss || '',
    nonExisted: error?.status === HTTP_STATUS.NOT_FOUND,
    registrationEnabled: data?.registrationEnabled === true,
    signInPageLabel: data?.signInPageLabel,
    showWebsiteLogoImage: data?.showWebsiteLogoImage,
    websiteLogoUrl: data?.websiteLogoUrl,
    websiteLogoTitle: data?.websiteLogoTitle,
    showWebsiteFooterLogoImage: data?.showWebsiteFooterLogoImage,
    websiteFooterLogoUrl: data?.websiteFooterLogoUrl,
    websiteFooterLogoTitle: data?.websiteFooterLogoTitle,
    signInButtonLabel: data?.signInButtonLabel,
    accessCodeRequestButtonLabel: data?.accessCodeRequestButtonLabel,
    loading: false,
    buttonAccessCodeRequestEnabled: data?.buttonAccessCodeRequestEnabled,
    buttonAccessCodeSignInEnabled: data?.buttonAccessCodeSignInEnabled,
  };
}
