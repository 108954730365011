import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import useDefaultRoutePage from '../../hooks/use-default-route-page';
import { IAuthContext, useAuth } from '../../lib/context-providers/auth-context';
export const RedirectPage: React.FC<RouteProps> = (): JSX.Element | null => {
  const auth = useAuth() as IAuthContext;
  const landingPage = useDefaultRoutePage();
  if (auth.isAuthenticated === true) return <Redirect to={landingPage} />;
  return null;
};
