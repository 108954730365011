import React from 'react';
import { useParticipantDetails } from '../../../hooks/api/public/use-participant-details';
import DetailsWrapper, { PageType } from '../../shared/DetailsWrapper';
import { getCurrentTimezone } from '../../../lib/helpers/dateHelper';

type Props = Record<string, unknown>

export const ParticipantDetailsWrapper: React.FC<Props> = (props: Props): JSX.Element | null => {
  const { data } = useParticipantDetails();
  if (data) {
    const timeZone = data.eventTimeZone || getCurrentTimezone();

    return (
      <DetailsWrapper
        timeZone={timeZone}
        meetingDetails={data}
        type={PageType.PARTICIPANT} 
        errorMessage={data.errorMessage}
      />
    );
  }
  return null;
};

export default ParticipantDetailsWrapper;
