import { EventPrivateDetailsResponseType, EventPublicDetailsResponseType } from '../api';
import { TEMPLATE_VARIABLES, TIME_FORMATS, timezonesConvertor } from '../constants';
import { useResolveTime } from '../../hooks/use-resolve-time';

export type ContextDataType = EventPublicDetailsResponseType | EventPrivateDetailsResponseType

export const composeTemplateContext = (data: ContextDataType | void): object => {
  const resolveEventTime = useResolveTime;
  if (!data) return {};

  return {
    [TEMPLATE_VARIABLES.Event_Name]: resolveEventName(data),
    [TEMPLATE_VARIABLES.Event_Starttime12hr]: resolveEventTime(data.startTime, data.timeZone, TIME_FORMATS.Clock_Format_12hr),
    [TEMPLATE_VARIABLES.Event_Endtime12hr]: resolveEventTime(data.endTime, data.timeZone, TIME_FORMATS.Clock_Format_12hr),
    [TEMPLATE_VARIABLES.Event_Starttime24hr]: resolveEventTime(data.startTime, data.timeZone, TIME_FORMATS.Clock_Format_24hr),
    [TEMPLATE_VARIABLES.Event_Endtime24hr]: resolveEventTime(data.endTime, data.timeZone, TIME_FORMATS.Clock_Format_24hr),
    [TEMPLATE_VARIABLES.Event_Start_Date_Long_US]: resolveEventTime(data.startTime, data.timeZone, TIME_FORMATS.Event_Start_Date_Long_US),
    [TEMPLATE_VARIABLES.Event_Start_Date_Long_UK]: resolveEventTime(data.startTime, data.timeZone, TIME_FORMATS.Event_Start_Date_Long_UK),
    [TEMPLATE_VARIABLES.Event_Start_Day_D]: resolveEventTime(data.startTime, data.timeZone, TIME_FORMATS.Event_Start_Day_D),
    [TEMPLATE_VARIABLES.Event_Start_Day_DD]: resolveEventTime(data.startTime, data.timeZone, TIME_FORMATS.Event_Start_Day_DD),
    [TEMPLATE_VARIABLES.Event_Start_Day_DDD]: resolveEventTime(data.startTime, data.timeZone, TIME_FORMATS.Event_Start_Day_DDD),
    [TEMPLATE_VARIABLES.Event_Start_Day_DDDD]: resolveEventTime(data.startTime, data.timeZone, TIME_FORMATS.Event_Start_Day_DDDD),
    [TEMPLATE_VARIABLES.Event_Start_Month_M]: resolveEventTime(data.startTime, data.timeZone, TIME_FORMATS.Event_Start_Month_M),
    [TEMPLATE_VARIABLES.Event_Start_Month_MM]: resolveEventTime(data.startTime, data.timeZone, TIME_FORMATS.Event_Start_Month_MM),
    [TEMPLATE_VARIABLES.Event_Start_Month_MMM]: resolveEventTime(data.startTime, data.timeZone, TIME_FORMATS.Event_Start_Month_MMM),
    [TEMPLATE_VARIABLES.Event_Start_Month_MMMM]: resolveEventTime(data.startTime, data.timeZone, TIME_FORMATS.Event_Start_Month_MMMM),
    [TEMPLATE_VARIABLES.Event_Start_Year_YY]: resolveEventTime(data.startTime, data.timeZone, TIME_FORMATS.Event_Start_Year_YY),
    [TEMPLATE_VARIABLES.Event_Start_Year_YYYY]: resolveEventTime(data.startTime, data.timeZone, TIME_FORMATS.Event_Start_Year_YYYY),
    [TEMPLATE_VARIABLES.Event_End_Date_Long_US]: resolveEventTime(data.endTime, data.timeZone, TIME_FORMATS.Event_End_Date_Long_US),
    [TEMPLATE_VARIABLES.Event_End_Date_Long_UK]: resolveEventTime(data.endTime, data.timeZone, TIME_FORMATS.Event_End_Date_Long_UK),
    [TEMPLATE_VARIABLES.Event_End_Day_D]: resolveEventTime(data.endTime, data.timeZone, TIME_FORMATS.Event_End_Day_D),
    [TEMPLATE_VARIABLES.Event_End_Day_DD]: resolveEventTime(data.endTime, data.timeZone, TIME_FORMATS.Event_End_Day_DD),
    [TEMPLATE_VARIABLES.Event_End_Day_DDD]: resolveEventTime(data.endTime, data.timeZone, TIME_FORMATS.Event_End_Day_DDD),
    [TEMPLATE_VARIABLES.Event_End_Day_DDDD]: resolveEventTime(data.endTime, data.timeZone, TIME_FORMATS.Event_End_Day_DDDD),
    [TEMPLATE_VARIABLES.Event_End_Month_M]: resolveEventTime(data.endTime, data.timeZone, TIME_FORMATS.Event_End_Month_M),
    [TEMPLATE_VARIABLES.Event_End_Month_MM]: resolveEventTime(data.endTime, data.timeZone, TIME_FORMATS.Event_End_Month_MM),
    [TEMPLATE_VARIABLES.Event_End_Month_MMM]: resolveEventTime(data.endTime, data.timeZone, TIME_FORMATS.Event_End_Month_MMM),
    [TEMPLATE_VARIABLES.Event_End_Month_MMMM]: resolveEventTime(data.endTime, data.timeZone, TIME_FORMATS.Event_End_Month_MMMM),
    [TEMPLATE_VARIABLES.Event_End_Year_YY]: resolveEventTime(data.endTime, data.timeZone, TIME_FORMATS.Event_End_Year_YY),
    [TEMPLATE_VARIABLES.Event_End_Year_YYYY]: resolveEventTime(data.endTime, data.timeZone, TIME_FORMATS.Event_End_Year_YYYY),
    [TEMPLATE_VARIABLES.Event_Time_Zone]: resolveEventTimeZone(data.timeZone),
  };
};

export const resolveEventName = (data: ContextDataType): string => data.eventTitleForPassport || data.name;
export const resolveEventTimeZone = (timezone: string): string => timezonesConvertor[timezone] || timezone;
