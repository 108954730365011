import { useEffect, useState } from 'react';
import { fetchEventToken } from '../lib/api/protected';

export default function useEventToken(eventId: number): string {
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    fetchEventToken(`${eventId}`).then((response) => {
      setToken(response.token);
    }).catch(e => {
      setToken('');
    });
  }, [eventId]);

  return token;
}
