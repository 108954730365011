import React from 'react';
import { SpeakerOrganizationResponseType } from '../../../../lib/api';
import './OrganizationNavBar.scss';

interface IProps {
  organizations: Array<SpeakerOrganizationResponseType>
}

const OrganizationNavBar: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { organizations } = props;
  return (
    <div className={'organization-navbar'}>
      <nav>
        <ul>
          {organizations
            .map((el: SpeakerOrganizationResponseType) => {
              return (
                <li key={el.organizationId}>
                  <a href={`#section${el.organizationId}`}>
                    {el.name}
                  </a>
                </li>
              );
            })}
        </ul>
      </nav>
    </div>
  );
};

export default OrganizationNavBar;
