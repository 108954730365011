import React, { ReactNode } from 'react';
import AliceCarousel from 'react-alice-carousel';
import Icon from '../../../../components/_base/Icon';
import { useMediaQuery, MediaQueryContextType } from '../../../../lib/context-providers/media-query-provider';
import './Carousel.scss';

interface ICarousel {
  children?: ReactNode[];
  responsiveSettings: { [key: string] : { items: number } };  
  countSliders: { [key: string] : number };
  screenBreakpoints: { [key: string] : number };  
  isGeneralSessions?: boolean;
}

const getResponsive = (l: number, n: number): ReactNode[] => {
  if ((l % n) === 0) return [];
  const count = Math.floor(l / n);
  const mockTile = count === 0 ? n - l : (l - n * count);
  return [...Array(mockTile)].map(n => <div style={{ width : '199px', height: '312px', visibility: 'hidden' }} />);
};

const Carousel = ({
  children,
  responsiveSettings,
  countSliders,
  screenBreakpoints,
  isGeneralSessions = true
}: ICarousel): JSX.Element => {
  const mediaQuery = useMediaQuery() as MediaQueryContextType;

  const getButtonOpacity = (isDisabled: boolean): string => {
    let opacity = '';
    let count = null;
    let isCountLimit = null;
    if (!isGeneralSessions) {
      count = React.Children.toArray(children).filter(React.isValidElement).length;
      isCountLimit = window.innerWidth > screenBreakpoints.large && count <= countSliders.laptopLimit;
    }
  
    if (isCountLimit && !isGeneralSessions) {
      opacity = '0';
    } else {
      opacity = isDisabled ? '0.5' : '1';
    }
    return opacity;
  };
  
  const renderPrevButton = ({ isDisabled }: {
    isDisabled: boolean,
  }) => {
    const opacity = getButtonOpacity(isDisabled);
    return <span style={{ opacity: opacity }}>
      <Icon.ArrowRight className='fill-primary' height='55px' width='55px' />
    </span>;
  };

  const renderNextButton = ({ isDisabled }: {
    isDisabled: boolean,
  }) => {
    const opacity = getButtonOpacity(isDisabled);
    return <span style={{ opacity: opacity }}>
      <Icon.ArrowLeft className='fill-primary' height='55px' width='55px' />
    </span>;
  };

  const mockTile = React.useMemo((): ReactNode[] => {
    const items = (children || []);
    const count = React.Children.toArray(children).filter(React.isValidElement).length;
    let mockTile: ReactNode[];
    if (mediaQuery.isMobile) {
      mockTile = getResponsive(count, countSliders.mobLimit);
    } else if (mediaQuery.isTable) {
      mockTile = getResponsive(count, countSliders.tableLimit);
    } else {
      mockTile = getResponsive(count, countSliders.laptopLimit);
    }
    return [ ...items, ...mockTile ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, mediaQuery]);

  return (
    <AliceCarousel
      mouseTracking
      items={mockTile}
      disableDotsControls
      responsive={responsiveSettings}
      controlsStrategy="alternate"
      renderNextButton={renderNextButton}
      renderPrevButton={renderPrevButton}
    />
  );
};

export { Carousel };