import { format, utcToZonedTime } from 'date-fns-tz';
import { isToday, isFuture, isPast, compareAsc, formatISO, toDate } from 'date-fns';

const localFullDateFormatterOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric'
};

const time24FormatterOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: false
};

const localTimeFormatterOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: !!(new Date().toLocaleTimeString().match(/am|pm/i))
};


export enum DateFnsFormats {
  NumericDateTime12Hours = 'dd/MM/yy h:mm a',
  StringifiedDayMonthNumericDateYear = 'EEEE, MMMM d, yyyy',
  FullDate = 'yyyy-MM-dd HH:mm:ss.sssz',
  StringifiedFullDayMonthNumericDateYear = 'EEEE, MMMM dd, yyyy',
  StringifiedUSShortDay = 'EEEE MM/dd',
  StringifiedEUShortDay = 'EEEE dd/MM',
  StringifiedShortFullDateDay12Hours = 'EE MM/dd/yyy',
  StringifiedShortFullDateDay24Hours = 'EE dd/MM/yyy',
  ShortDayDate = 'MM/dd',
}

const defaultLocalRegion = 'default';
export const enUsRegion = 'en-us';
export const enGbRegion = 'en-gb';

export const getCurrentTimezone = ():string => Intl.DateTimeFormat().resolvedOptions().timeZone;

export function getIsEnUsRegion ():boolean {
  const dateLocalFormat = getDateFormatPattern();
  const month = dateLocalFormat.indexOf('m');
  const day = dateLocalFormat.indexOf('d');
  if (month < day) return true;
  else return false;
}

export const getIsToday = (date: string):boolean => isToday(new Date(date));

export const getIsPast = (date: string):boolean => isPast(new Date(date));

export const getIsFuture = (date: string):boolean => isFuture(new Date(date));

export const getCompareAsc = (prev: string | Date, next: string | Date):number => compareAsc(new Date(prev), new Date(next));

export const convertStringToDate = (date: string):Date => toDate(new Date(date));

export const getStartCurrentISODay = ():string => {
  const time = new Date();
  time.setHours(0, 0, 0, 0);
  return formatISO(time);
};

export const getStartISODay = (date: string):string => {
  const time = new Date(date);
  time.setHours(0, 0, 0, 0);
  return formatISO(time);
};

export const dateToFormattedString = (date: string | Date, dateFormat: string, anyTimezone?: string): string => {
  const dateToRender = anyTimezone ? utcToZonedTime(date, anyTimezone) : date;

  return format(dateToRender, dateFormat, anyTimezone ? { timeZone: anyTimezone } : undefined);
};

export const getFullDateInLocalFormat = (date: string | Date, timezone: string): string => {
  return utcToZonedTime(date, timezone).toLocaleDateString(defaultLocalRegion, localFullDateFormatterOptions);
};

export const getFullDateInUsEuFormat = (date: string | Date, timezone: string): string => {
  return utcToZonedTime(date, timezone).toLocaleDateString(getIsEnUsRegion() ? enUsRegion : enGbRegion, localFullDateFormatterOptions);
};

export const getFullLocalDate = (date: string | Date, timezone: string): Date => {
  return utcToZonedTime(date, timezone);
};

export const getTimeInLocalFormat = (date: string | Date, timezone: string): string => {
  return utcToZonedTime(date, timezone).toLocaleTimeString(defaultLocalRegion, localTimeFormatterOptions).replace(' ','');
};

export const getTimeIn24Format = (date: string | Date, timezone: string): string => {
  return utcToZonedTime(date, timezone).toLocaleTimeString(defaultLocalRegion, time24FormatterOptions).replace(':','');
};

export const getFormatTimezone = (dateTime: Date, timeZone?: string):string => {
  //Get timezone in +00 format
  const formatRegex = /^[+-][0]/;
  const timeZoneShort = timeZone ? format(dateTime,'xxx' ,{ timeZone }) : format(dateTime,'xxx' );
  //Remove trailing zero and negative sign
  const formattedTimezone = timeZoneShort.replace(formatRegex, `${timeZoneShort[0]}`);
  return `GMT ${formattedTimezone}`;
};

/**
 * Return current DateTime in ISO without ms
 */
export const getDateTimeIsoOmitMs = (): string => (new Date()).toISOString().split('.')[0] + 'Z';

export function getCurrentLocale(): string {
  return Intl.DateTimeFormat().resolvedOptions().locale;
}

export function getDateFormatPattern (): string {
  const locale = getCurrentLocale();
  const getPatternForPart = (part: Intl.DateTimeFormatPart) => {
    switch (part.type) {
    case 'day':
      return 'd'.repeat(part.value.length);
    case 'month':
      return 'm'.repeat(part.value.length);
    case 'year':
      return 'y'.repeat(part.value.length);
    case 'literal':
      return part.value;
    default:
      return '';
    }
  };
  return new Intl.DateTimeFormat(locale).formatToParts(new Date())
    .map(getPatternForPart)
    .join('');
}

export enum TimeZoneDisplayTypeLabels {
  Default = 'DEFAULT_GMT',
  OffsetWithGeography = 'OFFSET_WITH_GEOGRAPHY',
  Custom = 'CUSTOM',
}

export const getEventOffsetGMT = (
  timeZoneDisplay: TimeZoneDisplayTypeLabels,
  customTimeZoneDisplay: string,
  startDateTime: Date,
  timeZone: string
): string => {
  switch (timeZoneDisplay) {
  case TimeZoneDisplayTypeLabels.Default:
    return getFormatTimezone(startDateTime, timeZone);
  case TimeZoneDisplayTypeLabels.OffsetWithGeography:
    return `${getFormatTimezone(startDateTime, timeZone)} (${getLabelTimezoneOffset(timeZone)})`;
  case TimeZoneDisplayTypeLabels.Custom:
    return customTimeZoneDisplay ?? '';
  default:
    return getFormatTimezone(startDateTime, timeZone);
  }
};

export const getLabelTimezoneOffset = (timeZone: string): string => {
  return `${timezonesCodeLabelMap[timeZone] || timeZone}`;
};

export const timezonesCodeLabelMap: { [key: string]: string } = {
  'Pacific/Midway': 'Midway Island, Samoa',
  'Pacific/Honolulu': 'Hawaii',
  'America/Anchorage': 'Alaska',
  'America/Los_Angeles': 'Pacific Time (US and Canada)',
  'America/Denver': 'Mountain Time (US and Canada)',
  'America/Mazatlan': 'Mazatlan',
  'America/Phoenix': 'Arizona',
  'America/Chicago': 'Central Time (US and Canada)',
  'America/Regina': 'Saskatchewan',
  'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
  'America/Guatemala': 'Central America',
  'America/New_York': 'Eastern Time (US and Canada)',
  'America/Indianapolis': 'Indiana (East)',
  'America/Bogota': 'Bogota, Lima, Quito',
  'America/Halifax': 'Atlantic Time (Canada)',
  'America/Caracas': 'Caracas, La Paz',
  'America/Santiago': 'Santiago',
  'America/St_Johns': 'Newfoundland and Labrador',
  'America/Sao_Paulo': 'Brasilia',
  'America/Argentina/Buenos_Aires': 'Buenos Aires, Georgetown',
  'America/Godthab': 'Greenland',
  'Atlantic/Azores': 'Azores',
  'Atlantic/Cape_Verde': 'Cape Verde Islands',
  'Europe/London': 'Dublin, Edinburgh, Lisbon, London',
  'Africa/Casablanca': 'Casablanca, Monrovia',
  'Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Warsaw': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Africa/Algiers': 'West Central Africa',
  'Europe/Bucharest': 'Bucharest',
  'Africa/Cairo': 'Cairo',
  'Europe/Helsinki': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Athens': 'Athens, Istanbul, Minsk',
  'Asia/Jerusalem': 'Jerusalem',
  'Africa/Harare': 'Harare, Pretoria',
  'Europe/Moscow': 'Moscow, St. Petersburg, Volgograd',
  'Asia/Kuwait': 'Kuwait, Riyadh',
  'Africa/Nairobi': 'Nairobi',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Tehran': 'Tehran',
  'Asia/Dubai': 'Abu Dhabi, Muscat',
  'Asia/Baku': 'Baku, Tbilisi, Yerevan',
  'Asia/Kabul': 'Kabul',
  'Asia/Yekaterinburg': 'Ekaterinburg',
  'Asia/Tashkent': 'Islamabad, Karachi, Tashkent',
  'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi',
  'Asia/Kathmandu': 'Kathmandu',
  'Asia/Dhaka': 'Astana, Dhaka',
  'Asia/Calcutta': 'India',
  'Asia/Almaty': 'Almaty, Novosibirsk',
  'Asia/Rangoon': 'Yangon Rangoon',
  'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Shanghai': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
  'Asia/Taipei': 'Taipei',
  'Australia/Perth': 'Perth',
  'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
  'Asia/Seoul': 'Seoul',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
  'Asia/Yakutsk': 'Yakutsk',
  'Australia/Darwin': 'Darwin',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Sydney': 'Canberra, Melbourne, Sydney',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Hobart': 'Hobart',
  'Asia/Vladivostok': 'Vladivostok',
  'Pacific/Port_Moresby': 'Guam, Port Moresby',
  'Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia',
  'Pacific/Fiji': 'Fiji Islands, Kamchatka, Marshall Islands',
  'Pacific/Auckland': 'Auckland, Wellington',
  'Pacific/Apia': 'Independent State of Samoa'
};