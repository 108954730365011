import { utcToZonedTime } from 'date-fns-tz';
import React, { useEffect, useState, useRef, RefObject } from 'react';
import Icon from '../../components/_base/Icon';
import { convertStringToDate, DateFnsFormats, dateToFormattedString, getCurrentTimezone, getFullDateInLocalFormat } from '../../lib/helpers/dateHelper';
import './SelectDateInput.scss';

export interface IProps {
  onChangeSelection: (selectedDate: string) => void,
  dateOptions: string[],
  selectedDate: string,
  isMeetLink: boolean,
  timeZone: string,
  label: string,
}

const SelectDateInput: React.FC<IProps> = (props: IProps): JSX.Element | null => {
  const {
    onChangeSelection,
    selectedDate,
    dateOptions,
    isMeetLink,
    timeZone,
    label
  } = props;

  const [open, setOpen] = useState(false);
  const optionsButtonRef: RefObject<HTMLDivElement> | null = useRef<HTMLDivElement>(null);
  const options = dateOptions
    .slice()
    .sort((a: string, b: string) => new Date(a).getTime() - new Date(b).getTime())
    .map((date: string) => ({
      value: date,
      label:  getFullDateInLocalFormat(new Date(date), isMeetLink ? timeZone : getCurrentTimezone())
    }));

  const handleOutsideClick = (e: Event) => {
    const target = e.target as HTMLElement;
    if (optionsButtonRef?.current?.contains(target)) {
      return;
    }
    setOpen(false);
  };

  const handleSelect = (value: string) => {
    setOpen(false);
    onChangeSelection(value);
  };

  const toggle = () => setOpen((state) => !state);

  const compareDates = (currentDay: Date, optionDate: Date): boolean => {
    return (
      currentDay.getFullYear() === optionDate.getFullYear() &&
      currentDay.getMonth() === optionDate.getMonth() &&
      currentDay.getDate() === optionDate.getDate()
    );
  };

  const isCurrentDate = (date: string): boolean => {
    const currentDay = utcToZonedTime(new Date(), timeZone);
    const optionDate = new Date(date);
    return compareDates(currentDay, optionDate);
  };

  const isCurrentDateForUserTimezone = (date: string): boolean => {
    const currentDay = new Date();
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const optionDate = convertStringToDate(dateToFormattedString(new Date(date),DateFnsFormats.FullDate, getCurrentTimezone()).slice(0, 10));
    return compareDates(currentDay, optionDate);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className='select-date relative' >
      <div
        onClick={toggle}
        className='label common-btn outline outline-mid-gray on-hover-shadow py-1 px-2 rounded truncate cursor-pointer leading-4 flex justify-between'
      >
        <span className='flex items-center'>
          <span className='pr-2 font-size-12px font-medium'>{label}</span>
          <Icon.ExpandArrow width='11px' height='6px' viewBox="0 0 11 6" className='expanded-arrow ml-3' />
        </span>
      </div>
      <div
        ref={optionsButtonRef}
        style={{ width: open ? 'max-content' : '0' }}
        className={`${isMeetLink && 'select-box--container'} ${open ? 'h-max shadow-lg' : 'h-0 opacity-0'} bg-white absolute overflow-hidden border z-10`}
      >
        {open
          ? options.map((d: { value: string, label: string }, i: number) => {
            const isSelected = d.value === selectedDate;
            return (
              <div
                key={d.value}
                onClick={() => handleSelect(d.value)}
                className={`${isSelected ? 'bg-primary text-white' : ''} bg-cool-gray-hover cursor-pointer border-bottom font-size-12px font-medium h-full p-2 text-left`}
              >
                {d.label}
                {(isMeetLink ? isCurrentDateForUserTimezone(d.value) : isCurrentDate(d.value)) ? <span className='pl-4 text-primary font-semibold'>Today</span> : null}
              </div>
            );
          })
          : null}
      </div>
    </div>
  );
};

export default SelectDateInput;
