import React, { useRef } from 'react';
import useChangeBackgroundSize from '../../hooks/use-change-background-size';
import { CustomSettingsContextType, useSettings } from '../../lib/context-providers/custom-settings-context';


export const BackgroundImage: React.FC = (): JSX.Element | null => {
  const { backgroundImgUrl, backgroundImgTitle } = useSettings() as CustomSettingsContextType;
  const ref = useRef(null); 
  useChangeBackgroundSize(ref, 'event-public-info');

  if (backgroundImgUrl) return <img className='background-img' ref={ref} src={backgroundImgUrl} alt={backgroundImgTitle} />;
  return null;
};
