import { ResponseError } from '../../../config/api';
import { useSWR, responseInterface } from '../../../config/overrides/swr';
import API, { CustomFieldsResponseType, PUBLIC, EventPublicDetailsResponseType } from '../../../lib/api';
import { checkKeyArgs } from './use-meeting-public-details';

const {
  fetchPublicCustomFieldsByEventId,
  fetchPublicTriageAnswerCustomFieldsByEventId,
} = API[PUBLIC];

export interface EventPublicDetailsResponseTypeWithCustomField extends EventPublicDetailsResponseType {
  customFields?: CustomFieldsResponseType[];
}

export function useEventPublicCustomFields(eventId: number, registrationEnabled: boolean, selectedTriageAnswer?: string): responseInterface<CustomFieldsResponseType, ResponseError> {
  const keyFn = () => selectedTriageAnswer ? null : registrationEnabled ? eventId.toString() : null;
  return useSWR(keyFn, fetchPublicCustomFieldsByEventId);
}

export function useEventPublicTriageAnswerCustomFields(eventId: number, selectedTriageAnswer: string, registrationEnabled: boolean): responseInterface<CustomFieldsResponseType, ResponseError> {
  const composeKey = () => registrationEnabled ? checkKeyArgs(eventId, selectedTriageAnswer) : null;
  return useSWR(composeKey, fetchPublicTriageAnswerCustomFieldsByEventId);
}
