import React from 'react';
import { responseInterface } from 'swr';
import { Redirect } from 'react-router-dom';

import MeetingDetails from '../../../app/shared/MeetingDetails';
import EventDetails from '../../../app/shared/EventDetails';
import { HTTP_STATUS, ResponseError } from '../../../config/api';
import { useMeetingPrivateDetails } from '../../../hooks/api/protected/use-meeting-private-details';
import { useMeetingId } from '../../../hooks/use-meeting-id';
import { useEventId } from '../../../hooks/use-event-id';
import { PrivateMeetingDetailsResponseType } from '../../../lib/api';
import { EventSettingsContextType, useEventSettings } from '../../../lib/context-providers/event-settings-context/event-settings-context';
import useConnectMeetingDisabled from '../../../hooks/use-connect-meeting-disabled';
import useDefaultRoutePage from '../../../hooks/use-default-route-page';

import './MeetingWrapper.scss';

const MeetingWrapper: React.FC = () => {
  const meetingId = useMeetingId();
  const eventId = useEventId();
  const eventSettings = useEventSettings() as EventSettingsContextType;
  const landingPage = useDefaultRoutePage();
  const { data: meetingDetails, error } = useMeetingPrivateDetails(eventId, meetingId) as responseInterface<PrivateMeetingDetailsResponseType, ResponseError>;
  const isConnectBtnDisabled = useConnectMeetingDisabled(eventSettings, meetingDetails?.startDateTime);

  if (error && (error.status === HTTP_STATUS.FORBIDDEN || error.status === HTTP_STATUS.NOT_FOUND)) {
    return <Redirect to={landingPage} />;
  }

  if (eventSettings && meetingDetails) {
    return (
      <>
        <EventDetails details={eventSettings}/>
        <MeetingDetails
          meetingDetails={meetingDetails}
          showTopNavBtns={true}
          timeZone={eventSettings.timeZone}
          agendaLabel={eventSettings.agendaLabel}
          isConnectBtnDisabled={isConnectBtnDisabled}
          eventName={eventSettings.name}
          eventId={eventId}
          showAddToCalendarBtn={true}
          eventCustomTitle={eventSettings.eventTitleForPassport}
          className='min-h-24rem'
          meetingLinksLabel={eventSettings.meetingLinksLabel}
        />
      </>
    );
  }
  return null;
};

export default MeetingWrapper;
