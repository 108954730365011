export enum AppProfile {
  Passport = 'passport',
  MeetingsLinks = 'meet',
  FileDownload = 'download'
}

export enum AppEnvironment {
  DEV = 'development',
  QA = 'qa',
  STAGE = 'stage',
  PROD = 'prod'
}
export type Runtime = {
  REACT_APP_PROFILE: AppProfile;
  REACT_APP_FE_ENV: AppEnvironment;
  REACT_APP_API_ROOT: string;
  REACT_APP_TITLE: string;
}

export type AmplifyAuthSettings = {
  userPoolWebClientId: string;
  userPoolId: string;
  endpoint: string;
  aws_region: string;
}
export type AmplifyAppSyncSettings = {
  aws_appsync_graphqlEndpoint: string;
  aws_appsync_region: string;
};
