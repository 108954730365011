import React from 'react';
import Icon from '../../../../components/_base/Icon';

interface IProps {
  changeShowPastFlag: (showPastMeetings: boolean) => void,
  showPastMeetings: boolean,
  className?: string
}

const TogglePastSessionsBtn: React.FC<IProps> = (props: IProps): JSX.Element | null => {
  const {
    changeShowPastFlag,
    showPastMeetings,
    className = ''
  } = props;

  const classNamesPastMeetings = `outline-auto-visible font-medium hover:bg-gray-200 px-2 py-1 rounded-5px ${showPastMeetings ? 'fill-primary text-primary' : 'text-off-black-shadowed'}`;

  return (
    <button
      type='button'
      className={`${classNamesPastMeetings} text-xs self-center cursor-pointer sm:text-right md:whitespace-no-wrap on-hover-shadow focus:bg-white ${className}`}
      onClick={() => changeShowPastFlag(!showPastMeetings)}
    >
      {showPastMeetings
        ? <><i className='inline-block not-italic'>
          <Icon.Eye className='fill-visible align-top' viewBox="0 0 16 16" height='16px' />
        </i> Past Meetings Visible</>
        : <>
          <i className='inline-block not-italic'>
            <Icon.CrossedEye className='fill-off-black-shadowed align-top' viewBox="0 0 16 16" height='16px' />
          </i> Past Meetings Hidden
        </>}
    </button>
  );
};

export default TogglePastSessionsBtn;
