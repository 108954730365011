
interface ResponseType {
  downloadFile: (fileBlob: Blob, fileName: string) =>void;
}

function downloadFile(fileBlob: Blob, fileName: string): void {
  if (fileBlob) {
    const dataUrl = window.URL.createObjectURL(fileBlob);
    const a = document.createElement('a');
    a.href = dataUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(dataUrl);
    a.remove();
  }
}

export default function useDownloadFile(): ResponseType {
  return { downloadFile };
}
