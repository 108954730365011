import API, { AttendeeProcessingUrlResponse, PUBLIC } from '../../api';


export type PollingSettings = {
  pollingInterval?: number;
  pollingCancellationTimeout?: number;
}

export class AttendeeUrlProcessingPolling {
  private static get defaults(): PollingSettings {
    return {
      pollingInterval: 3000,
      pollingCancellationTimeout: 60*1000
    };
  }

  private intervalID = 0;
  private timeoutID = 0;

  private pollingSettings: PollingSettings;

  private async apiClientMethod(): Promise<AttendeeProcessingUrlResponse> {
    return API[PUBLIC].fetchAttendeeProcessedResultUrlByRegistrationParams(this.registrationId, this.appSyncToken);
  }

  public async start(): Promise<AttendeeProcessingUrlResponse> {
    return new Promise(async (resolve, reject) => {
      this.schedulePollingCancellation(reject);

      this.intervalID = window.setInterval(async() => {
        try {
          const response = await this.apiClientMethod();
          this.executeSubscriptionOnPollingStart();

          if (response?.type) {
            resolve(response);
            this.stop();
          }
        } catch (e) {
          reject(e);
          this.stop();
        }

      }, this.pollingInterval);
    });
  }

  public stop(): void {
    this.cancelPolling();
    this.resetPollingCancellation();
  }

  public subscribeToPollingStart(cb: () => void): void {
    this.pollingStartSubscription = cb;
  }

  private executeSubscriptionOnPollingStart(): void {
    if (this.pollingStartSubscription && !this.isPollingStartSubscriptionExecuted) {
      this.pollingStartSubscription();
      this.isPollingStartSubscriptionExecuted = true;
    }
  }

  private pollingStartSubscription?: () => void;
  private isPollingStartSubscriptionExecuted = false;

  private cancelPolling(): void {
    window.clearInterval(this.intervalID);
    this.intervalID = 0;
  }

  private schedulePollingCancellation(reject: () => void): void {
    window.setTimeout(() => {
      this.cancelPolling();
      reject();
    }, this.pollingCancellationTimeout);
  }

  private resetPollingCancellation(): void {
    window.clearTimeout(this.timeoutID);
    this.timeoutID = 0;
  }

  constructor(
    private readonly registrationId: string,
    private readonly appSyncToken: string,
    settings: Partial<PollingSettings> = {}
  ) {
    this.pollingSettings = {
      ...AttendeeUrlProcessingPolling.defaults,
      ...settings
    };
  }

  private get pollingInterval(): number {
    return this.pollingSettings.pollingInterval as number;
  }
  private get pollingCancellationTimeout(): number {
    return this.pollingSettings.pollingCancellationTimeout as number;
  }

}
