import { fetchGoogleCalendarLink, fetchOutlookCalendarFile } from '../lib/api/protected';
import {
  fetchGoogleCalendarLinkByAttendeeToken,
  fetchGoogleCalendarLinkByBypassToken,
  fetchGoogleCalendarLinkByPasscode,
  fetchOutlookCalendarFileByAttendeeToken,
  fetchOutlookCalendarFileByBypassToken,
  fetchOutlookCalendarFileByPasscode
} from '../lib/api/public';
import useDownloadFile from './use-download-file';

interface ResponseType {
  fetchOutlookCalendarForMeeting: (
    eventId: string,
    meetingId: string,
    fileName: string,
    eventToken: string,
    singleMeetingPath?: string
  ) => Promise<void>;
  fetchGoogleCalendarForMeeting: (
    eventId: string,
    fileName: string,
    eventToken: string,
    singleMeetingPath?: string
  ) => Promise<void>;
  fetchOutlookCalendarForMeetLink(fileName: string, calendarProps: { [key: string] : string }): Promise<void>;
  fetchGoogleCalendarForMeetLink(calendarProps: { [key: string] : string }): Promise<void>;
}

async function fetchOutlookCalendarForMeeting(
  eventId: string,
  meetingId: string,
  fileName: string,
  eventToken: string,
  singleMeetingPath?: string
): Promise<void> {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { downloadFile } = useDownloadFile();
  try {
    const fileBlob = await fetchOutlookCalendarFile(eventId, meetingId, eventToken, singleMeetingPath);
    downloadFile(fileBlob, fileName);
  } catch (e) {
    throw e;
  }
}

async function fetchGoogleCalendarForMeeting(
  eventId: string,
  meetingId: string,
  eventToken: string,
  singleMeetingPath?: string
): Promise<void> {
  try {
    const url = await fetchGoogleCalendarLink(eventId, meetingId, eventToken, singleMeetingPath);
    const { link = '' } = url;
    window.open(link);
  } catch (e) {
    throw e;
  }
}

async function fetchOutlookCalendarForMeetLink(fileName: string, calendarProps: { [key: string] : string }): Promise<void> {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { downloadFile } = useDownloadFile();

  const {
    attendeeToken,
    meetingPath,
    bypassToken,
    passcode
  } = calendarProps;

  try {
    let fileBlob;
    if (attendeeToken) {
      fileBlob = await fetchOutlookCalendarFileByAttendeeToken(meetingPath, attendeeToken);
    } else if (bypassToken) {
      fileBlob = await fetchOutlookCalendarFileByBypassToken(meetingPath, bypassToken);
    } else if (passcode) {
      fileBlob = await fetchOutlookCalendarFileByPasscode(meetingPath, passcode);
    }

    if (fileBlob) downloadFile(fileBlob, fileName);
  } catch (e) {
    throw e;
  }
}

async function fetchGoogleCalendarForMeetLink(calendarProps: { [key: string] : string }): Promise<void> {
  const {
    attendeeToken,
    meetingPath,
    bypassToken,
    passcode
  } = calendarProps;

  try {
    let url;
    if (attendeeToken) {
      url = await fetchGoogleCalendarLinkByAttendeeToken(meetingPath, attendeeToken);
    } else if (bypassToken) {
      url = await fetchGoogleCalendarLinkByBypassToken(meetingPath, bypassToken);
    } else if (passcode) {
      url = await fetchGoogleCalendarLinkByPasscode(meetingPath, passcode);
    }

    if (url) {
      const { link = '' } = url;
      window.open(link);
    }
  } catch (e) {
    throw e;
  }
}

export default function useMeetingCalendar(): ResponseType {
  return {
    fetchOutlookCalendarForMeeting,
    fetchGoogleCalendarForMeeting,
    fetchOutlookCalendarForMeetLink,
    fetchGoogleCalendarForMeetLink
  };
}
