import { AppSettings } from '../../api';
import { AmplifyAppSyncSettings, AmplifyAuthSettings } from './index';

export class AppSettingsMapper {
  public static toAmplifyAuthSettings(settings: AppSettings): AmplifyAuthSettings {
    return {
      aws_region: settings.region,
      endpoint: settings.cognitoUrl,
      userPoolId: settings.userPoolId,
      userPoolWebClientId: settings.userPoolClientId
    };
  }

  public static toAmplifyAppSyncSettings(settings: AppSettings): AmplifyAppSyncSettings {
    return {
      aws_appsync_region: settings.region,
      aws_appsync_graphqlEndpoint: settings.appSyncUrl
    };
  }
}
