import React, { useState } from 'react';
import { SpeakerResponseType } from '../../../../../lib/api';
import './OrganizationSpeaker.scss';

interface IProps {
  speaker: SpeakerResponseType;
  organizationName: string;
}

const OrganizationSpeaker: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { speaker, organizationName } = props;
  const [bioExpanded, setBioExpanded] = useState(false);

  return (
    <>
      <div className={'speaker'}>
        <div className={'speaker__details'}>
          <h3>{speaker.firstName} {speaker.lastName}</h3>
          <div className={'speaker__title'}>
            {speaker.title}
          </div>
          <div className={'speaker__organization'}>
            {organizationName}
          </div>
          {speaker.biography && <div className={`speaker__bio speaker__bio${bioExpanded ? '_expanded' : ''}`}>
            <button aria-label={'toggle bio'} onClick={() => setBioExpanded(!bioExpanded)}>
              <span>Bio</span>
              <span>&#8250;</span>
            </button>
            {
              bioExpanded && <p dangerouslySetInnerHTML={{ __html: speaker.biography }}/>
            }
          </div>}
        </div>
        <div className="speaker__photo">
          {speaker.profileImageUrl && <img src={speaker.profileImageUrl} alt={'Speaker'}/>}
        </div>
      </div>
      <hr/>
    </>
  );
};

export default OrganizationSpeaker;
