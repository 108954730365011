import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { responseInterface } from 'swr';
import { HTTP_STATUS, ResponseError } from '../../../config/api';
import { DefaultPathLabel } from '../../../config/routes/paths';
import { useMeetingPrivateDetails } from '../../../hooks/api/protected/use-meeting-private-details';
import useDefaultRoutePage from '../../../hooks/use-default-route-page';
import { useMeetingId } from '../../../hooks/use-meeting-id';
import { PrivateMeetingDetailsResponseType } from '../../../lib/api';
import { EventSettingsContextType, useEventSettings } from '../../../lib/context-providers/event-settings-context/event-settings-context';
import { EmbedContainer } from '../../../lib/kv-embed';
import { Connection } from '../../../lib/connection/connection';

type MeetingDetailsResponse = responseInterface<PrivateMeetingDetailsResponseType, ResponseError>;

const KnovioPageWrapper: React.FunctionComponent = (): JSX.Element | null => {
  const eventSettings = useEventSettings() as EventSettingsContextType;
  const meetingId = useMeetingId();
  const landingPage = useDefaultRoutePage();

  const eventId = eventSettings ? String(eventSettings.eventId) : '';

  const { data, error } = useMeetingPrivateDetails(eventId, meetingId) as MeetingDetailsResponse;

  const connection = data ? Connection.create(data) : null;
  const joinMethods = connection?.getJoinMethods();

  const meetingUnavailable = !!(error && (error.status === HTTP_STATUS.FORBIDDEN || error.status === HTTP_STATUS.NOT_FOUND));
  const connectionIsNotApplicable = !!(data && !joinMethods?.some(joinMethod => joinMethod.isKnovioAvailable));

  useEffect(() => {
    if (data) {
      const presenterOrgName = data?.presenterOrganizations?.length ? data?.presenterOrganizations[0].name : '';
      const meetingData = data.topic
        ? data.topic
        : presenterOrgName;
      document.title = `${ DefaultPathLabel.CONNECTION_KNOVIO_PATH_LABEL } | ${ meetingData }`;
    }
  }, [data]);

  const renderKnovioEmbed = () => {
    if (!joinMethods) {
      return null;
    }

    const knovioJoinMethod = joinMethods.find(joinMethod => joinMethod.isKnovioAvailable);

    if (!knovioJoinMethod) {
      return null;
    }

    return <EmbedContainer.Knovio embedId={knovioJoinMethod.embedCodeId} />;
  };

  if (meetingUnavailable || connectionIsNotApplicable) return <Redirect to={landingPage} />;

  return data ? renderKnovioEmbed() : null;
};

export default KnovioPageWrapper;
