import React from 'react';
import { SearchInput } from '../../../components/search-input/search-input';
import './DefaultPageHeader.scss';

export interface IDefaultPageHeaderProps {
  label: string;
  handleSearch?: (value: string) => void;
  notes?: string;
  dataTestId?: string;
}

const withSearch = 'default-page-title__with-search overflow-hidden break-all';

const DefaultPageHeader: React.FC<IDefaultPageHeaderProps> = (props: IDefaultPageHeaderProps): JSX.Element => {
  const { label, handleSearch, notes, dataTestId } = props;

  return (
    <>
      <div className='default-page-header'>
        <h1
          className={`default-page-title font-size-40px ${handleSearch ? withSearch : ''}`}
        >
          {label}
        </h1>
        {handleSearch && (
          <SearchInput
            onChange={handleSearch}
            withDebounce
            withResetSearchButton
          />
        )}
      </div>
      {notes && <div className='pb-8 px-8 word-break' dangerouslySetInnerHTML={{ __html: notes }} data-test-id={dataTestId} />}
      <hr/>
    </>
  );
};

export default DefaultPageHeader;
