import React from 'react';
import useConnectToPrivateMeetingMethod, { ConnectionPrivateDetailsType } from '../../hooks/use-connect-to-private-meeting-method';
import Button from '../../components/button';
import Icon from '../../components/_base/Icon';
import useConnectToPublicMeetingMethod, { ConnectionPublicDetailsType } from '../../hooks/use-connect-to-public-meeting-method';
import { PrivateMeetingDetailsResponseType, PublicMeetingDetailsResponseType } from '../../lib/api';
import { getKvThumbnailUrl } from '../../lib/api/utils';
import { PREVENTED_DETAILS_TEXT } from './MeetingDetails';
import { JoinMethod } from '../../lib/join-method/join-method';
import { CONNECT_TO_MEETING_LABEL } from '../../lib/constants';

export type JoinButtonProps = {
  joinMethod: JoinMethod | null;
  isDisabled: boolean;
  meetingDetails: PrivateMeetingDetailsResponseType | PublicMeetingDetailsResponseType | void;
  useConnectToMeetingMethod: typeof useConnectToPrivateMeetingMethod | typeof useConnectToPublicMeetingMethod;
  className?: string;
  joinUrl?: string | null;
  label?: string;
}

type ConnectionDetailsType = ConnectionPrivateDetailsType & ConnectionPublicDetailsType

export const ThumbnailJoinButton: React.FunctionComponent<JoinButtonProps> = (props): JSX.Element | null => {
  const { meetingDetails, useConnectToMeetingMethod, joinMethod } = props;
  const connectToMeeting = useConnectToMeetingMethod(meetingDetails as ConnectionDetailsType, joinMethod as JoinMethod);

  if (!meetingDetails) return null;

  return (
    <Button.Thumbnail
      className={'bg-transparent'}
      name='join'
      onClick={connectToMeeting}
    >
      <img
        className='h-full w-full object-contain'
        alt='knovio thumbnail'
        src={getKvThumbnailUrl(joinMethod?.embedCodeId)}
      />
    </Button.Thumbnail>
  );
};


export const JoinButton: React.FC<JoinButtonProps> = (props: JoinButtonProps): JSX.Element | null => {
  const { isDisabled, meetingDetails, useConnectToMeetingMethod, joinMethod, className, joinUrl, label = CONNECT_TO_MEETING_LABEL } = props;
  const connectDetails = isDisabled ? undefined : { ...props.meetingDetails, joinUrl };
  const connectToMeeting = useConnectToMeetingMethod(connectDetails as ConnectionDetailsType, joinMethod as JoinMethod);
  const shouldRenderJoinButton = isDisabled || (!joinMethod && !joinUrl);

  if (!meetingDetails) return null;

  return (
    <>
      <Button.Common
        name='join'
        disabled={shouldRenderJoinButton}
        onClick={connectToMeeting}
        label={label}
        data-meeting-id={meetingDetails.meetingId}
        className={className}
      >
        <Icon.RightArrow className='fill-current text-white mb-1 ml-2' height='15px' width='15px'/>
      </Button.Common>
      {shouldRenderJoinButton && <div className='join-button__connection-details  font-size-14px pt-4 whitespace-pre-wrap leading-loose'>
        <div className='connection-details-title font-semibold'>Connection Details</div>
        <div>{PREVENTED_DETAILS_TEXT}</div>
      </div>
      }
    </>
  );
};

export default (props: JoinButtonProps): JSX.Element | null => {
  if (!props.meetingDetails) return null;

  const joinMethod = props.joinMethod;

  return joinMethod && joinMethod.isKnovioConnectionType() && !props.isDisabled
    ? <ThumbnailJoinButton {...props} />
    : <JoinButton {...props} />
  ;
};
