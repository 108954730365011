import React from 'react';
import { Redirect } from 'react-router-dom';
import { ROOT } from '../../config/routes/paths';
import { useEventPublicInfo } from '../../hooks/api/public/use-event-public-details';
import EventError from '../shared/EventError';

const NotFoundPage: React.FC = (): JSX.Element | null => {
  const { data, error, isValidating } = useEventPublicInfo();

  if (!isValidating && error) return <EventError error={error} />;
  if (!isValidating && data) return <Redirect to={ROOT} />;
  return null;
};



export default NotFoundPage;
