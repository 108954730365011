import React, { useState } from 'react';
import Button from '../../../components/button';
import { LinkType } from '../../../lib/api';
import './MeetingDetailsLinks.scss';
import MeetingDetailsLinksMapper from './MeetingDetailsLinksMapper';

interface LinkProps {
  links: LinkType[]
  meetingLinksLabel?: string;
}

const MeetingDetailsLinks = (props: LinkProps): JSX.Element => {
  const { links = [], meetingLinksLabel = 'Downloads and Links' } = props;
  const [expanded, setExpanded] = useState(true);

  const toggleExpanded = () => setExpanded(!expanded);

  return (<div className={`pt-2 sm:pl-8 sm:pr-12 meeting-details-links ${expanded ? 'meeting-details-links__expanded' : ''}`}>
    <hr/>
    <Button.Common 
      aria-label={'toggle Downloads and Links'} 
      className={`toggle pl-8 `} 
      onClick={toggleExpanded}
      name={meetingLinksLabel}
      data-test-id='downloads-links-button'
    >
      <span className='font-size-13px font-bold'>{meetingLinksLabel}</span>
      <span className='font-size-24px line-height-18px'>&#8250;</span>
    </Button.Common>

    <div className='meeting-details-links__downloads-section'>
      { expanded && links.length > 0 && <MeetingDetailsLinksMapper links={links} dataTestId='meeting-details-links-mapper'/>}
    </div>
  </div>);

};

export default MeetingDetailsLinks;
