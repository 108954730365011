import React, { RefObject } from 'react';
import { MeetingOrganizationDetailsListType } from '../../../lib/api';

interface IProps {
  orgs: MeetingOrganizationDetailsListType,
  topic: string,
  isFeatured: boolean,
  isExpandedView?: boolean,
  gridView?: boolean,
  topicRef?: RefObject<HTMLDivElement> | null,
  classNames?: string
}

const MeetingTopic = (props: IProps): JSX.Element | null => {
  const {
    orgs,
    topic,
    isExpandedView,
    isFeatured,
    gridView = false,
    topicRef,
    classNames = ''
  } = props;
  const shouldRenderComponent: boolean = !!topic || !!orgs.length;
  if (!shouldRenderComponent) return null;

  let meetingTopicClassName = '';
  const featuredMeetingTopicClassName = 'text-xl text-black mb-2.5 font-normal';

  if (gridView) {
    meetingTopicClassName = isExpandedView ? 'font-size-16px font-semibold text-primary' : 'font-size-12px';
  } else {
    meetingTopicClassName = isFeatured ? 'font-size-20px font-semibold text-primary' : 'font-size-12px';
  }

  let className = '';
  if (isFeatured) {
    className += featuredMeetingTopicClassName;
    if (classNames) className += ` ${classNames}`;
  } else {
    className += meetingTopicClassName + ` word-break ${classNames}`;
  }

  return (
    <p
      ref={topicRef}
      title={topic}
      className={className}
    >
      {topic
        ? topic
        : (!!orgs.length) && <span>{orgs.map(o => o.name).join(', ')}</span>
      }
    </p>
  );
};

export { MeetingTopic };
