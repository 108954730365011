import React from 'react';
import { EventSettingsContextType, useEventSettings } from '../../../lib/context-providers/event-settings-context/event-settings-context';
import AgendaDetails from './AgendaDetails';
import EventDetails from '../../shared/EventDetails';
import { Redirect } from 'react-router-dom';
import isEnabledRoute from '../../../hooks/use-enabled-route';

const AgendaWrapper = (): JSX.Element | null => {
  const data = useEventSettings() as EventSettingsContextType;

  if (!data) return null;

  if (!data.agendaEnabled) {
    const enabledRoute = isEnabledRoute(data?.landingPage);
    return <Redirect to={enabledRoute} />;
  }

  return (
    <>
      <EventDetails details={data}/>
      <AgendaDetails event={data}/>
    </>
  );
};

export default AgendaWrapper;
