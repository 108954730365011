import { useEffect } from 'react';
import { PassportPageType, customPageTypes } from '../lib/api/custom-page.types';


export const getLayoutElement = (): HTMLDivElement | null => document.querySelector('[data-id="app"]');
export const composeClassName = (className: string): string => className ? 'custom-page-' + className : '';

export function useCustomPagePathAsCssClass(pagePath: string, pageType: PassportPageType, el?: HTMLDivElement | null): void {
  const element = el || getLayoutElement();
  const isCustomPage = pagePath && customPageTypes.includes(pageType);

  useEffect(() => {
    if (!isCustomPage) return;

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const className = composeClassName(pagePath.slice(1));

    if (element && !element.classList.contains(className)) {
      element.classList.add(className);
    }

    return () => {
      if (element && element.classList.contains(className)) {
        element.classList.remove(className);
      }
    };
  }, [ element, pagePath, isCustomPage ]);
}
