import { useReducer, useState } from 'react';
import Observable from 'zen-observable';
import { registerMeetingCompleteSubscription } from '../config/appsync';
import { OnRegisterToMeetingCompletedSubscription } from '../config/appsync/API';
import { Hub } from 'aws-amplify';
import { AttendeeUrlProcessingPolling } from '../lib/services/polling/attendee-url-processing-polling';

type SubscriptionData = { value: { data: OnRegisterToMeetingCompletedSubscription }};

export type MeetingRegistrationSubscriptionState = {
  inProgress: boolean,
  joinUrl: null | string | undefined,
  uniqueDialInCode: null | string | undefined,
  error: null | string
}

export enum ACTION_TYPE {
  INITIALIZED = 'INITIALIZED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

type ACTION =
  | { type: ACTION_TYPE.INITIALIZED }
  | { type: ACTION_TYPE.COMPLETED; payload: TCompletedData }
  | { type: ACTION_TYPE.FAILED, payload?: string }
  ;

export type RegistrationResponse = { registrationId: string, appSyncToken: string };

export type SubscriptionResult = {
  state: MeetingRegistrationSubscriptionState,
  subscribe(response: RegistrationResponse | void, isJoinMethodAudioBridge: boolean | void): Promise<void>
}

const _defaults: MeetingRegistrationSubscriptionState = {
  inProgress: false,
  error: null,
  joinUrl: null,
  uniqueDialInCode: null
};

type TJoinUrl = {
  joinUrl: string | null | undefined;
};

type TJuniqueDialInCode = {
  uniqueDialInCode: string | null | undefined;
};

type TCompletedData = TJoinUrl | TJuniqueDialInCode;

export function reducer(state: MeetingRegistrationSubscriptionState, action: ACTION): MeetingRegistrationSubscriptionState {
  switch (action.type) {
  case ACTION_TYPE.INITIALIZED:
    return { ..._defaults, inProgress: true };
  case ACTION_TYPE.COMPLETED:
    return { ..._defaults, ...action.payload };
  case ACTION_TYPE.FAILED:
    return { ..._defaults, error: action.payload || null };
  default:
    return _defaults;
  }
}


let timeoutId: number | null = null;

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const FIVE_MINUTE_TIMEOUT = 5* 60*1000;
const initialState = { inProgress: false, joinUrl: null, error: null, uniqueDialInCode: null };

export const useWebinarRegistrationSubscription = (): SubscriptionResult => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const [ cancelled, cancel ] = useState<boolean>(false);
  const cancelSubscription = () => cancel(true);

  const initSubscription = (client: {unsubscribe(): void, closed: boolean}) => {
    timeoutId = window.setTimeout(() => {
      if (!client.closed) client.unsubscribe();
      subscriptionFailed();
      cancelSubscription();
    }, FIVE_MINUTE_TIMEOUT);

    dispatch({ type: ACTION_TYPE.INITIALIZED });
  };
  const subscriptionCompleted = (payload: TCompletedData) => dispatch({ type: ACTION_TYPE.COMPLETED, payload });
  const subscriptionFailed = (payload?: string) => dispatch({ type: ACTION_TYPE.FAILED, payload });

  return {
    state,
    async subscribe(response: RegistrationResponse, isJoinMethodAudioBridge: boolean): Promise<void> {
      return new Promise((resolve, reject) => {
        const params = [response?.registrationId, response?.appSyncToken] as [string, string];

        if (!params.every(Boolean)) return;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore*/
        const client = (registerMeetingCompleteSubscription(...params) as Observable<SubscriptionData>)
          .subscribe({
            next({ value }: SubscriptionData): void {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore*/
              const error = value?.data?.onRegisterToMeetingCompleted?.error;
              const registrationResult = value?.data?.onRegisterToMeetingCompleted?.registrationResult ?? '';
              const completedDate: TCompletedData = isJoinMethodAudioBridge ? { uniqueDialInCode: registrationResult } : { joinUrl: registrationResult };

              if (!cancelled && !error) {
                subscriptionCompleted(completedDate);
              }

              if (!cancelled && error) {
                subscriptionFailed(error);
                if (timeoutId) window.clearTimeout(timeoutId);
              }

              client.unsubscribe();
            },
            error(): void {
              subscriptionFailed();
              client.unsubscribe();
            },
            complete(): void {
              if (timeoutId) window.clearTimeout(timeoutId);
              if (!client.closed) client.unsubscribe();
            }
          })
       ;

        initSubscription(client);

        const cancelSubscriptionOnConnectionFailure = (): void => {
          if (!client.closed) client.unsubscribe();
          if (timeoutId) window.clearTimeout(timeoutId);
          subscriptionFailed();
          cancelSubscription();
        };

        /* Resolve when the subscription connection is established or reject if client fails to connect */
        Hub.listen('api', ({ payload }) => {
          const { event, data } = payload;

          if (event === 'Subscription ack') resolve();

          if (data.connectionState === 'ConnectionDisrupted') {

            /* stop subscription client */
            if (!client.closed) client.unsubscribe();
            if (timeoutId) window.clearTimeout(timeoutId);

            const polling = new AttendeeUrlProcessingPolling(
              response?.registrationId,
              response?.appSyncToken,
              { pollingCancellationTimeout: FIVE_MINUTE_TIMEOUT }
            );

            polling.start()
              .then(response => {
                const completedDate: TCompletedData = isJoinMethodAudioBridge ? { uniqueDialInCode: response.registrationResult } : { joinUrl: response.registrationResult };
                subscriptionCompleted(completedDate);
              })
              .catch(() => {
                cancelSubscriptionOnConnectionFailure();
                reject(new Error('ConnectionDisrupted'));
              });

            polling.subscribeToPollingStart(resolve);
          }
        });
      });

    }
  };
};
