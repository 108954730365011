import React, { useState } from 'react';
import API, { PrivateMeetingSessionDetailsResponseType, PROTECTED } from '../../../../lib/api';
import { EventType } from '../../SessionWrapper/LayoutWrapper';
import { JOIN_STATUS } from '../../../../lib/constants';
import { showJoinButton } from '../../SessionWrapper/helpers';
import useConnectMeetingDisabled from '../../../../hooks/use-connect-meeting-disabled';
import MeetingStatus from '../MeetingStatus';
import { AddToCalendarButton, JoinButton, RegisterButton, ViewDetailsButton } from './CardButtonPresentation';

import './CardButtons.scss';

interface IProps {
  meeting: PrivateMeetingSessionDetailsResponseType;
  event: EventType;
  preRegistrationEnabled: boolean;
  isFeatured?: boolean
}

const CardButtons: React.FC<IProps> = (props: IProps): JSX.Element | null => {
  const { 
    meeting,
    event,
    preRegistrationEnabled,
    isFeatured = !!props.isFeatured,
  } = props;

  const {
    access,
    meetingId,
    joinStatus,
    startDateTime, 
  } = meeting;

  const { eventId, name, eventTitleForPassport } = event;

  const { attendMeeting, leaveMeeting } = API[PROTECTED];

  const [status, setJoinStatus] = useState(joinStatus);

  const changeJoinStatus = (newJoinStatus: string) => {
    meeting.joinStatus = newJoinStatus;
    setJoinStatus(newJoinStatus);
  };

  const toggleStatusOnCard = () => {
    const oldJoinStatus = status;
    let newJoinStatus = JOIN_STATUS.JOINED_AS_REGISTRANT;
    const eventId = event.eventId.toString();
    switch (oldJoinStatus) {
    case JOIN_STATUS.NOT_JOINED:
      attendMeeting(eventId, meetingId)
        .then(() => changeJoinStatus(newJoinStatus))
        .catch(() => setJoinStatus(oldJoinStatus));
      return;
    case JOIN_STATUS.JOINED_AS_REGISTRANT:
      newJoinStatus = JOIN_STATUS.NOT_JOINED;
      leaveMeeting(eventId, meetingId)
        .then(() => changeJoinStatus(newJoinStatus))
        .catch(() => setJoinStatus(oldJoinStatus));
      return;
    default: return;
    }
  };

  const isConnectBtnDisabled = useConnectMeetingDisabled(event, startDateTime);

  const showJoinViewButtons = (): JSX.Element | null => {
    if (showJoinButton(access, preRegistrationEnabled, status)) {
      const eventId = event.eventId.toString();
      return isConnectBtnDisabled 
        ? <ViewDetailsButton eventId={eventId} meetingId={meetingId}/> 
        : <JoinButton eventId={eventId} meetingId={meetingId}/>;
    } return null;
  };

  const showRegisterButtons = (className?: string): JSX.Element => {
    if (access === 'OPEN') {
      return <RegisterButton joinStatus={joinStatus as JOIN_STATUS} toggleStatusOnCard={toggleStatusOnCard} />;
    } return <div className={className}/>;
  };
  
  const showCalendarButtons = (): JSX.Element | null => {
    if (access === 'PLENARY' || status !== JOIN_STATUS.NOT_JOINED) {
      return (
        <AddToCalendarButton
          eventId={eventId}
          name={name}
          isFeatured={isFeatured}
          meetingId={meetingId}
          eventTitleForPassport={eventTitleForPassport || ''}
        />);
    } return null;
  };

  const showMeetingStatus = (): JSX.Element | null => {
    return <MeetingStatus startDateTime={startDateTime} className='card-buttons__featured-wrapper__status' />;
  };
  
  if (!isFeatured) {
    return (
      <div className='card-buttons card-buttons__non-featured-wrapper'>
        <div className='card-buttons__non-featured-wrapper__top-block'>
          {showJoinViewButtons()}
          {showCalendarButtons()}
        </div>
        {showRegisterButtons('base-register base-register__disabled')}
      </div>
    );
  } else {
    return (
      <div className='card-buttons'>
        <div className='card-buttons__featured-wrapper'>
          {showRegisterButtons()}
          {showJoinViewButtons()}
          {showCalendarButtons()}
          {showMeetingStatus()}
        </div>
      </div>
    );
  }

};

export default CardButtons;