import { RoutesConfig } from './index';
import { PUBLIC_IDENTIFIER, ROOT } from './paths';
import { FileDownloadWrapper } from '../../app/file-download/FileDownloadWrapper';


const routes: RoutesConfig = {
  [ROOT]: { exact: true },
  [PUBLIC_IDENTIFIER]: { component: FileDownloadWrapper },
};

export default routes;
