export enum PassportPageType {
  /* DEFAULT PAGES */
  AGENDA = 'AGENDA',
  SESSIONS = 'SESSION',
  SHOWCASE = 'SHOWCASE',
  SPEAKERS = 'SPEAKERS',
  SUPPORT = 'SUPPORT',
  WELCOME = 'WELCOME',
  REPLAYS = 'REPLAYS',

  /* CUSTOM PAGES */
  KNOVIO = 'KNOVIO',
  EMBEDDED_WEBPAGE = 'EMBEDDED_WEBPAGE',
  RICH_TEXT = 'RICH_TEXT',
  EMBEDDED_HTML = 'EMBEDDED_HTML',
  USER_CREATED_SHOWCASE = 'USER_CREATED_SHOWCASE',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  SINGLE_MEETING = 'SINGLE_MEETING'
}

export enum SessionLayout {
  GRID_VIEW_BY_TRACK = 'GRID_VIEW_BY_TRACK',
  CAROUSEL_VIEW_BY_DAY = 'CAROUSEL_VIEW_BY_DAY',
  CAROUSEL_VIEW_BY_TRACK = 'CAROUSEL_VIEW_BY_TRACK',
}

export enum UpcomingSessionsTypeOptions {
  NextSessionOnly = 'NEXT_SESSION_ONLY' ,
  AllUpcomingSessions = 'ALL_UPCOMING_SESSIONS'
}

export enum DefaultPgePath {
  Welcome = '/welcome',
  Agenda = '/agenda',
  Session = '/session',
  Showcase = '/showcase',
  Speakers = '/speakers',
  Support = '/support',
  Replays = '/replays'
}

export enum ReplaysPageLayoutOptions {
  GridViewByMeetingDate = 'GRID_VIEW_BY_MEETING_DATE',
  GridViewByTrack = 'GRID_VIEW_BY_TRACK',
}

export const defaultPagePathByType: Record<string, DefaultPgePath> = {
  [PassportPageType.AGENDA]: DefaultPgePath.Agenda,
  [PassportPageType.SESSIONS]: DefaultPgePath.Session,
  [PassportPageType.SHOWCASE]: DefaultPgePath.Showcase,
  [PassportPageType.SPEAKERS]: DefaultPgePath.Speakers,
  [PassportPageType.SUPPORT]: DefaultPgePath.Support,
  [PassportPageType.WELCOME]: DefaultPgePath.Welcome,
  [PassportPageType.REPLAYS]: DefaultPgePath.Replays,
};

export const defaultPageTypes = [
  PassportPageType.WELCOME,
  PassportPageType.AGENDA,
  PassportPageType.SESSIONS,
  PassportPageType.SHOWCASE,
  PassportPageType.SUPPORT,
  PassportPageType.SPEAKERS,
  PassportPageType.REPLAYS
];
export const customPageTypes = [
  PassportPageType.KNOVIO,
  PassportPageType.EMBEDDED_WEBPAGE,
  PassportPageType.EMBEDDED_HTML,
  PassportPageType.RICH_TEXT,
  PassportPageType.SINGLE_MEETING,
  PassportPageType.USER_CREATED_SHOWCASE,
  PassportPageType.EXTERNAL_LINK
];

interface CustomPage {
  enabled: boolean,
  publicEnabled: boolean,
  type: PassportPageType,
  label: string,
  landingPage: boolean,
  order: number,
  path: string,
  linkDescription: string;
  pageLinkEnabled: boolean;
  externalLink?: string;
}
/* PAGES WITH PREDEFINED ROUTE PATH */
export interface AgendaPageType extends CustomPage {
  type: PassportPageType.AGENDA;
  topicEnabled: boolean;
  path: DefaultPgePath.Agenda;
  scheduleNotes: string;
}
export interface SessionsPageType extends CustomPage {
  layout: SessionLayout;
  path: DefaultPgePath.Session;
  type: PassportPageType.SESSIONS;
  preRegistrationEnabled: boolean;
  sessionNotes: string;
  pastSessionsEnabled: boolean;
}
export interface ShowcasePageType extends CustomPage {
  type: PassportPageType.SHOWCASE;
  embedCodeId: string;
  path: DefaultPgePath.Showcase;
}
export interface SpeakersPageType extends CustomPage {
  type: PassportPageType.SPEAKERS;
  path: DefaultPgePath.Speakers;
}
export interface SupportPageType extends CustomPage {
  type: PassportPageType.SUPPORT;
  supportInformation: string;
  path: DefaultPgePath.Support;
}
export interface WelcomePageType extends CustomPage {
  type: PassportPageType.WELCOME;
  embedCodeId: string;
  welcomeKnovioEnabled: boolean;
  path: DefaultPgePath.Welcome;
  calendarButtonEnabled: boolean;
  eventToken: string;
}
export interface ReplaysPageType extends CustomPage {
  type: PassportPageType.REPLAYS;
  path: DefaultPgePath.Replays;
  upcomingSessionType: UpcomingSessionsTypeOptions;
  upcomingSessionsEnabled: boolean;
  upcomingSessionsLabel: string;
  replaysSessionLabel: string;
  replaysPreRegistrationEnabled: boolean,
  replaysNotes: string;
  replaysLayout: ReplaysPageLayoutOptions;
}

/* PAGES WITH CONFIGURABLE ROUTE PATH */
export interface KnovioPageType extends CustomPage {
  type: PassportPageType.KNOVIO;
  embedCodeId: string;
  welcomeKnovioEnabled: boolean;
}
export interface EmbeddedHTMLPageType extends CustomPage {
  type: PassportPageType.EMBEDDED_HTML;
  htmlTitle: string;
  htmlUrl: string;
}
export interface EmbeddedWebPageType extends CustomPage {
  type: PassportPageType.EMBEDDED_WEBPAGE;
  embeddedWebpageUrl: string;
  htmlUrl: string;
}
export interface RichTextPageType extends CustomPage {
  type: PassportPageType.RICH_TEXT;
  richText: string;
}
export interface SingleMeetingPageType extends CustomPage {
  type: PassportPageType.SINGLE_MEETING;
  meetingId: number;
}
export interface UserShowcasePageType extends CustomPage {
  type: PassportPageType.USER_CREATED_SHOWCASE;
  embedCodeId: string;
}
export interface ExternalLinkPageType extends CustomPage {
  type: PassportPageType.EXTERNAL_LINK;
  externalLink: string;
}

export type CustomPageProps =
  | AgendaPageType
  | SessionsPageType
  | ShowcasePageType
  | SpeakersPageType
  | SupportPageType
  | WelcomePageType
  | ReplaysPageType
  | KnovioPageType
  | EmbeddedHTMLPageType
  | EmbeddedWebPageType
  | RichTextPageType
  | ExternalLinkPageType
  | SingleMeetingPageType
;
