import React from 'react';
import { downloadFileByUrl } from '../../lib/api/utils';
import { useAssetDownloadUrl } from '../../hooks/api/public/use-asset-download-url';
import './file-download-wrapper.scss';

export const FileDownloadWrapper = (): JSX.Element => {
  const { data } = useAssetDownloadUrl();

  if (data?.signedUrl) downloadFileByUrl(data.signedUrl);

  return (
    <>
      <p className='file-download-text'>
        Your file is now downloading.  You can retrieve it from your browser’s downloads.
      </p>
    </>
  );
};
