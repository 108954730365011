import { useEffect, useState } from 'react';
import { MeetingAttendeeDetailsListType, MeetingOrganizationDetailsListType, ReplaysListResponseType } from '../lib/api';

type TOrganizationsOrAttendees = MeetingOrganizationDetailsListType | MeetingAttendeeDetailsListType;
type TMatchKeys = 'name' | 'fullname';
export type IUseSearchReplaysReturnType = {
  searchValue: string;
  searchedUpcomingSessions: ReplaysListResponseType;
  searchedPastSessions: ReplaysListResponseType;
  handleSearch: (inputValue: string) => void;
}

function useSearchReplays(upcomingSessions: ReplaysListResponseType, pastSessions: ReplaysListResponseType): IUseSearchReplaysReturnType {
  const [searchValue, setValue] = useState<string>('');
  const [searchedUpcomingSessions, setSearchedUpcomingSessions] = useState<ReplaysListResponseType>([]);
  const [searchedPastSessions, setSearchedPastSessions] = useState<ReplaysListResponseType>([]);

  useEffect(() => {
    setSearchedUpcomingSessions(upcomingSessions);
    setSearchedPastSessions(pastSessions);
  }, [upcomingSessions, pastSessions]);

  const handleSearch = (inputValue: string) => {
    const value = inputValue.toUpperCase().trim();
    if (!value) {
      setValue('');
      setSearchedUpcomingSessions(upcomingSessions);
      setSearchedPastSessions(pastSessions);
      return;
    }

    setSearchedUpcomingSessions(filteredMeetings(upcomingSessions ?? [] as ReplaysListResponseType, value));
    setSearchedPastSessions(filteredMeetings(pastSessions ?? [] as ReplaysListResponseType, value));
    setValue(inputValue);
  };

  return { searchedUpcomingSessions, searchedPastSessions, handleSearch, searchValue };
}

export default useSearchReplays;

function filteredMeetings(
  meetingsList: ReplaysListResponseType,
  searchedValue: string
): ReplaysListResponseType {
  const filteredMeetingsResult: ReplaysListResponseType = [];

  for (const meeting of meetingsList) {
    const isMatchTopic = meeting.topic.toUpperCase().indexOf(searchedValue) !== -1;
    if (isMatchTopic) {
      filteredMeetingsResult.push(meeting);
      continue;
    }
    const { presenterOrganizations } = meeting;
    const isMatch = filterByOrgNameOrFullname(presenterOrganizations as MeetingOrganizationDetailsListType, searchedValue, 0);

    if (isMatch) {
      filteredMeetingsResult.push(meeting);
    }
  }

  return filteredMeetingsResult;
}

const matchedKyes: TMatchKeys[] = ['name', 'fullname'];

function filterByOrgNameOrFullname<TItems extends TOrganizationsOrAttendees>(
  list: TItems,
  searchedValue: string,
  depth: number
): boolean {
  const matchedKey: TMatchKeys = matchedKyes[depth];
  switch (matchedKey) {
  case 'fullname':
    for (const attendee of list as MeetingAttendeeDetailsListType) {
      const fullName = attendee.firstName.toUpperCase().concat(' ', attendee.lastName.toUpperCase());
      if (fullName.indexOf(searchedValue) !== -1) {
        return true;
      }
    }
    break;

  case 'name':
    for (const presenterOrg of list as MeetingOrganizationDetailsListType) {
      const isMatchOrganizationName = presenterOrg.name.toUpperCase().indexOf(searchedValue) !== -1;
      if (isMatchOrganizationName) {
        return true;
      }

      const { attendees } = presenterOrg;
      const isMatch = filterByOrgNameOrFullname(attendees as MeetingAttendeeDetailsListType, searchedValue, depth + 1);
      if (isMatch) {
        return true;
      }
    }
    break;
  default:
    break;
  }

  return false;
}
