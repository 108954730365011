import React from 'react';
import { utcToZonedTime } from 'date-fns-tz';

interface IProps {
  startDateTime: string;
  className?: string;
}

const MeetingStatus = (props: IProps): JSX.Element | null => {
  const currentDate = new Date();
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const attendeeStartDateTime = utcToZonedTime(props.startDateTime, currentTimeZone);

  if (attendeeStartDateTime < currentDate) {
    return <p className={`text-primary italic font-size-11px pt-1 font-bold ${props.className}`} > In progress </p>;
  }
  return null;
};

export default MeetingStatus;
