import { AppEnvironment, AppProfile } from './index';
import passportAPI, { AppSettings, PUBLIC } from '../../api';
import { AppSettingsMapper } from './AppSettingsMapper';
import { Auth } from 'aws-amplify';
import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

export class AppConfigurator {
  public static getApiRoot(): string {
    return this.getRuntimeValueByKey('REACT_APP_API_ROOT');
  }
  public static getAppProfile(): AppProfile {
    return this.getRuntimeValueByKey('REACT_APP_PROFILE') as AppProfile;
  }
  public static getAppEnv(): AppEnvironment {
    return this.getRuntimeValueByKey('REACT_APP_FE_ENV') as AppEnvironment;
  }
  public static getAppTitle(): string {
    return this.getRuntimeValueByKey('REACT_APP_TITLE');
  }

  public static get isPassportProfile(): boolean {
    return this.getAppProfile() === AppProfile.Passport;
  }
  public static get isMeetLinksProfile(): boolean {
    return this.getAppProfile() === AppProfile.MeetingsLinks;
  }
  public static get isFileDownloadProfile(): boolean {
    return this.getAppProfile() === AppProfile.FileDownload;
  }

  public static getSSOAppUrl(): string {
    return this.getAppSettings().ssoAppUrl;
  }

  public static getUserPoolWebClientId(): string {
    return this.getAppSettings().userPoolClientId;
  }

  public static async configure(): Promise<void> {
    try {
      await this.initAppConfiguration();
      this.configureAmplify();
    } catch (e) {
      throw e;
    }
  }

  private static getRuntimeValueByKey(key: string): string {
    if (!Reflect.has(process.env, key)) throw new Error(`Runtime parameter "${key}" is not set`);
    return Reflect.get(process.env, key) as string;
  }

  private static getAppSettings(): AppSettings {
    this.throwIfNotConfigured();
    return this.appSettings;
  }

  private static throwIfNotConfigured(): void {
    if (!this.appSettings) throw new Error('Not configured');
  }
  private static appSettings: AppSettings;

  private static async initAppConfiguration(): Promise<void> {
    try {
      this.appSettings = await passportAPI[PUBLIC].fetchApplicationSettings();
    } catch (e) {
      throw new Error(`Failed to fetch app settings: ${e.message}`);
    }
  }
  private static configureAmplify(): void {
    try {
      this.configureAmplifyAuth();
      this.configureAmplifyAppSync();
    } catch (e) {
      throw new Error(`Failed to configure Amplify: ${e.message}`);
    }
  }

  private static configureAmplifyAuth(): void {
    const settings = AppSettingsMapper.toAmplifyAuthSettings(this.getAppSettings());
    Auth.configure({ Auth: settings });
  }

  private static configureAmplifyAppSync(): void {
    const settings = AppSettingsMapper.toAmplifyAppSyncSettings(this.getAppSettings());
    API.configure({
      ...settings,
      aws_appsync_authenticationType: GRAPHQL_AUTH_MODE.AWS_LAMBDA
    });
  }
}

