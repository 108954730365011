import React, { useState } from 'react';
import { Button } from '../../../components/_base/Button';
import useNavBarItemsConfig, { ConfigItemType } from '../../../hooks/use-navbar-items';
import { IAuthContext, useAuth } from '../../../lib/context-providers/auth-context';
import { useMediaQuery, MediaQueryContextType } from '../../../lib/context-providers/media-query-provider';
import { NavItem } from './NavBarItem';
import './HeaderNavBar.scss';

const className = 'my-0 px-4 sm:px-4 whitespace-no-wrap ' +
  'font-size-14px font-medium cursor-pointer ';

const HeaderNavBar: React.FC = (): JSX.Element | null => {
  const auth = useAuth() as IAuthContext;
  const isAuthenticated = !!auth.isAuthenticated;
  const isLoading = auth.isLoading;
  const { isMobile = false, isTable = false, isLaptop = false, isDesktop = false } = useMediaQuery() as MediaQueryContextType;
  const isMobileView = isMobile || isTable || isLaptop;
  const itemsConfig = useNavBarItemsConfig(isDesktop, isAuthenticated);

  const [ isOpen, setIsOpen ] = useState<boolean>(false);
  const toggleNavBar = () => setIsOpen(prevState => !prevState);
  const classNameHeader = 'editable-header';
  const classNameHeaderWrapper = `${classNameHeader}-wrapper`;

  type Props = {
    className: string,
    toggleNavBar?: () => void,
    items: ConfigItemType[] | null,
    isMobileView: boolean,
    isDesktop: boolean
  };
  const NavItems: React.FC<Props> = (props: Props): JSX.Element | null => {
    if (!props.items || isLoading) return null;
    return (
      <>
        <ul className={classNameHeaderWrapper + ' ' + props.className + ' w-full justify-end navigation-container'}>
          {props.items.map((item, i) => (
            <li key={i}>
              <NavItem
                {...item}
                isMobile={props.isMobileView}
                onClick={props.toggleNavBar}
              />
            </li>
          ))
          }
          { isAuthenticated &&
            <li>
              <Button
                name='signOut'
                className='border-none outline-auto-visible cursor-default justify-end flex'
                onClick={() => {
                  props.toggleNavBar && props.toggleNavBar();
                  auth.signOut();
                }}
              >
                <span {...{ className }}>Sign Out</span>
              </Button>
            </li>
          }
        </ul>
      </>
    );
  };
  NavItems.defaultProps = {
    isDesktop: false,
    isMobileView: false,
  };

  if (itemsConfig) return (
    <>
      {isDesktop &&
        <div className={`${classNameHeader} hidden justify-end w-3/4 lg:flex ml-auto`}>
          <NavItems className='flex' items={itemsConfig} isMobileView={isMobileView} isDesktop={isDesktop}/>
        </div>
      }

      {isMobileView &&
        <div className={`${classNameHeader} lg:hidden block ml-auto`}>
          <Button
            name='signOut'
            className={`${className} flex border-none focus:outline-none cursor-default`}
            onClick={toggleNavBar}
            aria-expanded={isOpen}
          >Menu
          </Button>
          {/* TODO: ADD handle click in mobile nav for close dropdown menu */}
          {isOpen &&
            <nav className="flex w-full bg-cool-gray absolute left-0 top-48px justify-end text-right">
              <div className="flex text-sm flex-col bg-cool-gray border-yellow-900 max-w-full w-full">
                <NavItems items={itemsConfig} isMobileView={isMobileView} isDesktop={isDesktop} className='flex flex-col' toggleNavBar={toggleNavBar}/>
              </div>
            </nav>
          }
        </div>
      }

    </>
  );
  return null;
};

export default HeaderNavBar;
