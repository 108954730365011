import { CustomPageProps, defaultPagePathByType } from '../lib/api/custom-page.types';
import { useEventPrivateInfo } from './api/protected/use-event-private-details';
import { isDefaultPage } from './use-custom-routes';


export default function useDefaultRoutePage(): string {
  const { data } = useEventPrivateInfo();
  if (!data || !data.customPages) return '';

  const pagebyLandingPageProp = data.customPages.find(route => route.landingPage && route.enabled) as CustomPageProps;
  if (pagebyLandingPageProp) {
    return isDefaultPage(pagebyLandingPageProp)
      ? defaultPagePathByType[pagebyLandingPageProp.type]
      : `/${pagebyLandingPageProp.path}`;
  }

  const pagebyEnableProp = data.customPages.find(route => route.enabled) as CustomPageProps;
  if (pagebyEnableProp) {
    return isDefaultPage(pagebyEnableProp)
      ? defaultPagePathByType[pagebyEnableProp.type]
      : `/${pagebyEnableProp.path}`;
  }

  return '';
}
