import { useState } from 'react';
import { SpeakerListResponseType, SpeakerWithOrganizationName } from '../lib/api';

type IUseSearchSpeakersReturnType = {
  searchValue: string;
  searchedSpeakers: SpeakerWithOrganizationName[];
  handleSearch: (inputValue: string) => void;
}

function useSearchSpeakers(data: SpeakerListResponseType): IUseSearchSpeakersReturnType {
  const [searchValue, setValue] = useState('');
  const [searchedSpeakers, setSpeakers] = useState<SpeakerWithOrganizationName[]>([]);

  const handleSearch = (inputValue: string) => {
    const value = inputValue.toUpperCase().trim();
    if (!value) {
      setValue('');
      return;
    }

    const resultSpeakers = (data as SpeakerListResponseType).reduce((
      filteredOrganizations: SpeakerWithOrganizationName[],
      organization) => {
      const isMatchWithOrgName = organization.name.toUpperCase().indexOf(value) !== -1;

      const filteredSpeakers = organization.speakers.reduce((result: SpeakerWithOrganizationName[], speaker) => {
        const { firstName, lastName, title } = speaker;
        const fullName = firstName.toUpperCase().concat(' ', lastName.toUpperCase());

        if (fullName.indexOf(value) !== -1
        || (!!title && title.toUpperCase().indexOf(value) !== -1)) {
          result.push({
            organizationName: organization.name,
            ...speaker
          });
        }
        return result;
      }, []);

      if (isMatchWithOrgName) {
        if (filteredSpeakers.length) {
          filteredOrganizations.push(...filteredSpeakers);
        } else {
          filteredOrganizations.push(...organization.speakers.map(s => ({
            organizationName: organization.name,
            ...s,
          })));
        }
      }

      if (!isMatchWithOrgName && filteredSpeakers.length) {
        filteredOrganizations.push(...filteredSpeakers);
      }

      return filteredOrganizations;
    }, []);
    setValue(inputValue.trim());
    setSpeakers(resultSpeakers);
  };

  return { searchValue, searchedSpeakers, handleSearch };
}

export default useSearchSpeakers;