import { Auth } from 'aws-amplify';
import * as CryptoJS from 'crypto-js';
import { decodeJwt } from 'jose';
import { SSO_ERROR_MSG } from '../../../constants';
import { Secret } from './Secret';
import { AppConfigurator } from '../../../services/app-configuration/AppConfigurator';

type Payload = { 'cognito:username': string };


export class AuthSSOResolver {
  public static retrieveUserNameFromToken(token: string): string {
    const payload = AuthSSOResolver.decodeIdToken(token);

    return payload ? Reflect.get(payload, 'cognito:username') : '';
  }

  public static decodeIdToken(token: string): Payload {
    try {
      return decodeJwt(token) as Payload;
    } catch {
      return { 'cognito:username': '' };
    }
  }

  public static decryptSession(cipherSession: string): { idToken: string; refreshToken: string } {
    try {
      const decodedCipherSession = decodeURIComponent(cipherSession);
      const decryptedSession = CryptoJS.AES.decrypt(decodedCipherSession, Secret.get()).toString(CryptoJS.enc.Utf8);

      return JSON.parse(decryptedSession);
    } catch (e) {
      return { idToken: '', refreshToken: '' };
    }
  }

  private static get storageKeyPrefix(): string {
    return `CognitoIdentityServiceProvider.${AppConfigurator.getUserPoolWebClientId()}`;
  }

  public async resolve(): Promise<void> {
    try {
      this.pluginAuthData();
      await Auth.currentSession();
    } catch {
      throw new Error(SSO_ERROR_MSG.USER_AUTH_FAILURE);
    } finally {
      Secret.clear();
    }
  }

  constructor(private readonly cipherSession: string) {
    const { idToken, refreshToken } = AuthSSOResolver.decryptSession(cipherSession);

    this.idToken = idToken;
    this.refreshToken = refreshToken;
    this.userName = AuthSSOResolver.retrieveUserNameFromToken(idToken);
  }

  private readonly idToken: string;
  private readonly refreshToken: string;
  private readonly userName: string;

  private pluginAuthData(): void {
    if (this.idToken && this.refreshToken && this.userName) {
      this.pluginIdToken();
      this.pluginRefreshToken();
      this.pluginUserName();
    }
  }

  private pluginIdToken(): void {
    window.localStorage.setItem(`${AuthSSOResolver.storageKeyPrefix}.${this.userName}.idToken`, this.idToken);
  }
  private pluginRefreshToken(): void {
    window.localStorage.setItem(`${AuthSSOResolver.storageKeyPrefix}.${this.userName}.refreshToken`, this.refreshToken);
  }
  private pluginUserName(): void {
    window.localStorage.setItem(`${AuthSSOResolver.storageKeyPrefix}.LastAuthUser`, this.userName);
  }

}
