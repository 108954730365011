import { format, utcToZonedTime } from 'date-fns-tz';
import { TIME_FORMATS } from '../lib/constants';

export function useResolveTime (dateTime: string, timeZone: string, formatPattern: TIME_FORMATS): string {
  if (dateTime && timeZone) {
    const utcDate = new Date(dateTime);
    const zonedDate = utcToZonedTime(utcDate, timeZone);

    return format(zonedDate, formatPattern, { timeZone });
  }
  return '';
}