import React, { ReactNode, RefObject, useEffect } from 'react';
import { CurrentUserResponseType } from '../../lib/api';
import { useCurrentUserInfo } from '../../hooks/api/protected/use-current-user-info';
import { KvEmbedContentService } from './KvEmbedContentService';

export type KvEmbedProps = {
  embedId: string | null | undefined;
  className?: string;
  children?: ReactNode;
}
export type KvEmbedShowcaseProps = { pagePath?: string } & KvEmbedProps;

export type KvEmbedShowcaseWrapperProps = { data: CurrentUserResponseType } & KvEmbedShowcaseProps;
export type KvEmbedPresentationWrapperProps = { data: CurrentUserResponseType } & KvEmbedProps;

const _className = 'KnowledgeVisionEmbeddedContent';
const _style = { display: 'none' };
export type EmbedRefType = HTMLDivElement

export const KvEmbedContainer = React.forwardRef<EmbedRefType, KvEmbedProps>((props, ref) => {
  if (!props.embedId) return null;

  return (
    <div
      ref={ref}
      id={`${_className}${props.embedId}`}
      className={`${_className} ${props.className || ''}`}
      style={_style}
      tabIndex={0}
      aria-label='video player container'
    />
  );
});

KvEmbedContainer.defaultProps = {
  className: 'h-full'
};

export const KvShowcaseEmbedWrapper = (props: KvEmbedShowcaseWrapperProps): JSX.Element | null => {
  const ref: RefObject<HTMLDivElement> = React.createRef();

  useEffect(() => {
    if (props.embedId && ref.current && props.data) {
      /* need to copy the ref object here because of KvEmbedContainer re-rendering */
      const _ref = { ...ref };
      return KvEmbedContentService.initShowcaseEmbedContentService(props.embedId, props.pagePath, _ref, props.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.embedId, ref.current, props.pagePath]);

  if (props.data) return <KvEmbedContainer {...props} ref={ref}/>;
  return null;
};

export const KvShowcaseEmbedContainer = (props: KvEmbedShowcaseProps): JSX.Element | null => {
  const { data } = useCurrentUserInfo();
  if (data) return <KvShowcaseEmbedWrapper {...props} data={data} />;
  return null;
};

export const KvPresentationEmbedContainer = (props: KvEmbedShowcaseProps): JSX.Element | null => {
  const { data } = useCurrentUserInfo();
  if (data) return <KvPresentationEmbedWrapper {...props} data={data} />;
  return null;
};

export const KvPresentationEmbedWrapper = (props: KvEmbedPresentationWrapperProps): JSX.Element | null => {
  const ref: RefObject<HTMLDivElement> = React.createRef();

  useEffect(() => {
    if (props.embedId && ref.current && props.data) {
      /* need to copy the ref object here because of KvEmbedContainer re-rendering */
      const _ref = { ...ref };
      return KvEmbedContentService.initPresentationEmbedContentService(props.embedId, _ref, props.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.embedId, ref.current]);

  if (props.data) return <KvEmbedContainer {...props} ref={ref}/>;
  return null;
};

export default KvEmbedContainer;

