import { useEffect } from 'react';
import { useEventPublicInfo } from '../../../../hooks/api/public/use-event-public-details';
import { getUrlQueryArgByKey } from '../../../api/utils';
import { SSO_ERROR_MSG } from '../../../constants';
import { IAuthContext, useAuth } from '../../auth-context';
import { AuthSSOResolver } from './AuthSSOResolver';
import { Secret } from './Secret';
import { AppContextType, useApp } from '../../app-config-context';

const SESSION = 'session';
const SSO_FAILURE = 'sso_failure';

export const useSSOAuthResolver = (): void => {
  const auth = useAuth() as IAuthContext;
  const app = useApp() as AppContextType;
  const { data } = useEventPublicInfo();

  const session = getUrlQueryArgByKey(SESSION);
  const ssoFailure = getUrlQueryArgByKey(SSO_FAILURE);

  const resetBrowserUrl = () => window.history.replaceState({}, document.title, '/');

  useEffect(() => {
    if (data && auth.isAuthenticated === false && !auth.passwordlessAuthInProgress && session && app.isConfigured) {
      const flowResolver = new AuthSSOResolver(session);

      auth.setPasswordlessAuthInProgress();

      flowResolver.resolve()
        .then(auth.setAsAuthenticated)
        .catch((e) => {
          auth.setAsNotAuthenticated();
          resetBrowserUrl();
          auth.setSsoError(e);
        })
        .finally(auth.resetPasswordlessAuthInProgress)
      ;
    }

  }, [data, auth, session, app.isConfigured]);

  useEffect(() => {
    if (data && auth.isAuthenticated === false && !auth.passwordlessAuthInProgress && ssoFailure) {
      Secret.clear();
      const error = new Error(SSO_ERROR_MSG.AUTH_FAILURE);
      auth.setSsoError(error);
    }
  }, [data, auth, ssoFailure]);
};
