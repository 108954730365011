import { useEventSettings } from '../lib/context-providers/event-settings-context/event-settings-context';
import { createTemplateProcessor, TemplateProcessor } from '../lib/templates/template-processor';
import { composeTemplateContext } from '../lib/templates/template-context';

export const useTemplateProcessor = (): TemplateProcessor => {
  const data = useEventSettings();
  const tplContext = composeTemplateContext(data);

  return createTemplateProcessor(tplContext);
};
