const debounce = <T>(func: (...args: T[]) => void, wait: number): (...args: T[]) => void => {
  let timeout: number;

  return (...args: T[]) => {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = window.setTimeout(later, wait);
  };
};

export default debounce;