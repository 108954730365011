import React from 'react';
import { Redirect } from 'react-router-dom';
import { EventSettingsContextType, useEventSettings } from '../../../lib/context-providers/event-settings-context/event-settings-context';
import EventDetails from "../../shared/EventDetails";
import RichTextInfo from "./RichTexPageInfo";
import { RichTextPageType } from '../../../lib/api/custom-page.types';
import isEnabledRoute from '../../../hooks/use-enabled-route';
import { IAuthContext, useAuth } from '../../../lib/context-providers/auth-context';

const RichTextPageWrapper: React.FC<RichTextPageType> = (props: RichTextPageType): JSX.Element | null => {
  const auth = useAuth() as IAuthContext;
  const data = useEventSettings() as EventSettingsContextType;

  if ((auth.isAuthenticated && !props?.enabled) || (auth.isAuthenticated === false && !props?.publicEnabled)) {
    const enabledRoute = isEnabledRoute(data?.landingPage);
    return <Redirect to={enabledRoute} />;
  }

  if (props?.richText && data) return (
    <>
      <EventDetails details={data} />
      <RichTextInfo
        label={props?.label || ''}
        richText={props?.richText || ''}
      />
    </>
  );
  return null;
};

export { RichTextPageWrapper };
