import { ResponseError } from '../../../config/api';
import { useSWR, responseInterface } from '../../../config/overrides/swr';
import { IAuthContext, useAuth } from '../../../lib/context-providers/auth-context';
import API, { PROTECTED, EVENT, LIST, SPEAKER, PUBLIC } from '../../../lib/api';


const { fetchSpeakerList } = API[PROTECTED];
const { fetchPublicSpeakerList } = API[PUBLIC];

export function useSpeakersList(eventId: string): responseInterface<typeof fetchSpeakerList, ResponseError> {
  const auth = useAuth() as IAuthContext;
  const keyFn = () => eventId ? [ EVENT, eventId, SPEAKER, LIST ] : null;

  return useSWR(keyFn, auth?.isAuthenticated ? fetchSpeakerList : fetchPublicSpeakerList);
}
