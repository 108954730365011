import React from 'react';
import { KnovioPageType } from '../../../lib/api/custom-page.types';
import { EmbedContainer } from '../../../lib/kv-embed';


export const KnovioCustomPage: React.FunctionComponent<KnovioPageType> = (props: KnovioPageType): JSX.Element | null => {
  if (props && props.embedCodeId) return <EmbedContainer.Knovio embedId={props.embedCodeId} />;

  return null;
};
