import React, { useCallback, useRef, useState } from 'react';
import { INPUT_DEBOUNCE_TIME } from '../../lib/constants';
import debounce from '../../lib/helpers/debounce';
import { Button } from '../_base/Button';

import './search-input.scss';

interface SearchInputProps {
    withResetSearchButton?: boolean;
    withDebounce?: boolean;
    onChange: (value: string) => void;
    placeholder?: string;
}

const SearchInput = (props: SearchInputProps): JSX.Element => {
  const {
    withResetSearchButton,
    onChange,
    withDebounce,
    placeholder,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const onChangeCallback = (...args: string[]) => withDebounce ? debounce(onChange, INPUT_DEBOUNCE_TIME) : onChange;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(onChangeCallback(), []);

  const withButtonStyles: string = withResetSearchButton ? 'search-input--with-button' : '';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const newValue = e.target.value;
    setInputValue(newValue);
    debouncedChangeHandler(newValue);
  };

  const handleReset = () => {
    setInputValue('');
    debouncedChangeHandler('');
  };

  return (
    <div className='search-wrapper'>
      <input
        aria-label='Search input'
        placeholder={placeholder || 'Search'}
        type='text'
        className={`${withButtonStyles} search-input outline input-outline`}
        value={inputValue}
        onChange={handleChange}
        ref={inputRef}
      />
      { withResetSearchButton && !!inputValue.length &&
        <Button
          name='reset'
          onClick={handleReset}
          className='reset-button'
          ariaLabel='Reset search'
        >
          &#10006;
        </Button>
      }
    </div>
  );
};

export { SearchInput };
