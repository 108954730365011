import * as React from 'react';
import './CheckboxOption.scss';
import Icon from './Icon';

interface IProps {
  isSelected: boolean;
  isDisabled?: boolean;
  onClick: () => void;
}

const CheckboxOption = (props: IProps): JSX.Element => {
  const { isDisabled, isSelected, onClick } = props;

  const handleClick = () => {
    if (!isDisabled) onClick();
  };

  const checkboxClassNames = `checkbox-option 
  ${isSelected ? 'checkbox-option--selected' : ''}
  ${isDisabled ? 'checkbox-option--disabled' : ''}
  `;

  return (
    <div className={checkboxClassNames} onClick={handleClick}>
      <Icon.CheckmarkIcon />
    </div>
  );
};

export default CheckboxOption;
