/* eslint-disable @typescript-eslint/ban-ts-comment */
import { RefObject, useEffect, useLayoutEffect } from 'react';

const useChangeBackgroundSize = (ref: RefObject<HTMLDivElement> | null, nameId: string): void => {
  const changeBackgroundSize = (): void => {
    const id = document.getElementById(nameId) as HTMLDivElement;
    const indentTop = 50;
    const minHeight = 300;
    if (ref && id) {
      const heightLoginWrapper = id.clientHeight ;
      if (heightLoginWrapper > minHeight) {
        // @ts-ignore*/
        ref.current.style.height = `calc(100% - ${heightLoginWrapper}px)`;
        // @ts-ignore*/
        ref.current.style.top = indentTop + heightLoginWrapper + `px`;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', changeBackgroundSize);
    return () => {
      window.removeEventListener('resize', changeBackgroundSize);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useLayoutEffect(() => {
    if (ref?.current) changeBackgroundSize();
  });

};

export default useChangeBackgroundSize;