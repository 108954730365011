import React from 'react';
import Button from '../../../../components/button';
import './GeneralSessions.scss';
import Icon from '../../../../components/_base/Icon';

interface IProps {
  isExpandedView: boolean,
  setExpandedView: (isExpandedView: boolean) => void
}

const ToggleSessionsViewBtn: React.FC<IProps> = (props: IProps): JSX.Element | null => {
  const {
    isExpandedView,
    setExpandedView
  } = props;

  const className = 'editable-session';
  const classNameHeaderButtons = `${className}--header-buttons`;
  const classNameHeaderLeftBtn = `${className}--header-left-btn`;
  const classNameHeaderRightBtn = `${className}--header-right-btn`;

  return (
    <div className={`${classNameHeaderButtons} session-btn`}>
      <Button.Common 
        name='Expand table view'
        className={'outline-visible on-hover-shadow px-2 pb-2 pt-1 rounded ' + classNameHeaderLeftBtn + ' mr-2 fill-primary ' + (isExpandedView ? 'outline outline-mid-gray pointer-events' : 'fill-off-black opacity-60 cursor-pointer')}
        onClick={() => setExpandedView(true)}
        aria-label='Expand table view'
        ariaPressed={isExpandedView ? 'true' : 'false'}
      >    
        <Icon.ExpandIcon width='18px' height='18px' viewBox='0 0 18 18'/>
      </Button.Common>
      <Button.Common 
        name='Collapse table view'
        className={'outline-visible on-hover-shadow px-2 pb-2 pt-1 rounded ' + classNameHeaderRightBtn + ' mr-2 fill-primary ' + (isExpandedView ? 'fill-off-black opacity-60 cursor-pointer' : 'outline outline-mid-gray pointer-events')}
        onClick={() => setExpandedView(false)}
        aria-label='Collapse table view'
        ariaPressed={isExpandedView ? 'false' : 'true'}
      >
        <Icon.CollapseIcon width='18px' height='18px' viewBox='0 0 18 18'/>
      </Button.Common>
    </div>
  );
};

export default ToggleSessionsViewBtn;
