import * as CryptoJS from "crypto-js";

export class Secret {
  private static readonly secretKey: string = '__secret';

  public static get(): string {
    if (!this.hasValue()) this.create();

    return localStorage.getItem(this.secretKey) as string;
  }

  public static clear(): void {
    localStorage.removeItem(this.secretKey);
  }

  private static create(): void {
    const bytes = 8;
    const secret = CryptoJS.lib.WordArray.random(bytes).toString();
    localStorage.setItem(this.secretKey, secret);
  }

  private static hasValue(): boolean {
    return !!localStorage.getItem(this.secretKey);
  }
}
