import { DateFnsFormats, dateToFormattedString, getFullDateInUsEuFormat, getIsEnUsRegion } from "./dateHelper";

export const getGroupedMeetingsList = <T extends { startDateTime: string }>
  (meetings: T[], isCarouselViewByDay: boolean, timeZone: string): { [date: string]: T[] } => {
  
  const groupedMeetingsList: { [date: string]: T[] } = {};
  meetings.forEach((meeting: T) => {
    const startDate = isCarouselViewByDay
      ? getFullDateInUsEuFormat(meeting.startDateTime, timeZone)
      : dateToFormattedString(meeting.startDateTime, 
        getIsEnUsRegion() ? DateFnsFormats.StringifiedUSShortDay : DateFnsFormats.StringifiedEUShortDay,
        timeZone);
    if (groupedMeetingsList[startDate]) {
      groupedMeetingsList[startDate].push(meeting);
    } else {
      groupedMeetingsList[startDate] = [meeting];
    }
  });
  return groupedMeetingsList;
};

