import { ResponseError } from '../../../config/api';
import { useSWR, responseInterface } from '../../../config/overrides/swr';

import API, { PUBLIC } from '../../../lib/api';
import { getCurrentPathName } from '../../../lib/api/utils';

const { fetchFileDownloadUrlByPublicIdentifier } = API[PUBLIC];

export function useAssetDownloadUrl(): responseInterface<{ signedUrl: string }, ResponseError> {
  const publicIdentifier = getCurrentPathName();
  const keyFn = () => publicIdentifier ? publicIdentifier : null;

  return useSWR(keyFn, fetchFileDownloadUrlByPublicIdentifier);
}
