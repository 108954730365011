import React from 'react';
import Link from '../../../components/_base/Link';
import { LinkType } from '../../../lib/api';
import './MeetingDetailsLinks.scss';

interface LinkProps {
  links: LinkType[];
  className?: string;
  dataTestId?: string;
}

const MeetingDetailsLinksMapper = (props: LinkProps): JSX.Element => {
  const { links = [], className = '', dataTestId= '' } = props;

  return (
    <div className={`meeting-details-links__mapper ${className}`} data-test-id={dataTestId}>
      {links.map((link, index) => {
        return (
          <div key={index} title={link.label}>
            <Link
              label={link.label}
              url={link.url}
            />
          </div>
        );
      })}
    </div>
  );

};

export default MeetingDetailsLinksMapper;