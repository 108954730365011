import { Secret } from './Secret';
import { AppConfigurator } from '../../../services/app-configuration/AppConfigurator';

export class AuthSSOInitiator {
  public async initiate(): Promise<void> {
    try {
      const ssoAppUrl = AppConfigurator.getSSOAppUrl();

      const customProviderQueryArg = `customProvider=${this.idpProvider}`;
      const customStateQueryArg = `customState=${this.getCustomState()}`;
      const redirectUrl = `${ssoAppUrl}/?${customProviderQueryArg}&${customStateQueryArg}`;

      window.location.replace(redirectUrl);
    } catch (e) {
      throw new Error('Failed to initiate SSO flow: ' + e.message);
    }
  }

  constructor(private readonly idpProvider: string, private readonly subdomain: string) {}

  private getCustomState(): string {
    const subdomain = this.subdomain;
    const secret = Secret.get();

    return window.btoa(JSON.stringify({ subdomain, secret }));
  }
}
