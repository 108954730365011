import React, { RefObject, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useConnectMeetingDisabled from '../../../../hooks/use-connect-meeting-disabled';
import API, { PrivateMeetingSessionDetailsResponseType, PROTECTED } from '../../../../lib/api';
import { JOIN_STATUS } from '../../../../lib/constants';
import { EventType } from '../LayoutWrapper';
import { Button, ButtonProps } from '../../../../components/_base/Button';
import Icon from '../../../../components/_base/Icon';
import Buttons from '../../../../components/button';
import useMeetingCalendar from '../../../../hooks/use-meeting-calendar';
import { getAddRemoveBtnClassName, getJoinBtnClassName, showJoinButton } from '../helpers';
import useEventToken from '../../../../hooks/use-event-token';
import { MediaQueryContextType, useMediaQuery } from '../../../../lib/context-providers/media-query-provider';
import { PassportPageType, ReplaysPageType } from '../../../../lib/api/custom-page.types';

export interface IFooterProps {
  meeting: PrivateMeetingSessionDetailsResponseType;
  event: EventType;
  isExpandedView?: boolean;
  featured?: boolean;
  isCarousel?: boolean;
  withTooltip?: boolean;
  isSessionsTab?: boolean;
  isRegisterBtnShownDown?: boolean;
  isClosestMeeting?: boolean;
}

const FooterButtons = (props: IFooterProps): JSX.Element => {
  const {
    meeting,
    event,
    isExpandedView,
    isCarousel,
    withTooltip,
    featured = meeting.featured,
    isSessionsTab,
    isClosestMeeting = false
  } = props;

  const {
    access,
    meetingId,
    joinStatus,
    startDateTime,
  } = meeting;

  const changeJoinStatus = (newJoinStatus: string) => (meeting.joinStatus = newJoinStatus);

  const history = useHistory();
  const [status, setJoinStatus] = useState(joinStatus);
  const { attendMeeting, leaveMeeting } = API[PROTECTED];

  const isConnectBtnDisabled = useConnectMeetingDisabled(event, startDateTime);
  const isAddBtnShown = access === 'OPEN';

  const { fetchOutlookCalendarForMeeting, fetchGoogleCalendarForMeeting } = useMeetingCalendar();
  const eventToken = useEventToken(event.eventId);

  const eventIdString = event.eventId.toString();
  const eventName = event.name || '';

  const { isMobile } = useMediaQuery() as MediaQueryContextType;

  const isRegisterBtnShownDown = props.isRegisterBtnShownDown ?? (!featured || isMobile);

  const className = 'editable-session';
  const classNameSessionTableCellButtons = `${className}--table-cell-buttons`;
  const classNameSessionTableCellBtnAdd = `${className}--table-cell-btn-add`;
  const classNameSessionTableCellBtnJoin = `${className}--table-cell-btn-join`;

  const toggleGeneralSession = () => {
    const oldJoinStatus = status;
    let newJoinStatus = JOIN_STATUS.JOINED_AS_REGISTRANT;

    switch (oldJoinStatus) {
    case JOIN_STATUS.NOT_JOINED:
      attendMeeting(event.eventId.toString(), meetingId)
        .then(() => changeJoinStatus(newJoinStatus))
        .catch(() => setJoinStatus(oldJoinStatus));
      setJoinStatus(newJoinStatus);
      return;
    case JOIN_STATUS.JOINED_AS_REGISTRANT:
      newJoinStatus = JOIN_STATUS.NOT_JOINED;
      leaveMeeting(event.eventId.toString(), meetingId)
        .then(() => changeJoinStatus(newJoinStatus))
        .catch(() => setJoinStatus(oldJoinStatus));
      setJoinStatus(newJoinStatus);
      return;
    default: return;
    }
  };

  const getAddRemoveBtnParams = (): ButtonProps => {
    let labelClassName = 'flex justify-center items-center truncate w-full';

    if (isCarousel) {
      if (!featured || (featured && isMobile)) {
        labelClassName += ' w-2/3';
      }
    } else {
      labelClassName += ' line-height-20px';
    }

    const labelText = `Register`;
    const style = isCarousel ? { lineHeight: '20px' } : {};

    const addLabel =
      <div className={labelClassName} style={style}>
        <Icon.Add className='mr-1 align-top' viewBox='0 0 24 24' />
        {labelText}
      </div>;
    const addedLabel =
      <span className={labelClassName + ' text-off-black'} style={style}>
        <Icon.Added className='mr-1 fill-off-black align-top' viewBox='0 0 24 24' />Registered
      </span>;
    const removeLabel =
      <span className={labelClassName + ' text-white'} style={style}>
        <Icon.Remove className='mr-1 fill-white align-top' viewBox='1 1 18 18' height='14' width='14' />
        <span className='cancel-button'>Cancel</span> 
      </span>;

    const addRemoveBtn = status === JOIN_STATUS.NOT_JOINED
      ? addLabel
      : status === JOIN_STATUS.JOINED_AS_REGULAR
        ? addedLabel
        : <>{addedLabel}{removeLabel}</>;

    const addRemoveBtnAriaLabel = status === JOIN_STATUS.NOT_JOINED
      ? `Register`
      : status === JOIN_STATUS.JOINED_AS_REGULAR
        ? `Registered`
        : `Cancel`;

    const addRemoveBtnParams: ButtonProps = {
      name: 'meetingItem',
      type: 'button',
      onClick: toggleGeneralSession,
      label: addRemoveBtn,
      className: handleGetAddRemoveBtnClassNames(),
      'data-meeting-id': meetingId,
      ariaLabel: addRemoveBtnAriaLabel
    };

    if (withTooltip && status === JOIN_STATUS.NOT_JOINED) {
      addRemoveBtnParams['data-tooltip'] = `Register`;
      addRemoveBtnParams['data-tooltip-position'] = 'bottom';
    }
    return addRemoveBtnParams;
  };

  const handleGetJoinBtnClassNames = () => getJoinBtnClassName(
    classNameSessionTableCellBtnJoin,
    !!isMobile,
    featured,
    isConnectBtnDisabled,
    isExpandedView,
    access === 'PLENARY',
    (!isSessionsTab && !isClosestMeeting),
    isSessionsTab
  );

  const handleGetAddRemoveBtnClassNames = () => getAddRemoveBtnClassName(
    classNameSessionTableCellBtnAdd,
    !!isMobile,
    status ?? '',
    featured,
    isExpandedView
  );

  const AddToCalendarButton = (props: { isFeatured?: boolean }) => {
    const { isFeatured = false } = props;
    const [customStyles, setCustomStyles] = useState<React.CSSProperties>({});

    const onShowDropDown = (buttonRef: RefObject<HTMLDivElement>) => {
      const buttonLeftPosition = buttonRef.current?.getBoundingClientRect().x || 0;

      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      if (buttonLeftPosition > document.body.clientWidth / 2) {
        setCustomStyles({ left: '-214px' });
      } else {
        setCustomStyles({});
      }
    };

    return access === 'PLENARY' || status !== JOIN_STATUS.NOT_JOINED
      ? (
        <Buttons.OptionsButton
          customStyles={!isFeatured ? customStyles : {}}
          onShowDropDown={onShowDropDown}
          label={'+ Add to Calendar'}
          name={'addToCalendar'}
          disabled={false}
          eventId={eventIdString}
          eventName={eventName}
          className='select-box--button-small'
          downloadIcsFile={
            () => fetchOutlookCalendarForMeeting(
              eventIdString,
              meetingId,
              `${event.eventTitleForPassport || eventName}.ics`,
              eventToken
            )
          }
          openGoogleCalendar={() => fetchGoogleCalendarForMeeting(eventIdString, meetingId, eventToken)}
          iconButton
        />
      )
      : null
    ;
  };

  const JoinButton = ({ preRegistrationEnabled }: { preRegistrationEnabled: boolean }) => showJoinButton(access, preRegistrationEnabled, status)
    ? (
      <Button
        name='meetingItem'
        data-meeting-id={meetingId}
        type='button'
        onClick={() => history.push(`/session/${event.eventId}/meeting/${meetingId}`, history.location.pathname)}
        label={isConnectBtnDisabled ? 'View Details' : 'Join'}
        className={`${handleGetJoinBtnClassNames()}${isCarousel ? ' py-2' : ''}`}
        ariaLabel={isConnectBtnDisabled ? 'View Details' : 'Join Meeting'}
        data-test-id='join-button'
      />
    )
    : null;

  const AddButton = () => isAddBtnShown
    ? (
      <Button {...getAddRemoveBtnParams()} />
    )
    : isCarousel
      ? <div className={`${!featured ? 'bg-cool-gray' : ''}`} style={{ height: '34px', border: 'none' }} />
      : null
  ;

  let preRegistrationEnabled = event.preRegistrationEnabled;
  if (!isSessionsTab) {
    const pageConfiguration = event.customPages.find(el => el.type === PassportPageType.REPLAYS) as ReplaysPageType;
    preRegistrationEnabled = pageConfiguration.replaysPreRegistrationEnabled ;
  }

  return (
    <div className={`flex ${classNameSessionTableCellButtons} ${isRegisterBtnShownDown ? 'flex-col-reverse' : ''}`}>
      <AddButton />
      <div className='session-meeting__content flex items-center'>
        <JoinButton preRegistrationEnabled={preRegistrationEnabled}/>
        <AddToCalendarButton isFeatured={featured} />
      </div>
    </div>
  );
};

export { FooterButtons };