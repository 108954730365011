import { ResponseError } from '../../../config/api';
import { useSWR, responseInterface } from '../../../config/overrides/swr';
import API, { PresenterParticipantDetailsResponseType, PUBLIC } from '../../../lib/api';

const { fetchPublicPresenterDetails } = API[PUBLIC];

export function usePresenterDetails(): responseInterface<PresenterParticipantDetailsResponseType, ResponseError> {
  const sliceNum = 2;
  const path = window.location.pathname.split('/').slice(sliceNum) ?? [];
  const keyFn = () => path.length ? path : null;
  return useSWR(keyFn, fetchPublicPresenterDetails);
}