import { PhoneNumber } from '../api/connection.types';

export function truncateString(str: string, num: number): string {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

export function getPathName(path: string): string {
  return path.replace("/", "");
}

export function parsePhoneNumbers(numbers: string | null, participantOneTapDial: string | null): PhoneNumber[] {
  const numbersArray = numbers?.trim().split("\n");
  const parsedNumbersArray: PhoneNumber[] = numbersArray?.map(item => ({
    priority: 0,
    country: '',
    countryCode: '',
    telephoneNumber: item,
    unformattedTelephoneNumber: '',
    toll: '',
    oneTapFormula: '',
  })) || [];

  if (numbersArray && participantOneTapDial) {
    parsedNumbersArray.unshift({
      priority: 0,
      country: '',
      countryCode: '',
      telephoneNumber: participantOneTapDial,
      unformattedTelephoneNumber: '',
      toll: '',
      oneTapFormula: '',
    });
    return parsedNumbersArray;
  } else if (numbersArray) {
    return parsedNumbersArray;
  }
  return [];
}

export function getUnformattedPhoneNumber(phoneNumber: string): string {
  // Return phone number without non digital symbols
  return phoneNumber.replace(/\D/g, '');
}
