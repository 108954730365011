import React from 'react';
import useConnectToPrivateMeetingMethod from '../../../hooks/use-connect-to-private-meeting-method';
import { PrivateMeetingDetailsResponseType } from '../../../lib/api';
import { EventSettingsContextType, useEventSettings } from '../../../lib/context-providers/event-settings-context/event-settings-context';
import JoinButton from '../../shared/JoinButton';
import ShowDialInInfoButton from '../../../components/_base/ShowDialInInfoButton/ShowDialInInfoButton';
import { JoinMethod } from '../../../lib/join-method/join-method';
import DialInInfo from '../../shared/DialInInfo/DialInInfo';

export const SHOWCASE_PAGE_PATH_CONNECTION_INVALID_TEXT = 'No connection to the meeting is available, please contact the event administrator';
export const ZOOM_REGISTRATION_IN_PROGRESS_TEXT = 'One moment while we get everything ready for you to connect';

export type ConnectToMeetingProps = {
  isConnectBtnDisabled: boolean,
  joinMethod: JoinMethod | null,
  isMultipleConnection: boolean,
  joinUrl: string | null,
  showDialInInfo: boolean, 
  toggleDialInInfo: () => void 
} & PrivateMeetingDetailsResponseType;

const ConnectToMeeting: React.FunctionComponent<ConnectToMeetingProps> = (props: ConnectToMeetingProps): JSX.Element => {
  const eventSettings = useEventSettings() as EventSettingsContextType;
  const {
    isConnectBtnDisabled,
    joinMethod,
    isMultipleConnection,
    joinUrl,
    showDialInInfo,
    toggleDialInInfo,
    ...meetingDetails
  } = props;

  const renderAudioBridgeComponent = (joinMethod: JoinMethod) => {
    if (isMultipleConnection) {
      return (
        <ShowDialInInfoButton 
          joinMethod={joinMethod} 
          showDialInInfo={showDialInInfo} 
          toggleDialInInfo={toggleDialInInfo}
        />
      );
    }
    return <DialInInfo joinMethod={joinMethod}/>;
  };

  /* Don't show ConnectToMeeting Button when connection is In-Person or Showcase with invalid configuration */

  /* ShowcasePagePath Connection Type with invalid configuration */
  if (joinMethod && joinMethod.isShowcaseConnectionType() && !eventSettings.showcaseEnabled) return (
    <div className='pt-4 whitespace-pre-wrap leading-loose'>{SHOWCASE_PAGE_PATH_CONNECTION_INVALID_TEXT}</div>
  );

  /* In-Person Connection Type  */
  if (joinMethod && joinMethod.isInPersonConnectionType()) return (
    <div className='pt-4 text-sm whitespace-pre-wrap break-words' dangerouslySetInnerHTML={{ __html: joinMethod.meetingLocation }}/>
  );

  /* Audio Bridge Connection Type  */
  if (joinMethod && (joinMethod.isAudioBridgeType() || joinMethod.isUniqueDialInType())) {
    return renderAudioBridgeComponent(joinMethod);
  }

  return (
    <>
      <JoinButton
        joinUrl={joinUrl}
        isDisabled={isConnectBtnDisabled}
        joinMethod={joinMethod}
        meetingDetails={meetingDetails}
        useConnectToMeetingMethod={useConnectToPrivateMeetingMethod}
        className='connect-to-meeting-btn rounded bg-primary text-white on-hover-shadow'
        label={joinMethod?.connectButtonLabel}
      />
    </>
  );
};

export default ConnectToMeeting;
