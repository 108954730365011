import { ResponseError } from '../../../config/api';
import { useSWR, responseInterface } from '../../../config/overrides/swr';
import API, { EventPublicDetailsResponseType, PUBLIC } from '../../../lib/api';
import { ERROR_SUBDOMAIN } from '../../../lib/constants';
import useEventSubdomain from '../../use-event-subdomain';

const { fetchPublicEventDetailsBySubdomain } = API[PUBLIC];
const fetchEmptyObject = () => { return undefined;};

/* bypassing api calls from MeetLinks and FileDownload to Passport API */
export function useEventPublicInfo(bypassApiCall = false): responseInterface<EventPublicDetailsResponseType, ResponseError> {
  const subdomain = useEventSubdomain();
  const keyFn = () => bypassApiCall ? null : subdomain;

  const swrResponse = useSWR(keyFn, subdomain === ERROR_SUBDOMAIN ? fetchEmptyObject : fetchPublicEventDetailsBySubdomain);

  return swrResponse;
}