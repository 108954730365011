import React from 'react';
import Icon from './Icon';
import './Link.scss';

export type LinkProps = {
  url: string;
  label?: string;
  className?: string;
};

export const Link: React.FunctionComponent<LinkProps> = (props: LinkProps): JSX.Element => {
  const { label, url, className = '' } = props;

  return (
    <a className={`link ${className}`} href={url} target='_blank' rel='noopener noreferrer'>
      <div className='link__icon' > 
        <Icon.LinkIcon />
      </div>
      <span className='text-ellipsis' >{label || url}</span>
    </a>
  );
};

export default Link;
