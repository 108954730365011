import React, { useState } from 'react';
import { SpeakerListResponseType } from '../../../lib/api';
import AttendeeWithDetails from '../AttendeeWithDetails/AttendeeWithDetails';
import './OrganizationListWithDetails.scss';

interface IProps {
  organizations: SpeakerListResponseType;
  isMeetLink?: boolean;
}

const OrganizationsListWithDetails: React.FC<IProps> = (props: IProps) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className='pt-2 sm:pl-8 organization-list-with-details'>
      <hr/>
      <button aria-label={'toggle speaker details'} className={`toggle${expanded ? ' expanded' : ''}${!props.isMeetLink ? ' pl-8' : ''}`} onClick={toggleExpanded}>
        <span className='font-size-13px font-semibold'>Speaker Details</span>
        <span className='font-size-24px line-height-18px'>&#8250;</span>
      </button>
      {expanded && props.organizations.map(org => {
        return (
          <div className={`px-0${!props.isMeetLink ? ' pl-8' : ''}`} key={org.organizationId}>
            <h2 className='font-size-30px pb-4'>{org.name}</h2>
            {org.speakers?.map((att) =>
              <AttendeeWithDetails
                organizationName={org.name}
                attendee={att}
                key={att.attendeeId}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default OrganizationsListWithDetails;
