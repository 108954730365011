/*eslint-disable @typescript-eslint/no-var-requires*/
import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Switch, useLocation } from 'react-router-dom';
import { LayoutConfig } from '../config';
import { RoutesConfig } from '../config/routes';
import Layout from '../components/_base/Layout';
import { useEventPublicInfo } from '../hooks/api/public/use-event-public-details';
import { REGISTRATION_PATH, ROOT } from '../config/routes/paths';
import { useRoutes } from '../hooks/use-custom-routes';
import { IAuthContext, useAuth } from '../lib/context-providers/auth-context';
import { getCurrentPathName, getUrlQueryArgByKey } from '../lib/api/utils';
import { BYPASS_TOKEN_KEY, ERROR_SUBDOMAIN, IDP_IDENTIFIER, ML_ACCESS_TOKEN } from '../lib/constants';
import { AppConfigurator } from '../lib/services/app-configuration/AppConfigurator';
import useDefaultRoutePage from "../hooks/use-default-route-page";
import useEventSubdomain from '../hooks/use-event-subdomain';
import { ErrorPage } from './passport/ErrorPage';
import { EventSettingsContextType, useEventSettings } from '../lib/context-providers/event-settings-context/event-settings-context';

const { Route } = (() => {
  if (AppConfigurator.isPassportProfile) return require('../config/overrides/route');
  if (AppConfigurator.isMeetLinksProfile) return require('react-router-dom');
  if (AppConfigurator.isFileDownloadProfile) return require('react-router-dom');
})();

type Props = {
  routes: RoutesConfig
}

const RegistrationFormRoute = () => {
  const { data } = useEventPublicInfo();
  const auth = useAuth() as IAuthContext;
  const token = getUrlQueryArgByKey(ML_ACCESS_TOKEN) || '';
  const idpIdentifier = getUrlQueryArgByKey(IDP_IDENTIFIER) || '';
  const pathname = getCurrentPathName();
  const appSyncToken = getUrlQueryArgByKey(BYPASS_TOKEN_KEY) || '';
  const isRedirectedToRegistration = AppConfigurator.isPassportProfile && auth?.isAuthenticated === false && !token && !idpIdentifier && !pathname && data && data.registrationAsLandingPage && !appSyncToken;
  const subdomain = useEventSubdomain() || '';
  const isRedirectedToErrorPage = AppConfigurator.isPassportProfile && subdomain === ERROR_SUBDOMAIN;

  if (isRedirectedToErrorPage) {
    return <ErrorPage/>;
  }
  if (auth?.ssoError) {
    return <Redirect to={ROOT}/>;
  }
  if (isRedirectedToRegistration) {
    return <Redirect to={REGISTRATION_PATH} />;
  }
  return null;
};

const ScrollToTop = (): null => {
  const { pathname } = useLocation();

  useEffect(() => {
    const element = document.getElementsByClassName('router-wrapper')?.[0] as HTMLDivElement | undefined;
    if (element) {
      element.scrollTo({
        top: 0,
        behavior: 'instant' as ScrollBehavior,
      });
    }
  }, [pathname]);

  return null;
};

const RedirectToLandingPage = () => {
  const landingPage = useDefaultRoutePage();
  const location = useLocation();

  return (
    <Redirect
      to={{
        pathname: landingPage,
        state: { from: location.pathname },
        search: location.search
      }}
    />
  );
};

const PassportAppRouter: React.FC<Props> = (): JSX.Element => {
  const routes: RoutesConfig = { ...useRoutes() };
  const eventSettings = useEventSettings() as EventSettingsContextType;

  return (
    <BrowserRouter>
      <Layout {...LayoutConfig} routesConfig={routes} customPages={eventSettings?.customPages}>
        <ScrollToTop/>
        <RegistrationFormRoute/>
        { !!Object.keys(routes).length && (
          <Switch>
            {Object.keys(routes).map(path => <Route key={path} {...{ path, ...routes[path] }} />)}
            <Route key='no-match'>
              <RedirectToLandingPage />
            </Route>
          </Switch>
        )}
      </Layout>
    </BrowserRouter>
  );
};

/* used for meet links and file download apps (without Cognito authentication) */
const PublicAppRouter: React.FC<Props> = ({ routes }: Props): JSX.Element => {
  return (
    <BrowserRouter>
      <Layout {...LayoutConfig} routesConfig={routes}>
        <Switch>
          {Object.keys(routes).map(path => <Route key={path} {...{ path, ...routes[path] }} />)}
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default AppConfigurator.isPassportProfile ? PassportAppRouter : PublicAppRouter;
