import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/button';
import useConnectToPrivateMeetingMethod, { ConnectionPrivateDetailsType } from '../../hooks/use-connect-to-private-meeting-method';
import { ConnectionPublicDetailsType } from '../../hooks/use-connect-to-public-meeting-method';
import { MeetingDetailsType, PrivateMeetingDetailsResponseType, PublicMeetingDetailsResponseType } from '../../lib/api';
import { Connection } from '../../lib/connection/connection';
import { JoinMethod } from '../../lib/join-method/join-method';

export type WatchButtonProps = {
  isDisabled: boolean;
  meetingDetails: PrivateMeetingDetailsResponseType | PublicMeetingDetailsResponseType | void;
  className?: string;
  joinUrl?: string | null;
}

export const WatchButton: React.FC<WatchButtonProps> = (props: WatchButtonProps): JSX.Element | null => {
  type ConnectionDetailsType = ConnectionPrivateDetailsType & ConnectionPublicDetailsType;

  const { isDisabled, meetingDetails, className, joinUrl } = props;
  const history = useHistory();

  const connectDetails = isDisabled ? undefined : { ...props.meetingDetails, joinUrl };
  const connection = Connection.create(meetingDetails as MeetingDetailsType);

  const isMultipleConnection = connection?.isMultipleConnection();
  const joinMethod = connection?.getJoinMethod();

  const isKnovioConnectionPublish = joinMethod?.isKnovioConnectionType() ? joinMethod.isKnovioAvailable : true;

  const connectToMeeting = useConnectToPrivateMeetingMethod(connectDetails as ConnectionDetailsType, joinMethod as JoinMethod);

  if (!meetingDetails) return null;

  const handleClick = () => {
    if (isMultipleConnection) {
      history.push(`/session/${meetingDetails.eventId}/meeting/${meetingDetails.meetingId}`, history.location.pathname);
    } else {
      connectToMeeting();
    }
  };

  const shouldDisableBtn = isDisabled || !isKnovioConnectionPublish || (!connection && !joinUrl);

  return (
    <div className='flex items-center'>
      <Button.Common
        name='watch'
        disabled={shouldDisableBtn}
        onClick={handleClick}
        label='Watch'
        data-meeting-id={meetingDetails.meetingId}
        className={className}
      />
      {
        shouldDisableBtn && <div className='coming-soon'>Coming soon</div>
      }
    </div>
  );
};
