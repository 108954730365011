import { useState } from 'react';
export enum AuthErrorTypes {
  UserNotFoundException = 'UserNotFoundException'
}

export type AuthError = {
  status?: number,
  message?: string,
  __type?: AuthErrorTypes,
  ssoError?: boolean
} | null

export type UseAuthError = {
  error: AuthError,
  setError(e: AuthError): void
}

export const useAuthError = (): UseAuthError => {
  const [ error, setError ] = useState<AuthError>(null);

  return { error, setError };
};
