import React, { ReactNode } from 'react';
import './Header.scss';
import { OE_LOGO_IMG_PATH, OE_LOGO_ALT_TEXT } from '../../lib/constants';

export interface IHeaderLogoData {
  showWebsiteLogoImage: boolean | undefined,
  websiteLogoUrl?: string,
  websiteLogoTitle?: string,
  shouldRenderLogo: boolean,
}

type Props = {
  children?: ReactNode,
  className?: string,
  headerLogoData: IHeaderLogoData,
  customStyle: React.CSSProperties;
}

const _className = 'w-full h-12 border-black bg-cool-gray px-5 py-2 sticky z-2';

const Header: React.FunctionComponent<Props> = (props: Props): JSX.Element => {

  const { children, headerLogoData, customStyle } = props;

  return (
    <header className={`${_className} ${props.className || ''}`} style={customStyle}>
      {headerLogoData.shouldRenderLogo &&
        <img
          src={headerLogoData.showWebsiteLogoImage ? OE_LOGO_IMG_PATH : headerLogoData.websiteLogoUrl}
          alt={headerLogoData.showWebsiteLogoImage ? OE_LOGO_ALT_TEXT : headerLogoData.websiteLogoTitle}
          title={headerLogoData.showWebsiteLogoImage ? OE_LOGO_ALT_TEXT : headerLogoData.websiteLogoTitle}
          role='presentation'
          tabIndex={0}
          className={`${headerLogoData.showWebsiteLogoImage ? 'oe-header-logo-image' : ''}`}
        />
      }
      {children}
    </header>
  );
};

export default Header;
