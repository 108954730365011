import { RefObject } from 'react';
import API, { ATTENDEE, INFO, PROTECTED, CurrentUserResponseType } from '../api';
import { KvEmbedResourceService } from './KvEmbedResourceService';
import { KvEmbedTrackingService, TrackingParamsType } from './KvEmbedTrackingService';
import TrackingParamsMapper from './KvEmbedTrackingDataMapper';

export class KvEmbedContentService {
  static initShowcaseEmbedContentService(embedId: string, pagePath = '', embedRef: RefObject<HTMLDivElement>, trackingData: CurrentUserResponseType): () => void {
    const sourceInstance = KvEmbedResourceService.initEmbedShowcase(embedId, pagePath);
    new KvEmbedContentService(sourceInstance, embedRef, trackingData);

    return sourceInstance.unloadSource;
  }
  static initPresentationEmbedContentService(embedId: string, embedRef: RefObject<HTMLDivElement>, trackingData?: CurrentUserResponseType): () => void {
    const sourceInstance = KvEmbedResourceService.initEmbedPresentation(embedId);
    new KvEmbedContentService(sourceInstance, embedRef, trackingData);

    return sourceInstance.unloadSource;
  }

  private readonly resource: KvEmbedResourceService;

  private subscription = (): Promise<void> => this.getTrackingData()
    .then(TrackingParamsMapper.mapToParams)
    .then(this.trackKvData)
    ;

  private async getTrackingData(): Promise<CurrentUserResponseType> {
    if (this.trackingData) return this.trackingData;
    return API[PROTECTED].fetchCurrentUserInfo(ATTENDEE, INFO);
  }

  private trackKvData = (data: TrackingParamsType): void => {
    if (this.resource.isSourceLoaded() && this.embedRef.current) {
      KvEmbedTrackingService.trackData(data);
    }
  };

  protected constructor(
    resource: KvEmbedResourceService,
    private readonly embedRef: RefObject<HTMLDivElement>,
    private readonly trackingData?: CurrentUserResponseType
  ) {
    this.resource = resource;
    // register kv-data tracking function
    this.resource.addSubscription(this.subscription);
    // load embed resource after embed-container rendered
    if (this.embedRef.current) this.resource.loadSource();
  }
}
