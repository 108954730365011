import React from 'react';
import { PrivateMeetingSessionDetailsResponseType } from '../../../lib/api';
import { IAuthContext, useAuth } from '../../../lib/context-providers/auth-context';
import { MoreButton } from '../../shared/MoreButton';
import { WatchButton } from '../../shared/WatchButton';
import { EventType } from '../SessionWrapper/LayoutWrapper';
import CardButtons from './CardButtons/CardButtons';
import { PassportPageType, ReplaysPageType } from '../../../lib/api/custom-page.types';

interface IProps {
  toggleVisible: boolean;
  contentExpanded: boolean;
  setContentExpanded: () => void;
  isUpcomingSession: boolean;
  startDateTime: string;
  meeting: PrivateMeetingSessionDetailsResponseType;
  isConnectBtnDisabled: boolean;
  event: EventType;
  isClosestMeeting: boolean;
}

const ReplaysButtons = (props: IProps): JSX.Element => {
  const auth = useAuth() as IAuthContext;
  const isAuthenticated = !!auth.isAuthenticated;

  const {
    toggleVisible,
    contentExpanded,
    setContentExpanded,
    isUpcomingSession,
    meeting,
    isConnectBtnDisabled,
    event,
    isClosestMeeting
  } = props;

  const pageConfiguration = event?.customPages.find(el => el.type === PassportPageType.REPLAYS) as ReplaysPageType;
  const preRegistrationEnabled = pageConfiguration?.replaysPreRegistrationEnabled ;

  return (
    <div className='replay-card-buttons-wrapper'>
      {toggleVisible &&
        <MoreButton
          action={setContentExpanded}
          isContentExpanded={contentExpanded}
          ariaLabel={'toggle content'}
          className={isUpcomingSession ? 'upcoming' : ''}
        />
      }
      {isUpcomingSession ?
        isAuthenticated && <div>
          <CardButtons
            event={event}
            meeting={meeting}
            preRegistrationEnabled={preRegistrationEnabled}
            isFeatured={isClosestMeeting}
          />
        </div>
        :
        <WatchButton
          isDisabled={isConnectBtnDisabled}
          meetingDetails={meeting}
          className='featured-card__watch-btn'
        />
      }
    </div>
  );
};

export default ReplaysButtons;
