import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BACKGROUND_IMG_URL, ERROR_MESSAGE } from '../../lib/constants';
import { fetchAttendeeOneWayRegistrationError } from '../../lib/api/public';
import { decodeJwt } from 'jose';
import DefaultSpinner from '../shared/DefaultSpinner';

type JwtPayload = {
  registrationId: string;
};
export const ErrorPage: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token') || '';
  const redirectId = window.location.pathname.split('/')[1];
  const [displayError, setDisplayError] = useState(ERROR_MESSAGE);
  const [loading, setLoading] = useState(true);
  const [error, setErrorMessage] = useState<string | null>(null);

  const fetchErrorMessage = async (attendeeId: string, token: string): Promise<void> => {
    try {
      const decoded: JwtPayload = decodeJwt(token) as JwtPayload;
      const registrationId = decoded.registrationId;
      const response = await fetchAttendeeOneWayRegistrationError(registrationId, attendeeId, token);
      setDisplayError(response.displayErrorMessage);
    } catch (error) {
      setErrorMessage('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!token && !redirectId) {
      setLoading(false);
      return;
    }

    fetchErrorMessage(redirectId, token);
  }, [loading, redirectId, token]);


  return (
    <div className="flex flex-col items-center justify-center h-64 mt-16">
      <div className="flex mt-40 text-red-500 font-medium">
        {loading ? <DefaultSpinner visible={loading} /> : error ? error : displayError}
      </div>
      <img className="background-img" src={BACKGROUND_IMG_URL} alt='' />
    </div>
  );
};