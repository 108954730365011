
import { COMPARE_INDEX, JOIN_STATUS } from '../../../lib/constants';
import {
  PrivateMeetingSessionDetailsListResponseType,
  PrivateMeetingSessionDetailsResponseType } from '../../../lib/api';
import { PrivateMeetingSessionList } from './GridLayout/index';

export interface IMeetingsByStartTime {
  meetingsList: PrivateMeetingSessionList;
  startTime: string;
  endDateTime: Date;
}

const getActiveTracks = (activeMeetings: PrivateMeetingSessionDetailsListResponseType): string[] => {
  return [...new Set(activeMeetings.map(m => m.track))].sort((t1, t2) =>
    !t1 ? COMPARE_INDEX : !t2 ? -COMPARE_INDEX : t1.localeCompare(t2)
  );
};

const int = 1;

const truncate = (str: string, n: number): string => {
  return str?.length > n ? str.substr(0, n - int) + "..." : str;
};

const getMeetingsByStartTime = (meetings: PrivateMeetingSessionDetailsResponseType[]): IMeetingsByStartTime[] => {
  const meetingsBySelectedDate = meetings;
  const startDateTimes = [...new Set(meetingsBySelectedDate.map(m => m.startDateTime))];
  return startDateTimes.map((startDateTime: string) => {
    const meetingsByStartTime = meetingsBySelectedDate.filter(m => m.startDateTime === startDateTime);
    const endDateTime = meetingsByStartTime.map(m => new Date(m.endDateTime)).reduce((ed, d) => (ed < d ? d : ed));
    return {
      meetingsList: meetingsByStartTime,
      startTime: startDateTime,
      endDateTime
    };
  });
};

const getActiveMeetings = (meetingsByStartTime: IMeetingsByStartTime[], showPastMeetings: boolean): PrivateMeetingSessionList => {
  const nowDateInMs = new Date().getTime();
  let activeMeetings: PrivateMeetingSessionList = [];
  meetingsByStartTime.forEach(ms => {
    const endDateInMs = ms.endDateTime.getTime();
    activeMeetings = !showPastMeetings && endDateInMs < nowDateInMs ? activeMeetings : activeMeetings.concat(ms.meetingsList);
  });
  return activeMeetings;
};

const showJoinButton = (access: string, preRegistrationEnabled: boolean, status = ''): boolean => {
  if (access === 'PLENARY' || !preRegistrationEnabled) return true;
  if (access === 'OPEN' && preRegistrationEnabled && status === JOIN_STATUS.NOT_JOINED) {
    return false;
  }
  return true;
};

const getJoinBtnClassName = (
  classNameSessionTableCellBtnJoin: string,
  isMobile: boolean,
  featured: boolean,
  isConnectBtnDisabled: boolean,
  isExpandedView?: boolean,
  isAddBtnShown?: boolean,
  isClosestMeeting?: boolean,
  isSessionsTab?: boolean,
): string => {
  let joinBtnClassName = classNameSessionTableCellBtnJoin + ' font-size-11px rounded whitespace-no-wrap font-weight-500 on-hover-shadow';
  joinBtnClassName += !isMobile && (featured || isExpandedView || isClosestMeeting) ? ' session-meeting-title-join-btn-expanded px-4 py-3 my-5 ' : ' session-meeting-title-join-btn py-1 my-3';
  joinBtnClassName += isConnectBtnDisabled ? ' bg-white outline outline-green-blue text-primary on-hover-shadow' : ' bg-primary text-white';

  joinBtnClassName += (!featured && isAddBtnShown) || (!isSessionsTab) ? ' ml-5' : ' mx-10px';

  joinBtnClassName += isAddBtnShown ? ' plenary' : '';

  joinBtnClassName += !isSessionsTab ? ' replays-join-button' : '';

  return joinBtnClassName;
};

const getAddRemoveBtnClassName = (
  classNameSessionTableCellBtnAdd: string,
  isMobile: boolean,
  joinStatus: string,
  featured: boolean,
  isExpandedView?: boolean
): string => {
  let addRemoveBtnClassName = classNameSessionTableCellBtnAdd + ' font-size-11px font-semibold add-remove-btn justify-center h-34px';

  switch (joinStatus) {
  case JOIN_STATUS.JOINED_AS_REGISTRANT:
    addRemoveBtnClassName += ' hover-label-btn remove-btn-hover';
    break;
  case JOIN_STATUS.JOINED_AS_REGULAR:
    addRemoveBtnClassName += ' cursor-not-allowed added-as-regular pointer-events';
    break;
  case JOIN_STATUS.NOT_JOINED:
    addRemoveBtnClassName += ' not-joined';
    addRemoveBtnClassName += ' text-primary fill-primary not-joined';
    break;
  }

  if (isMobile) {
    addRemoveBtnClassName += ' border-t p-2 bg-cool-gray';
  } else {
    addRemoveBtnClassName += ' p-10px ';
    if (!featured && !isExpandedView) addRemoveBtnClassName += ' border-t bg-cool-gray';

    addRemoveBtnClassName += !featured && !isExpandedView ? '' : ' w-112px';

    if (featured || isExpandedView) addRemoveBtnClassName += ' grid items-center rounded my-5';

    if (!featured && isExpandedView) addRemoveBtnClassName += ' ml-10px';
  }

  return addRemoveBtnClassName;
};

export {
  truncate,
  getActiveTracks,
  getActiveMeetings,
  getMeetingsByStartTime,
  showJoinButton,
  getJoinBtnClassName,
  getAddRemoveBtnClassName
};

