import { HTTP_STATUS, ResponseError, ResponseHeaders } from '../../config/api';
import { invalidate } from '../auth';
import { SSO_ERROR_MSG } from '../constants';


/* Check if response status is 204 or contentLength is 0 */
export const isResponseEmpty = (response: Response): boolean =>
  response?.status === HTTP_STATUS.NO_CONTENT ||
  response?.headers?.get(ResponseHeaders.ContentLength) === '0'
;

/**
 * Default fetch function used for API calls
 * @param url - API url to call
 * @param reqInit - Request config
 * @returns parsed Response or throw an Error
 */
export async function fetcher<T>(url: string | Request, reqInit?: RequestInit): Promise<T> {
  const response = await fetch(url, reqInit);

  if (response.ok) {
    if (isResponseEmpty(response) ) {
      return {} as T;
    } else {
      if (response.headers?.get(ResponseHeaders.ContentType) === 'application/octet-stream') {
        return response.blob() as unknown as Promise<T>;
      } else {
        return await response.json();
      }
    }
  }

  const {
    name = '',
    message = `Fetching the resource ${url} failed`,
    status = response.status,
    ssoError,
  } = await response.json();

  const error: ResponseError = { name, message };
  error.status = status;
  error.url = response.url;

  if (ssoError) {
    error.ssoError = ssoError;
    error.message = SSO_ERROR_MSG.USER_AUTH_FAILURE;
  }

  if (status === HTTP_STATUS.UNAUTHORIZED) await invalidate();

  throw error;
}

