import React from 'react';
import { useHistory } from 'react-router-dom';
import { REGISTRATION_PATH, ROOT } from '../../../../config/routes/paths';
import Button from '../../../../components/button';
import './RegisterBlock.scss';

export enum FormFooterBlockType {
  registration = 'REGISTRATION',
  signIn = 'SIGN-IN'
}

interface IFormBottom {
  type?: FormFooterBlockType;
  isProRegistrationView?: boolean;
}

const RegisterBlock = (props :IFormBottom): JSX.Element => {
  const { type = FormFooterBlockType.registration, isProRegistrationView } = props;
  const history = useHistory();

  let label:string;
  let buttonText: string;
  let navigateTo: string;

  if (type === FormFooterBlockType.registration){
    label = 'Not registered yet?';
    buttonText = 'Register Now';
    navigateTo = REGISTRATION_PATH;
  } else {
    label = 'Already registered?';
    buttonText = 'Sign In';
    navigateTo = ROOT;
  }

  return (
    <div className={`${isProRegistrationView ? 'register-block--pro' : ''} bg-white py-8 px-10 flex justify-between items-center  font-medium`}>
      <span className='register-block__label font-size-13px'>{label}</span>
      <Button.Common
        size={'auto'}
        name={buttonText}
        onClick={() => history.push(navigateTo)}
        label={buttonText}
        className='px-4 py-2 rounded text-center bg-white outline font-medium outline-mid-gray text-primary font-size-13px on-hover-shadow outline-auto-visible'
      />
    </div>
  );
};

export default RegisterBlock;
