import React from 'react';

type ErrorBlockProps = {
  errorMessage: string,
  classname?: string
}

const ErrorBlock:React.FC<ErrorBlockProps> = ({ errorMessage, classname = '' }) => {
  return (<div className={`${classname} width-full bg-white text-error py-1 font-medium font-size-11px break-words bg-opacity-0 `} dangerouslySetInnerHTML={{ __html: errorMessage }} />);
};

export default ErrorBlock;
