import { Redirect } from 'react-router-dom';
import React from 'react';
import { EventSettingsContextType, useEventSettings } from '../../../lib/context-providers/event-settings-context/event-settings-context';
import SpeakerDetails from './SpeakerDetails';
import EventDetails from '../../shared/EventDetails';
import isEnabledRoute from '../../../hooks/use-enabled-route';
import { IAuthContext, useAuth } from '../../../lib/context-providers/auth-context';
import { PassportPageType } from '../../../lib/api/custom-page.types';
import { ROOT } from '../../../config/routes/paths';

const SpeakerWrapper = (): JSX.Element | null => {
  const auth = useAuth() as IAuthContext;
  const isAuthenticated = !!auth.isAuthenticated;
  const isLoading = auth.isLoading;

  const eventData = useEventSettings() as EventSettingsContextType;
  const enabledRoute = isEnabledRoute(eventData?.landingPage);

  if (!eventData || isLoading) return null;

  const pageConfiguration = eventData.customPages.find(el => el.type === PassportPageType.SPEAKERS);

  if (isAuthenticated && !eventData?.speakersEnabled) {
    return <Redirect to={enabledRoute} />;
  }

  if (!isAuthenticated && !pageConfiguration?.publicEnabled) {
    return <Redirect to={ROOT} />;
  }

  if (eventData) return (
    <>
      <EventDetails details={eventData}/>
      <SpeakerDetails event={eventData} label={eventData.speakersLabel || pageConfiguration?.label} />
    </>
  );
  return null;
};

export default SpeakerWrapper;
