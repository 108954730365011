import { useHistory, generatePath } from 'react-router-dom';
import { SHOWCASE_PATH, CONNECTION_KNOVIO_PATH } from '../config/routes/paths';
import { PrivateMeetingDetailsResponseType } from '../lib/api';
import { JoinMethod } from '../lib/join-method/join-method';

export type ConnectionPrivateDetailsType = (PrivateMeetingDetailsResponseType & { joinUrl?: string }) | void;

/*
* Connection method precedence:
* 1 - Attendee level connection
* 1.1 - embedCodeId or showcasePagePath
* 1.2 - connectionLink
* 2 - Link set for an Attendee
*/

export const openWindow = (url?: string): void => {
  window.open(url ?? '', '_blank');
};

export default function useConnectToPrivateMeetingMethod(details: ConnectionPrivateDetailsType, joinMethod: JoinMethod | null): () => void {
  const history = useHistory();

  return () => {
    if (!details) return;

    const { meetingId, attendeeLink, joinUrl } = details;

    if (joinUrl) {
      return openWindow(joinUrl);
    }

    if (joinMethod) {
      if (joinMethod.isKnovioConnectionType()) return openWindow(generatePath(CONNECTION_KNOVIO_PATH, { meetingId }));

      if (joinMethod.isShowcaseConnectionType()) return history.push(SHOWCASE_PATH, { showcasePagePath: joinMethod.showcasePagePath });

      return openWindow(joinMethod.connectionLink);
    }

    return openWindow(attendeeLink);
  };
}
