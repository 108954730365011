import React from 'react';
import Button from '../../../components/button';
import Icon from '../../../components/_base/Icon';

export type IProps = {
  presenterPortalLink?: string;
}

const OpenPresenterLink: React.FunctionComponent<IProps> = (props: IProps): JSX.Element | null => {
  const { presenterPortalLink } = props;

  if (!presenterPortalLink) {
    return null;
  }

  const connectToMeeting = () => {
    window.open(presenterPortalLink, '_blank');
  };

  return (
    <Button.Common
      name='open presenter link'
      onClick={connectToMeeting}
      label='Open Presenter Link'
      className='connect-to-meeting-btn rounded bg-primary text-white mt-4 on-hover-shadow'
    >
      <Icon.RightArrow className='fill-current text-white mb-1 ml-2' height='15px' width='15px'/>
    </Button.Common>
  );
};

export default OpenPresenterLink;