import { ResponseError } from '../../../config/api';
import { useSWR, responseInterface } from '../../../config/overrides/swr';
import { IAuthContext, useAuth } from '../../../lib/context-providers/auth-context';
import API, { PROTECTED, EVENT, SESSION, PUBLIC } from '../../../lib/api';

const { fetchMeetingPrivateSessionList } = API[PROTECTED];
const { fetchMeetingPublicSessionsList } = API[PUBLIC];

export function useSessionMeetingsList(eventId: string): responseInterface<typeof fetchMeetingPrivateSessionList, ResponseError> {
  const auth = useAuth() as IAuthContext;

  const keyFn = () => eventId ? [ EVENT, eventId, SESSION ] : null;

  return useSWR(keyFn, auth.isAuthenticated ? fetchMeetingPrivateSessionList : fetchMeetingPublicSessionsList);
}

