import { ResponseError } from '../../../config/api';
import { responseInterface, useSWR } from '../../../config/overrides/swr';
import API, { PROTECTED, EVENT, MEETING, REPLAYS, FilterReplays } from '../../../lib/api';


const { fetchReplaysList } = API[PROTECTED];

export function useReplaysList(eventId: string, filterReplays: FilterReplays, sectionEnabled = true): responseInterface<typeof fetchReplaysList, ResponseError> {
  const keyFn = () => eventId && sectionEnabled ? [ EVENT, eventId, MEETING, REPLAYS, filterReplays ] : null;

  return useSWR(keyFn, fetchReplaysList);
}
