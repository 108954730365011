import { RequestHeaders, ResponseResultType } from '../../../config/api';
import { publicApiClient } from '../../../config/overrides/api';
import { composeUrl } from '../utils';
import {
  AppSettings,
  ATTENDEE,
  CONFIGURATION,
  EVENT,
  EventPublicDetailsResponseType,
  EXPORT,
  GOOGlE,
  GoogleCalendarResponseType,
  ICS,
  MEETING,
  PARTICIPANT,
  PREREGISTER,
  PRESENTER,
  PresenterParticipantDetailsResponseType,
  PROPERTIES,
  PublicMeetingDetailsResponseType,
  PublicSessionMeetingDetails,
  REGISTER,
  RegistrationSubscriptionParams,
  RESEND_CODE,
  SpeakerListResponseType,
  PrivateMeetingSessionDetailsListResponseType,
  REGISTRATION_CONFIGURATION,
  AttendeeProcessingUrlResponse,
  AttendeeOneWayRegistrationErrorResponse
} from '../index';
import { ATTENDEE_TOKEN_KEY, BYPASS_TOKEN_KEY, TOKEN, TYPE } from '../../constants';

const API_PREFIX = 'public';
export const composePublicUrl = (...args: string[]): string => composeUrl(API_PREFIX, ...args);

export function fetchPublicPresenterDetails(...path: string[]): ResponseResultType<PresenterParticipantDetailsResponseType> {
  return publicApiClient.GET(composePublicUrl(PRESENTER, ...path));
}

export function fetchPublicParticipantDetails(...path: string[]): ResponseResultType<PresenterParticipantDetailsResponseType> {
  return publicApiClient.GET(composePublicUrl(PARTICIPANT, ...path));
}

export function fetchPublicMeetingDetails(meetingPath: string, passcode: string): ResponseResultType<PublicMeetingDetailsResponseType> {
  return publicApiClient.GET(composePublicUrl(MEETING, meetingPath), { headers: { [RequestHeaders.Passcode]: btoa(passcode) }});
}

export function fetchPublicMeetingDetailsWithBypassToken(meetingPath: string, bypassToken: string): ResponseResultType<PublicMeetingDetailsResponseType> {
  return publicApiClient.GET(`${composePublicUrl(MEETING, meetingPath)}?${BYPASS_TOKEN_KEY}=${bypassToken}`);
}

export function fetchPublicMeetingDetailsWithAttendeeToken(meetingPath: string, attendeeToken: string): ResponseResultType<PublicMeetingDetailsResponseType> {
  return publicApiClient.GET(`${composeUrl(API_PREFIX, MEETING, meetingPath)}?${ATTENDEE_TOKEN_KEY}=${attendeeToken}`);
}

export function fetchPublicEventDetailsBySubdomain(subdomain: string): ResponseResultType<EventPublicDetailsResponseType> {
  return publicApiClient.GET(composePublicUrl(EVENT, subdomain));
}

export function fetchPublicCustomFieldsByEventId(eventId: string): Promise<void> {
  return publicApiClient.GET(composePublicUrl(EVENT, eventId, REGISTRATION_CONFIGURATION));
}

export function fetchPublicTriageAnswerCustomFieldsByEventId(eventId: number, selectedTriageAnswer: string): Promise<void> {
  return publicApiClient.GET(`${composePublicUrl(EVENT, eventId.toString(), REGISTRATION_CONFIGURATION)}?formName=${encodeURIComponent(selectedTriageAnswer)}`);
}

export function requestAccessCode(eventId: string, email: string, registrationId?: string): Promise<void> {
  const payload = registrationId ? { email, registrationId } : { email };
  return publicApiClient.POST(composePublicUrl(EVENT, eventId, ATTENDEE, RESEND_CODE), payload);
}

export function fetchRequestAccessCodeParameters(eventId: string): Promise<{ registrationId: string, appSyncToken: string }> {
  return publicApiClient.GET(composePublicUrl(EVENT, eventId, ATTENDEE, RESEND_CODE));
}

export function registerAttendee(eventId: string, params: Record<string, unknown>): Promise<{ registrationId: string, appSyncToken: string, eventToken: string | undefined }> {
  return publicApiClient.POST(composePublicUrl(EVENT, eventId, ATTENDEE, REGISTER), { ...params });
}

export async function fetchOutlookCalendarFile(eventId: string, eventToken: string | undefined): Promise<Blob> {
  return await publicApiClient.GET(`${composePublicUrl(EVENT, eventId, EXPORT)}?${TYPE}=${ICS}&${TOKEN}=${eventToken}`);
}

export async function fetchGoogleCalendarLink(eventId: string, eventToken: string | undefined): Promise<GoogleCalendarResponseType> {
  return await publicApiClient.GET(`${composePublicUrl(EVENT, eventId, EXPORT)}?${TYPE}=${GOOGlE}&${TOKEN}=${eventToken}`);
}

export function fetchEventGeneralSessionList(...args: string[]): ResponseResultType<PublicSessionMeetingDetails> {
  return publicApiClient.GET(composePublicUrl(...args));
}

export function fetchOutlookCalendarFileByBypassToken(meetingPath: string, bypassToken: string): Promise<Blob> {
  return publicApiClient.GET(`${composePublicUrl(MEETING, meetingPath, EXPORT)}?${TYPE}=${ICS}&${BYPASS_TOKEN_KEY}=${bypassToken}`);
}

export function fetchGoogleCalendarLinkByBypassToken(meetingPath: string, bypassToken: string): Promise<GoogleCalendarResponseType> {
  return publicApiClient.GET(`${composePublicUrl(MEETING, meetingPath, EXPORT)}?${TYPE}=${GOOGlE}&${BYPASS_TOKEN_KEY}=${bypassToken}`);
}

export function fetchOutlookCalendarFileByAttendeeToken(meetingPath: string, attendeeToken: string): Promise<Blob> {
  return publicApiClient.GET(`${composePublicUrl(MEETING, meetingPath, EXPORT)}?${TYPE}=${ICS}&${ATTENDEE_TOKEN_KEY}=${attendeeToken}`);
}

export function fetchGoogleCalendarLinkByAttendeeToken(meetingPath: string, attendeeToken: string): Promise<GoogleCalendarResponseType> {
  return publicApiClient.GET(`${composePublicUrl(MEETING, meetingPath, EXPORT)}?${TYPE}=${GOOGlE}&${ATTENDEE_TOKEN_KEY}=${attendeeToken}`);
}

export function fetchOutlookCalendarFileByPasscode(meetingPath: string, passcode: string): Promise<Blob> {
  return publicApiClient.GET(`${composePublicUrl(MEETING, meetingPath, EXPORT)}?${TYPE}=${ICS}`,
    { headers: { [RequestHeaders.Passcode]: btoa(passcode) }});
}

export function fetchGoogleCalendarLinkByPasscode(meetingPath: string, passcode: string): Promise<GoogleCalendarResponseType> {
  return publicApiClient.GET(`${composePublicUrl(MEETING, meetingPath, EXPORT)}?${TYPE}=${GOOGlE}`,
    { headers: { [RequestHeaders.Passcode]: btoa(passcode) }});
}

export async function fetchPublicSpeakerList(...args: string[]): Promise<SpeakerListResponseType> {
  return await publicApiClient.GET(composePublicUrl(...args));
}

export async function fetchMeetingPublicSessionsList(...args: string[]): Promise<PrivateMeetingSessionDetailsListResponseType> {
  return await publicApiClient.GET(composePublicUrl(...args));
}

export async function fetchRegistrationSubscriptionParams(eventId: string, selectedTriageAnswer: string): Promise<RegistrationSubscriptionParams> {
  return await publicApiClient.GET(`${composePublicUrl(EVENT, eventId, ATTENDEE, PREREGISTER)}?formName=${encodeURIComponent(selectedTriageAnswer)}`);
}

export async function fetchApplicationSettings(): Promise<AppSettings> {
  return await publicApiClient.GET(composePublicUrl(CONFIGURATION, PROPERTIES));
}

export async function fetchAttendeeProcessedResultUrlByRegistrationParams(registrationId: string, appSyncToken: string): Promise<AttendeeProcessingUrlResponse> {
  return await publicApiClient.GET(
    `${composeUrl('attendee/processing-result')}?registrationId=${registrationId}`,
    { headers: { [RequestHeaders.AppSyncToken]: appSyncToken }}
  );
}

export async function fetchFileDownloadUrlByPublicIdentifier(publicIdentifier: string): Promise<string> {
  return await publicApiClient.GET(`${composePublicUrl('asset', publicIdentifier, 'signed-url')}`);
}

export async function initiateAttendeeOneWayRegistration(registrationId: string, appSyncToken: string): Promise<void> {
  return await publicApiClient.GET(
    `${composeUrl('attendee/one-way-register/initiate')}?registrationId=${registrationId}`,
    { headers: { [RequestHeaders.AppSyncToken]: appSyncToken }}
  );
}

export async function fetchAttendeeOneWayRegistrationError(registrationId: string, attendeeId: string, appSyncToken: string): Promise<AttendeeOneWayRegistrationErrorResponse> {
  return await publicApiClient.GET(
    `${composeUrl('attendee/one-way-register/redirect')}?registrationId=${registrationId}&redirectId=${attendeeId}`,
    { headers: { [RequestHeaders.AppSyncToken]: appSyncToken }}
  );
}