export const setItemInSessionStorage = (key: string, value: string): void => {
  window.sessionStorage.setItem(key, value);
};

export const getItemFromSessionStorage = (key: string): string | null => {
  return window.sessionStorage.getItem(key);
};

export const removeItemFromSessionStorage = (key: string): void => {
  window.sessionStorage.removeItem(key);
};

export const clearSessionStorage = (): void => {
  window.sessionStorage.clear();
};