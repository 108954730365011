import React, { createContext, useContext, useEffect } from 'react';
import { AuthError, UseAuthError, useAuthError } from '../use-auth-error';
import { useEventPublicInfo } from '../../../hooks/api/public/use-event-public-details';
import { Auth } from 'aws-amplify';
import { IDP_IDENTIFIER, ML_ACCESS_TOKEN } from '../../constants';
import { IAuthContext, useAuth } from '../auth-context';
import { getUrlQueryArgByKey } from '../../api/utils';
import { useSSOAuthFlow } from './sso';
import { AppContextType, useApp } from '../app-config-context';
import { Buffer } from 'buffer';

export type IPasswordlessAuthContext = {
  authError: AuthError,
  resetAuthError(): void,
}
interface IContent {
  email: string;
  expiresAt: string;
  firstName: string;
  keyId: string;
  lastName: string;
}
interface ITokenObject {
  content: IContent;
  signature: string;
}

const usePasswordlessAuthProvider = () => {
  const app = useApp() as AppContextType;
  const auth = useAuth() as IAuthContext;
  const { error: authError, setError } = useAuthError() as UseAuthError;
  const resetAuthError = () => setError(null);
  const { data } = useEventPublicInfo();

  const token = getUrlQueryArgByKey(ML_ACCESS_TOKEN) || '';
  const idpIdentifier = getUrlQueryArgByKey(IDP_IDENTIFIER) || '';

  useSSOAuthFlow();

  async function signInWithToken(tokenObject: ITokenObject) {
    auth.setPasswordlessAuthInProgress();
    if (data?.eventId && auth.isAuthenticated === false) {
      const username = `${tokenObject.content.email.trim()}_${data?.eventId}`;
      try {
        const user = await Auth.signIn(username);
        await Auth.sendCustomChallengeAnswer(user, token);
        auth.setAsAuthenticated();
      } catch (err) {
        auth.setAsNotAuthenticated();
        auth.setInvalidLink();
      } finally {
        auth.resetPasswordlessAuthInProgress();
      }
    }
  }

  useEffect(() => {
    if (token && !idpIdentifier && app.isConfigured) {
      const tokenJsonString = Buffer.from(token, 'base64')?.toString() || '';
      const tokenObject = JSON.parse(tokenJsonString) || '';
      signInWithToken(tokenObject);
    } else if (!token && !idpIdentifier) {
      auth.resetPasswordlessAuthInProgress();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, app.isConfigured, auth.isAuthenticated]);



  return {
    authError, resetAuthError,
  };
};
const authContext = createContext<IPasswordlessAuthContext | void>(undefined);
type Props = {
  children: React.ReactNode | React.ReactNodeArray
}
export const PasswordlessAuthProvider: React.FC<Props> = ({ children }: Props): JSX.Element => {
  const auth = usePasswordlessAuthProvider() as IPasswordlessAuthContext;

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
};
export const usePasswordlessAuth = (): IPasswordlessAuthContext | void => useContext(authContext);
export default PasswordlessAuthProvider;

