import * as React from 'react';
import Spinner from 'react-spinner-material';
import { COLOR_SPINNER, RADIUS_SPINNER, RADIUS_STROKE } from '../../lib/constants';
import './DefaultSpinner.scss';
interface ISpinnerProps {
    radius?: number;
    color?: string;
    stroke?: number;
    visible: boolean;
    text?: string;
}

const DefaultSpinner = (props: ISpinnerProps) : JSX.Element => {

  const {
    radius = RADIUS_SPINNER,
    color = COLOR_SPINNER,
    stroke = RADIUS_STROKE,
    visible = false,
    text = ''
  } = props;


  return (<>
    {visible && <div className='overlay-background'>
      <Spinner visible={visible} radius={radius} color={color} stroke={stroke} />
      {text && <div>{text}</div>}
    </div>
    }
  </>
  );
};

export default DefaultSpinner;
