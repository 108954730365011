import React from 'react';
import {
  EventPrivateDetailsResponseType,
  SpeakerListResponseType,
} from '../../../lib/api';
import { useSpeakersList } from '../../../hooks/api/protected/use-speaker-list';
import { EventPrivateSettingsType } from '../../../lib/context-providers/event-settings-context/event-settings-context';
import DefaultPageHeader from '../../shared/DefaultPageHeader/DefaultPageHeader';
import OrganizationNavBar from './OrganizationNavBar/OrganizationNavBar';
import OrganizationList from './OrganizationList/OrganizationList';
import useSearchSpeakers from '../../../hooks/use-search-speakers';

interface IProps {
  event: EventPrivateDetailsResponseType & EventPrivateSettingsType;
  label?: string;
}

const SpeakerDetails: React.FC<IProps> = (props: IProps): JSX.Element | null => {
  const { event, label = '' } = props;
  const { data, isValidating } = useSpeakersList(String(event.eventId)) as { data: SpeakerListResponseType | void, isValidating: boolean };
  const { searchValue, searchedSpeakers, handleSearch } = useSearchSpeakers(data as SpeakerListResponseType);

  if (!isValidating && data) return (
    <div className={'shadow-gray bg-white mx-auto md:w-3/4 md:px-8 py-8 mt-12 max-h-full h-70 w-100 mx-0 px-0'}>
      <DefaultPageHeader label={label} handleSearch={handleSearch} />
      <div className="flex">
        <OrganizationNavBar organizations={data}/>
        <OrganizationList organizations={data} searchValue={searchValue} searchedSpeakers={searchedSpeakers} />
      </div>
    </div>
  );
  return null;
};

export default SpeakerDetails;
