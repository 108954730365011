import React from 'react';
import DefaultPageHeader from '../../shared/DefaultPageHeader/DefaultPageHeader';
import { IReplaysContext, useReplaysContext } from './ReplaysContextProvider';
import ReplaysPastSessionsWrapper from './ReplaysPastSessionsWrapper';
import ReplaysUpcomingSessionsWrapper from './ReplaysUpcomingSessionsWrapper';
import useSearchReplays from '../../../hooks/use-search-replays';
import { ReplaysListResponseType } from '../../../lib/api';

interface IProps {
  label?: string;
}

const ReplaysDetails: React.FC<IProps> = (props: IProps): JSX.Element | null => {
  const { label = '' } = props;

  const {
    eventData,
    pageConfiguration,
    upcomingSessions,
    pastSessions,
    isValidatingUpcomingSessions = true,
    isValidatingPastSessions = true
  } = useReplaysContext() as IReplaysContext;

  const {
    searchedUpcomingSessions,
    searchedPastSessions,
    handleSearch,
    searchValue,
  } = useSearchReplays(
    upcomingSessions as ReplaysListResponseType,
    pastSessions as ReplaysListResponseType
  );

  if ((upcomingSessions || pastSessions) && !isValidatingUpcomingSessions && !isValidatingPastSessions) {
    return (
      <div className={'shadow-gray bg-white mx-auto md:w-3/4 md:px-8 py-8 mt-12 max-h-full h-70 w-100 mx-0 px-0'}>
        <DefaultPageHeader
          label={label}
          notes={pageConfiguration.replaysNotes}
          dataTestId='replays-notes'
          handleSearch={handleSearch}
        />
        {
          searchValue &&
            <>
              <section className='replays-search-result'>
                {
                  (searchedUpcomingSessions.length || searchedPastSessions.length)
                    ? searchedUpcomingSessions.length + searchedPastSessions.length
                    : 'no'
                } search results for <pre>“{searchValue}”</pre>
              </section>
              <hr />
            </>
        }
        <div className='mt-4'>
          {pageConfiguration.upcomingSessionsEnabled &&
            <ReplaysUpcomingSessionsWrapper
              event={eventData}
              showAnchorElement={!!searchedPastSessions?.length}
              sessionLabel={pageConfiguration.upcomingSessionsLabel}
              pastSessionsLabel={pageConfiguration.replaysSessionLabel}
              upcomingSessions={searchedUpcomingSessions}
            />
          }
          <ReplaysPastSessionsWrapper
            event={eventData}
            showAnchorElement={!!searchedUpcomingSessions?.length}
            sessionLabel={pageConfiguration.replaysSessionLabel}
            upcomingSessionsLabel={pageConfiguration.upcomingSessionsLabel}
            pastSessions={searchedPastSessions}
          />
        </div>
      </div>
    );
  }
  return null;
};

export default ReplaysDetails;