import { useEventPrivateInfo } from './api/protected/use-event-private-details';

export default function useEnabledRoute (route: string): string {
  const { data } = useEventPrivateInfo();
  const isLandingPageEnabled = data?.customPages.find(page => page.landingPage && page.enabled);
  if (isLandingPageEnabled) return route;

  let firstEnabledRoute = '';
  if (data) {
    for (let index = 0; index < data.customPages.length; index++) {
      const element = data.customPages[index];
      if (element.enabled) {
        firstEnabledRoute = `/${element.path ? element.path : element.type.toLowerCase()}`;
        break;
      }
    }
  }
  return firstEnabledRoute;
}