import { matchPath } from 'react-router-dom';
import { useEffect } from 'react';
import { CustomPageProps, SingleMeetingPageType } from '../lib/api/custom-page.types';
import { AGENDA_MEETING_PATH, SESSION_MEETING_PATH } from '../config/routes/paths';

export const getLayoutElement = (): HTMLDivElement | null => document.querySelector('[data-id="app"]');

export const composeSingleMeetingClassName = (meetingId?: string | number): string =>
  meetingId ? `single-meeting-${meetingId}` : '';

function addClassToElement(element: HTMLElement, className: string): void {
  if (element && !element.classList.contains(className)) {
    element.classList.add(className);
  }
}

function removeClassFromElement(element: HTMLElement, className: string): void {
  if (element && element.classList.contains(className)) {
    element.classList.remove(className);
  }
}

function generateMeetingCssClassName(pagePath: string, customPages?: CustomPageProps[]): string | null {
  const sessionMatch = matchPath<{ meetingId?: string }>(pagePath, { path: SESSION_MEETING_PATH, exact: true });
  if (sessionMatch) return composeSingleMeetingClassName(sessionMatch.params.meetingId);

  const agendaMatch = matchPath<{ meetingId?: string }>(pagePath, { path: AGENDA_MEETING_PATH, exact: true });
  if (agendaMatch) return composeSingleMeetingClassName(agendaMatch.params.meetingId);

  const customPage = customPages?.find((page) => page.path === pagePath.slice(1)) as SingleMeetingPageType;
  if (customPage) return composeSingleMeetingClassName(customPage.meetingId);

  return null;
}

export function useMeetingIdAsCssClass(pagePath: string, customPages?: CustomPageProps[]): void {
  const element = getLayoutElement();

  useEffect(() => {
    const className = generateMeetingCssClassName(pagePath, customPages);

    if (className && element) {
      addClassToElement(element, className);
      return () => removeClassFromElement(element, className);
    }
  }, [pagePath, customPages, element]);
}
